export function formatDuration(seconds: number): string {
  if (seconds <= 0) return 'now'

  const units = [
    { label: 'year', seconds: 365 * 24 * 60 * 60 },
    { label: 'day', seconds: 24 * 60 * 60 },
    { label: 'hour', seconds: 60 * 60 },
    { label: 'minute', seconds: 60 },
    { label: 'second', seconds: 1 },
  ]

  const parts: string[] = []

  for (const unit of units) {
    const quotient = Math.floor(seconds / unit.seconds)
    if (quotient > 0) {
      parts.push(`${quotient} ${unit.label}${quotient > 1 ? 's' : ''}`)
      seconds %= unit.seconds
    }
  }

  if (parts.length === 1) return parts[0]

  const lastPart = parts.pop()
  return parts.join(', ') + ' and ' + lastPart
}
