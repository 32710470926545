import { Trans } from '@lingui/macro'
import { ButtonPrimary, ButtonSecondary } from 'components/Button'
import Modal from 'components/Modal'
import React from 'react'
import { AlertTriangle } from 'react-feather'
import styled from 'styled-components'
import { ThemedText } from 'theme'

const WarningContainer = styled.div`
  padding: 1rem;
  text-align: center;
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  margin-top: 1.5rem;
`

interface VestingWarningModalProps {
  isOpen: boolean
  onDismiss: () => void
  onConfirm: () => void
  remainingVestingTime: string
}

export function VestingWarningModal({ isOpen, onDismiss, onConfirm, remainingVestingTime }: VestingWarningModalProps) {
  return (
    <Modal isOpen={isOpen} onDismiss={onDismiss}>
      <WarningContainer>
        <AlertTriangle size={48} color="orange" style={{ marginBottom: '1rem' }} />
        <ThemedText.HeadlineSmall marginBottom="1rem">
          <Trans>Early Unstaking Warning</Trans>
        </ThemedText.HeadlineSmall>
        <ThemedText.BodyPrimary marginBottom="1rem">
          <Trans>
            You still have {remainingVestingTime} remaining in the vesting period. Unstaking now will result in reduced
            rewards.
          </Trans>
        </ThemedText.BodyPrimary>
        <ButtonContainer>
          <ButtonSecondary onClick={onDismiss}>
            <Trans>Cancel</Trans>
          </ButtonSecondary>
          <ButtonPrimary onClick={onConfirm}>
            <Trans>Unstake Anyway</Trans>
          </ButtonPrimary>
        </ButtonContainer>
      </WarningContainer>
    </Modal>
  )
}
