/* eslint-disable react-hooks/exhaustive-deps */
import { BigNumberish } from '@ethersproject/bignumber'
import { BigNumber } from '@ethersproject/bignumber'
import type { TransactionResponse } from '@ethersproject/providers'
import { Trans } from '@lingui/macro'
import { BigintIsh, Currency, CurrencyAmount, Percent, Token } from '@uniswap/sdk-core'
import { MAX_UINT160 } from '@uniswap/smart-order-router'
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { Pool, Position } from '@uniswap/v3-sdk'
import { useWeb3React } from '@web3-react/core'
import { Univ3Staker } from 'abis/types'
import Badge, { BadgeVariant } from 'components/Badge'
import { DarkCard, LightCard } from 'components/Card'
import { AutoColumn } from 'components/Column'
import DoubleCurrencyLogo from 'components/DoubleLogo'
import InvalidFarmDetails from 'components/Farms/FarmDetails/InvalidFarmDetails'
import FarmDetailsSkeleton from 'components/Farms/FarmDetails/Skeleton'
import { SkeletonRow } from 'components/NavBar/SuggestionRow'
import Row, { AutoRow, RowBetween, RowFixed, RowFlat } from 'components/Row'
import { PageWrapper } from 'components/swap/styled'
import { Label } from 'components/swap/SwapModalHeaderAmount'
import { BreadcrumbNavLink } from 'components/Tokens/TokenDetails/BreadcrumbNavLink'
import { Hr } from 'components/Tokens/TokenDetails/Skeleton'
import { MouseoverTooltip } from 'components/Tooltip'
import TransactionConfirmationModal, { ConfirmationModalContent } from 'components/TransactionConfirmationModal'
import { DAYS_IN_YEAR, DEFAULT_CHAIN_ID } from 'constants/misc'
import { DEFAULT_ERC20_DECIMALS } from 'constants/tokens'
import { useToken } from 'hooks/Tokens'
import { useFarmV31Helper, usePoolV3Contract, useV3NFTPositionManagerContract } from 'hooks/useContract'
import { usePairAddresstoCurrency } from 'hooks/usePairAddresstoCurrency'
import { ToUSD, useV3Positions, useV3PositionsFromFarm, useV3PositionsWithStakeInfo } from 'hooks/useV3PositionsVested'
import JSBI from 'jsbi'
import { useSingleCallResult, useSingleContractMultipleData } from 'lib/hooks/multicall'
import { useCurrencyFromMap, useTokenFromActiveNetwork } from 'lib/hooks/useCurrency'
import { themeVars } from 'nft/css/sprinkles.css'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { ArrowLeft, PlusCircle } from 'react-feather'
import { useNavigate } from 'react-router-dom'
import { Text } from 'rebass'
import { useUSDPricesWithFallback } from 'state/cache/hooks'
import { useTransactionAdder } from 'state/transactions/hooks'
import { TransactionType } from 'state/transactions/types'
import styled, { useTheme } from 'styled-components'
import { BREAKPOINTS, ExternalLinkIcon, ThemedText } from 'theme'
import { PositionDetails } from 'types/position'
import { calculateGasMargin } from 'utils/calculateGasMargin'
import { formatUSDPrice } from 'utils/formatNumbers'
import { ExplorerDataType, getExplorerLink } from 'utils/getExplorerLink'

import { useV3FarmIncentives, useV3UserPositions } from '../hooks/fetchFarms'
import { ConfirmAddFarmModalBottom } from './ConfirmAddModalBottom'
import { PositionsTable, ResponsiveButton } from './PositionsTable'
import RewardDisplay from './RewardsDisplay'

const HeaderContainer = styled(Row)`
  @media screen and (max-width: ${BREAKPOINTS.sm}px) {
    flex-direction: column;
  }
`

const RowStakes = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 16px;
  flex: 1;
  justify-content: space-between;
  margin-left: 64px;
  @media screen and (max-width: ${BREAKPOINTS.sm}px) {
    margin-left: 0px;
  }
`

const Child = styled.div`
  flex-grow: 1;
`

// Add this new styled component after the other styled components
const TokenLink = styled.a`
  color: ${({ theme }) => theme.neutral1};
  cursor: help;
  text-decoration: none;
  border-bottom: 2px dashed ${({ theme }) => theme.neutral2};
  margin-bottom: -1px;

  &:hover {
    color: ${({ theme }) => theme.accent1};
    border-bottom-color: ${({ theme }) => theme.accent1};
  }
`

// eslint-disable-next-line import/no-unused-modules
export enum FarmAction {
  Deposit = 1,
  Stake = 2,
  Remove = 3,
  Claim = 4,
  Withdraw = 5,
  Unstake = 6,
  Create = 7,
}

export interface Incentive {
  rewardToken: string
  id: string
  pool: string
  startTime: string | BigNumberish
  endTime: string | BigNumberish
  refundee: string
  vestingPeriod?: string | BigNumberish // New field
  isOfficial: boolean
  isActive?: boolean
  isExpired?: boolean
}

function actionToDisplayName(action: FarmAction | undefined) {
  if (action === FarmAction.Deposit) return 'Deposit'
  if (action === FarmAction.Stake) return 'Stake'
  if (action === FarmAction.Unstake) return 'Unstake'
  if (action === FarmAction.Remove) return 'Remove'
  if (action === FarmAction.Claim) return 'Claim'
  if (action === FarmAction.Withdraw) return 'Withdraw'
  return 'Unknown'
}

// Add this helper function before the FarmDetails component
export function getExplorerUrl(chainId: number | undefined) {
  switch (chainId) {
    case 250:
      return 'https://ftmscan.com'
    case 199:
      return 'https://bttcscan.com'
    case 108:
      return 'https://eonscan.com'
    default:
      return 'https://ftmscan.com' // fallback to ftmscan
  }
}

// eslint-disable-next-line import/no-unused-modules
export function useGetTVL(pool: Pool | undefined, incentiveId?: string) {
  const [tvl, setTvl] = useState<number | undefined>(0)
  const cachedTokenPrices = useUSDPricesWithFallback()

  const unistaker = useFarmV31Helper()

  const { loading: loadingPositions, positions } = useV3Positions(unistaker ? unistaker.address : undefined)

  const filteredPositions = useMemo(() => {
    if (!positions || !pool) {
      return []
    }

    return positions.filter((position: PositionDetails) => {
      return (
        position.fee === pool.fee && position.token0 === pool.token0.address && position.token1 === pool.token1.address
      )
    })
  }, [positions, pool])

  // Prepare calls for stakes function if incentiveId is provided
  const stakesCalls = useMemo(() => {
    if (!incentiveId || !filteredPositions) return []
    return filteredPositions.map((position) => ({
      params: [position.tokenId.toString(), incentiveId],
    }))
  }, [filteredPositions, incentiveId])

  // Call stakes function for each position
  const stakesResults = useSingleContractMultipleData(
    unistaker,
    'stakes',
    stakesCalls.map((call) => call.params)
  )

  useEffect(() => {
    if (!loadingPositions && filteredPositions && filteredPositions.length > 0 && pool) {
      const tvls = filteredPositions.map((position: PositionDetails, index: number) => {
        const pos = new Position({
          pool,
          liquidity: JSBI.BigInt(position.liquidity),
          tickLower: position.tickLower,
          tickUpper: position.tickUpper,
        })

        // If incentiveId is provided, check if the position is staked in this incentive
        if (incentiveId) {
          const stakesResult = stakesResults[index]
          if (stakesResult && stakesResult.result) {
            const liquidity = BigNumber.from(stakesResult.result.liquidity)
            if (liquidity.eq(0)) {
              return 0 // Skip this position if it's not staked in this incentive
            }
          }
        }

        const amount0 = pos.amount0
        const amount1 = pos.amount1
        const usdToken0 = ToUSD(cachedTokenPrices, amount0)
        const usdToken1 = ToUSD(cachedTokenPrices, amount1)
        return usdToken0 + usdToken1
      })

      const totalTvl = tvls.reduce((a, b) => a + b, 0)
      setTvl(totalTvl)
    }
  }, [cachedTokenPrices, filteredPositions, loadingPositions, pool, incentiveId, stakesResults])

  return tvl
}

export function useGetUserTVL(
  pool: Pool | undefined,
  incentiveId: string | undefined,
  userAccount: string | null | undefined,
  v3StakerContract: Univ3Staker | null
) {
  const [userTVL, setUserTVL] = useState<number | undefined>(0)
  // const { positions: userPositions } = useV3Positions(userAccount)
  const cachedTokenPrices = useUSDPricesWithFallback()

  const { loading: loadingPositions, positions } = useV3PositionsWithStakeInfo(
    userAccount,
    incentiveId,
    v3StakerContract
  )

  const filteredPositions = useMemo(() => {
    if (!positions || !pool) {
      return []
    }

    return positions.filter((position: PositionDetails) => {
      return (
        position.fee === pool.fee &&
        position.token0 === pool.token0.address &&
        position.token1 === pool.token1.address &&
        position.stakedInIncentive
      )
    })
  }, [positions, pool])

  useEffect(() => {
    if (filteredPositions && filteredPositions.length > 0 && pool) {
      const tvls = filteredPositions.map((position: PositionDetails) => {
        const pos = new Position({
          pool,
          liquidity: JSBI.BigInt(position.liquidity),
          tickLower: position.tickLower,
          tickUpper: position.tickUpper,
        })
        const amount0 = pos.amount0
        const amount1 = pos.amount1
        const usdToken0 = ToUSD(cachedTokenPrices, amount0)
        const usdToken1 = ToUSD(cachedTokenPrices, amount1)
        return usdToken0 + usdToken1
      })
      const totalTvl = tvls.reduce((a, b) => a + b, 0)
      setUserTVL(totalTvl)
    }
  }, [filteredPositions, pool, cachedTokenPrices])

  return userTVL
}

type FarmDetailsProps = {
  poolKey?: string
}
// eslint-disable-next-line import/no-unused-modules
export default function FarmDetails({ poolKey }: FarmDetailsProps) {
  if (!poolKey) {
    throw new Error('Invalid Farm details route: one of the currency param is undefined')
  }

  const { account, chainId, provider } = useWeb3React()
  const navigate = useNavigate()

  const addTransaction = useTransactionAdder()
  const [showConfirm, setShowConfirm] = useState(false)
  const [attemptingTxn, setAttemptingTxn] = useState(false)
  const [txHash, setTxHash] = useState('')
  const rewardsToClaim = MAX_UINT160.toString()
  const [farmActionState, setFarmActionState] = useState(FarmAction.Deposit)
  const [tokenSelect, setTokenSelect] = useState('')

  const [pool, setPool] = useState<Pool | undefined>()
  const nftPositionManager = useV3NFTPositionManagerContract()
  const { positions: userPositions, refetch: refetchUserPositions } = useV3UserPositions(account)
  const { positions: userPositionsUser, loading: loadingPositions } = useV3PositionsFromFarm(account, pool)
  const uniV3StakerContract = useFarmV31Helper()

  const { incentives }: { incentives: Incentive[] } = useV3FarmIncentives(poolKey)
  const rewardResults = useSingleCallResult(uniV3StakerContract, 'rewards', [incentives?.[0]?.rewardToken, account])
    .result as BigNumberish[]

  const theme = useTheme()

  const poolAddress = incentives?.[0]?.pool
  const endTime = incentives?.[0]?.endTime

  const remainingDays = useMemo(() => {
    if (!endTime) {
      return undefined
    }

    const now = Math.floor(Date.now() / 1000) // current time in seconds
    const remainingSeconds = Number(endTime) - now
    const remainingDays = Math.ceil(remainingSeconds / (24 * 60 * 60)) // convert seconds to days

    return remainingDays > 0 ? remainingDays : 0 // if remainingDays is negative, return 0
  }, [endTime])

  // TODO : multicall poolV3 contract to get slot0, liquidity, token0, token1, fee
  const poolV3Contract = usePoolV3Contract(poolAddress)

  const slot0Result = useSingleCallResult(poolV3Contract, 'slot0', [])
  const liquidityResult = useSingleCallResult(poolV3Contract, 'liquidity', [])
  const token0Result = useSingleCallResult(poolV3Contract, 'token0', [])
  const token1Result = useSingleCallResult(poolV3Contract, 'token1', [])
  const feeResult = useSingleCallResult(poolV3Contract, 'fee', [])
  const token0Address = token0Result?.result?.[0] ?? '0x'
  const token1Address = token1Result?.result?.[0] ?? '0x'
  const [currencyA, currencyB] = usePairAddresstoCurrency(token0Address, token1Address)
  const tokenA = useToken(token0Address)
  const tokenB = useToken(token1Address)

  const rewardToken = incentives?.[0]?.rewardToken
  const rewardTokenQuery = useTokenFromActiveNetwork(rewardToken) as Token
  const rewardCurrencyQuery = useCurrencyFromMap({ [rewardToken ?? '']: rewardTokenQuery }, chainId, rewardToken) as
    | Currency
    | undefined

  const rewardResult = useSingleCallResult(uniV3StakerContract, 'incentives', [poolKey])
  const rewardTokensleft = rewardResult?.result?.totalRewardUnclaimed as BigintIsh
  const rewardTokensLeftExact =
    rewardTokensleft && rewardCurrencyQuery
      ? CurrencyAmount.fromRawAmount(rewardCurrencyQuery, rewardTokensleft).toExact()
      : '0'

  // Add reward calculation using token ID and pool structure.
  const [tokenRewards, setTokenRewards] = useState<{ [tokenId: string]: string }>({})

  const incentive = incentives?.[0]
  const tokenIds = useMemo(() => userPositions.map((position) => position.tokenId.toString()), [userPositions])

  // Prepare calls to get rewards for each token ID
  const rewardCalls = useMemo(
    () =>
      tokenIds.map((tokenId) => ({
        params: [
          [
            incentive?.rewardToken,
            incentive?.pool,
            incentive?.startTime,
            incentive?.endTime,
            incentive?.vestingPeriod,
            incentive?.refundee,
          ],
          tokenId,
        ],
      })),
    [tokenIds, incentive]
  )

  const rewardResults2 = useSingleContractMultipleData(
    uniV3StakerContract,
    'getRewardInfo',
    rewardCalls.map((call) => call.params as [string, string, string, string, string, string])
  )

  useEffect(() => {
    // Map the reward results to their respective token IDs
    const newTokenRewards: { [tokenId: string]: string } = {}
    rewardResults2.forEach((result, index) => {
      if (result.result) {
        const tokenId = tokenIds[index]
        newTokenRewards[tokenId] = result.result.reward.toString()
      }
    })
    setTokenRewards(newTokenRewards)
  }, [rewardResults2, tokenIds])

  const handleDismissConfirmation = useCallback(() => {
    setShowConfirm(false)
    setAttemptingTxn(false)
    setTxHash('')
  }, [])
  const setupPool = useCallback(
    (token0Result: any, token1Result: any, slot0Result: any, liquidityResult: any, chainId: number) => {
      if (
        !token0Result.pending &&
        !token1Result.pending &&
        !token0Result.loading &&
        !token1Result.loading &&
        !feeResult.loading &&
        token0Result.result &&
        token1Result.result &&
        slot0Result.result &&
        liquidityResult.result &&
        feeResult.result
      ) {
        const tokenA = new Token(chainId, token0Result.result[0], currencyA?.decimals ?? DEFAULT_ERC20_DECIMALS)
        const tokenB = new Token(chainId, token1Result.result[0], currencyB?.decimals ?? DEFAULT_ERC20_DECIMALS)
        const newPool = new Pool(
          tokenA,
          tokenB,
          feeResult.result[0],
          JSBI.BigInt(slot0Result.result.sqrtPriceX96.toString()),
          JSBI.BigInt(liquidityResult.result.toString()),
          slot0Result.result.tick
        ) // Mocked values for sqrtPriceX96, liquidity, tick
        setPool(newPool)
      }
    },
    [currencyA, currencyB, feeResult, setPool]
  )

  useEffect(() => {
    if (token0Result && token1Result && slot0Result && liquidityResult && chainId) {
      setupPool(token0Result, token1Result, slot0Result, liquidityResult, chainId)
    }
  }, [token0Result, token1Result, chainId, setupPool, slot0Result, liquidityResult])

  const farmStatusBadge = useMemo(() => {
    if (remainingDays !== undefined && remainingDays > 0) {
      return (
        <Badge style={{ gap: '8px' }}>
          <BadgeText>
            <Trans>
              {' '}
              Active Farm - Ends in {remainingDays} {remainingDays === 1 ? 'Day' : 'Days'}
            </Trans>
          </BadgeText>
          <ActiveDot />
        </Badge>
      )
    } else {
      return (
        <Badge style={{ gap: '8px', backgroundColor: themeVars.colors.deprecated_accentFailureSoft }}>
          <BadgeText style={{ color: themeVars.colors.critical }}>
            <Trans> Farm Ended - Only Unstake & Withdraw Available </Trans>
          </BadgeText>
        </Badge>
      )
    }
  }, [remainingDays])
  const cachedTokenPrices = useUSDPricesWithFallback()

  const tvlAmount = useGetTVL(pool, incentives?.[0]?.id)
  const rewardsUSD = ToUSD(
    cachedTokenPrices,
    rewardTokensleft && rewardTokenQuery
      ? CurrencyAmount.fromRawAmount(rewardTokenQuery, rewardTokensleft ? rewardTokensleft : 0)
      : undefined
  )

  const apr = useMemo(() => {
    if (rewardsUSD > 0 && tvlAmount && remainingDays && remainingDays > 0) {
      const calculatedApr = (rewardsUSD / tvlAmount) * (DAYS_IN_YEAR / remainingDays) * 100
      // Check if the APR is a valid, reasonable number (e.g., less than 1000000%)
      return calculatedApr > 0.01 ? calculatedApr : 0
    } else {
      return 0
    }
  }, [rewardsUSD, tvlAmount, remainingDays])

  const { descriptiveAction, formattedTokenName, formattedPendingText } = useMemo(() => {
    const formattedTokenName = `${currencyA?.symbol} / ${currencyB?.symbol}`

    const descriptiveAction = actionToDisplayName(farmActionState)

    const formattedPendingText = `${descriptiveAction}  ${formattedTokenName} ${
      farmActionState === FarmAction.Claim ? ' Rewards' : ''
    }`
    return {
      descriptiveAction,
      formattedTokenName,
      formattedPendingText,
    }
  }, [farmActionState, currencyA, currencyB])

  const pendingText = (
    <AutoColumn gap="8px" justify="center">
      <Trans>{formattedPendingText}</Trans> <Badge variant={BadgeVariant.PRIMARY}>Token ID {tokenSelect}</Badge>
    </AutoColumn>
  )

  async function onExecute() {
    if (!chainId || !provider || !account || !uniV3StakerContract || !nftPositionManager || !incentives || !poolKey) {
      console.error('Missing required parameters')
      return
    }

    setAttemptingTxn(true)

    try {
      if (farmActionState === FarmAction.Deposit) {
        await handleAddAction()
      } else if (farmActionState === FarmAction.Stake) {
        await handleStakeAction()
      } else if (farmActionState === FarmAction.Unstake) {
        await handleUnstakeAction()
      } else if (farmActionState === FarmAction.Claim) {
        await handleClaimAction()
      } else if (farmActionState === FarmAction.Withdraw) {
        await handleWithdrawAction()
      } else {
        console.error('Invalid farm action state')
      }
    } catch (error) {
      console.error('Execution failed:', error)
    } finally {
      handleDismissConfirmation()
      // setShowConfirm(false)
      setTimeout(() => {
        console.log('Refetching user positions')
        refetchUserPositions()
      }, 15000)
    }
  }

  async function handleAddAction() {
    if (!pool || !incentives || !nftPositionManager || !uniV3StakerContract) {
      console.error('Missing required parameters')
      return
    }
    const encodeArgData = uniV3StakerContract.interface._abiCoder.encode(
      ['address', 'address', 'uint256', 'uint256', 'uint256', 'address'],
      [
        incentives[0].rewardToken,
        incentives[0].pool,
        incentives[0].startTime,
        incentives[0].endTime,
        incentives[0].vestingPeriod,
        incentives[0].refundee,
      ]
    )

    const transferArgs = [account, uniV3StakerContract.address, tokenSelect, encodeArgData] as [
      string,
      string,
      string,
      string
    ]

    await nftPositionManager.estimateGas['safeTransferFrom(address,address,uint256,bytes)'](...transferArgs)
      .then((estimatedGas) =>
        nftPositionManager['safeTransferFrom(address,address,uint256,bytes)'](...transferArgs, {
          gasLimit: calculateGasMargin(estimatedGas),
        })
      )
      .then((response: TransactionResponse) => {
        setTxHash(response.hash)
        addTransaction(response, {
          type: TransactionType.ADD_LIQUIDITY_V3_FARM,
          baseCurrencyId: pool?.token0.address,
          quoteCurrencyId: pool?.token1.address,
          expectedTokenId: tokenSelect,
        })
      })
      .catch((error) => {
        console.error('NFT transfer failed:', error)
        throw new Error('NFT transfer failed')
      })
  }

  async function handleStakeAction() {
    if (!incentives || !uniV3StakerContract || !pool) {
      console.error('Missing required parameters')
      return
    }
    const incentive = incentives[0]
    const args: [
      {
        rewardToken: string
        pool: string
        startTime: BigNumberish
        endTime: BigNumberish
        vestingPeriod: BigNumberish
        refundee: string
      },
      string
    ] = [
      {
        rewardToken: incentive.rewardToken,
        pool: incentive.pool,
        startTime: incentive.startTime,
        endTime: incentive.endTime,
        vestingPeriod: incentive.vestingPeriod as BigNumberish,
        refundee: incentive.refundee,
      },
      tokenSelect,
    ]

    await uniV3StakerContract.estimateGas
      .stakeToken(...args)
      .then((estimatedGas) => uniV3StakerContract.stakeToken(...args, { gasLimit: calculateGasMargin(estimatedGas) }))
      .then((response: TransactionResponse) => {
        setTxHash(response.hash)
        addTransaction(response, {
          type: TransactionType.STAKE_FARM_V3,
          baseCurrencyId: pool.token0.address,
          quoteCurrencyId: pool.token1.address,
          expectedTokenId: tokenSelect,
        })
      })
      .catch((error) => {
        console.error('Stake failed:', error)
        throw new Error('Stake failed')
      })

    console.log('Stake successful')
  }

  async function handleUnstakeAction() {
    // Prepare arguments for unstaking and withdrawing
    if (!incentives || !uniV3StakerContract || !pool) {
      console.error('Missing required parameters')
      return
    }
    const incentive = incentives[0]
    const args: [
      {
        rewardToken: string
        pool: string
        startTime: BigNumberish
        endTime: BigNumberish
        vestingPeriod: BigNumberish
        refundee: string
      },
      string
    ] = [
      {
        rewardToken: incentive.rewardToken,
        pool: incentive.pool,
        startTime: incentive.startTime,
        endTime: incentive.endTime,
        vestingPeriod: incentive.vestingPeriod as BigNumberish,
        refundee: incentive.refundee,
      },
      tokenSelect,
    ]

    await uniV3StakerContract.estimateGas
      .unstakeToken(...args)
      .then((g) =>
        uniV3StakerContract
          .unstakeToken(...args, { gasLimit: calculateGasMargin(g) })
          .then((response: TransactionResponse) => {
            setTxHash(response.hash)
            addTransaction(response, {
              type: TransactionType.UNSTAKE_FARM_V3,
              baseCurrencyId: pool.token0.address,
              quoteCurrencyId: pool.token1.address,
              expectedTokenId: tokenSelect,
            })
          })
      )
      .catch((err) => {
        console.error('Unstake failed:', err)
        throw new Error('Unstake failed')
      })

    console.log('Unstake and withdraw successful')
  }

  async function handleWithdrawAction() {
    if (!incentives || !uniV3StakerContract || !pool || !tokenSelect || !account) {
      console.error('Missing required parameters')
      return
    }
    const args: [string, string, string] = [tokenSelect, account, '0x']
    await uniV3StakerContract.estimateGas
      .withdrawToken(...args)
      .then((estimatedGas) =>
        uniV3StakerContract.withdrawToken(...args, { gasLimit: calculateGasMargin(estimatedGas) })
      )
      .then((response: TransactionResponse) => {
        setTxHash(response.hash)
        addTransaction(response, {
          type: TransactionType.WITHDRAW_FARM_V3,
          baseCurrencyId: pool.token0.address,
          quoteCurrencyId: pool.token1.address,
          expectedTokenId: tokenSelect,
        })
      })
      .catch((error) => {
        console.error('Withdraw failed:', error)
        throw new Error('Withdraw failed')
      })
  }

  async function handleClaimAction() {
    if (!incentives || !uniV3StakerContract || !account) {
      console.error('Missing required parameters')
      return
    }
    const incentive = incentives[0] as Incentive
    const address = account as string
    const args: [string, string, string] = [incentive.rewardToken, address, rewardsToClaim]

    await uniV3StakerContract.estimateGas
      .claimReward(...args)
      .then((estimatedGas) => uniV3StakerContract.claimReward(...args, { gasLimit: calculateGasMargin(estimatedGas) }))
      .then((response: TransactionResponse) => {
        setTxHash(response.hash)
        addTransaction(response, {
          type: TransactionType.CLAIM,
          recipient: account,
          farmAmountRaw: rewardsToClaim,
        })
      })
      .catch((error) => {
        console.error('Claim rewards failed:', error)
        throw new Error('Claim rewards failed')
      })
  }

  const modalHeader = () => {
    return (
      <AutoColumn gap="20px">
        <LightCard mt="20px" $borderRadius="20px">
          <RowFlat style={{ alignItems: 'center', gap: '15px' }}>
            <Text fontSize="32px" fontWeight={500} lineHeight="42px">
              {descriptiveAction} {formattedTokenName}
            </Text>
            {remainingDays === undefined || remainingDays <= 0 ? (
              <Badge style={{ gap: '8px', backgroundColor: themeVars.colors.deprecated_violet }}>
                <BadgeText style={{ color: themeVars.colors.critical }}>
                  <Trans>Farm has ended. Only Unstake and Withdraw are available.</Trans>
                </BadgeText>
              </Badge>
            ) : null}
          </RowFlat>
        </LightCard>
      </AutoColumn>
    )
  }

  const modalBottom = () => {
    if (currencyA && currencyB) {
      return (
        <ConfirmAddFarmModalBottom
          formattedTokenName={formattedTokenName}
          lpToken={currencyB}
          quoteToken={currencyA}
          farmAction={farmActionState}
          onExecute={onExecute}
        />
      )
    } else {
      return <SkeletonRow />
    }
  }

  // Calculate total user deposits
  const totalDeposits = useGetUserTVL(pool, incentives?.[0]?.id, account, uniV3StakerContract)

  // Handler for Claim button
  const handleClaimNavigation = () => {
    navigate('/add/' + tokenA?.address + '/' + tokenB?.address + '/' + pool?.fee)
  }

  // address will never be undefined if token is defined; address is checked here to appease typechecker
  if (!poolAddress || !currencyA || !currencyB || !pool) {
    return <InvalidFarmDetails pageChainId={chainId ? chainId : 1} isInvalidAddress={!pool} />
  }

  return (
    <>
      <TransactionConfirmationModal
        isOpen={showConfirm}
        onDismiss={handleDismissConfirmation}
        attemptingTxn={attemptingTxn}
        hash={txHash}
        reviewContent={() => (
          <ConfirmationModalContent
            title={<Trans>Performing action on a farm</Trans>}
            onDismiss={handleDismissConfirmation}
            topContent={modalHeader}
            bottomContent={modalBottom}
          />
        )}
        pendingText={pendingText}
      />
      <PageWrapper
        style={{
          maxWidth: '780px',
        }}
      >
        {poolAddress && pool ? (
          <AutoColumn gap="sm" style={{ width: '100%', height: '100%' }}>
            <BreadcrumbNavLink to="/farms/v3">
              <ArrowLeft data-testid="token-details-return-button" size={14} /> Farms
            </BreadcrumbNavLink>
            <ResponsiveRow>
              <RowFixed>
                <DoubleCurrencyLogo currency0={currencyA} currency1={currencyB} size={24} margin={true} />
                <ThemedText.DeprecatedLabel fontSize="24px" mr="10px">
                  &nbsp;
                  <div style={{ display: 'inline-flex', alignItems: 'center' }}>
                    {currencyA?.symbol}
                    <ExternalLinkIcon
                      style={{ alignItems: 'top' }}
                      size={24}
                      href={`${getExplorerLink(
                        chainId ?? DEFAULT_CHAIN_ID,
                        currencyA?.wrapped.address,
                        ExplorerDataType.TOKEN
                      )}`}
                    />
                  </div>
                  &nbsp;/&nbsp;
                  <div style={{ display: 'inline-flex', alignItems: 'center' }}>
                    {currencyB?.symbol}
                    <ExternalLinkIcon
                      size={32}
                      href={`${getExplorerLink(
                        chainId ?? DEFAULT_CHAIN_ID,
                        currencyB?.wrapped.address,
                        ExplorerDataType.TOKEN
                      )}`}
                      style={{ alignItems: 'top' }}
                    />{' '}
                  </div>
                </ThemedText.DeprecatedLabel>
                <Badge style={{ marginRight: '8px' }}>
                  <BadgeText>
                    <Trans>{new Percent(pool.fee, 1_000_000).toSignificant()}%</Trans>
                  </BadgeText>
                </Badge>
              </RowFixed>
              <BadgeWrapper>
                <MouseoverTooltip text={<Trans>The farm is active and rewards are available to claim</Trans>}>
                  <LabelText color={themeVars.colors.success}>
                    {remainingDays !== undefined && remainingDays > 0 ? (
                      <Badge style={{ gap: '8px' }}>
                        <BadgeText>
                          <Trans>
                            {' '}
                            Active Farm - Ends in {remainingDays} {remainingDays === 1 ? 'Day' : 'Days'}
                          </Trans>
                        </BadgeText>{' '}
                        <ActiveDot />
                      </Badge>
                    ) : (
                      farmStatusBadge
                    )}
                  </LabelText>
                </MouseoverTooltip>
              </BadgeWrapper>
            </ResponsiveRow>

            <Hr />

            <DarkCard>
              <AutoColumn gap="md" style={{ width: '100%' }}>
                <HeaderContainer style={{ gap: '0px', alignItems: 'baseline', display: 'flex', flex: '1' }}>
                  <AutoColumn justify="space-between">
                    <Label>
                      <Trans>APR</Trans>
                    </Label>
                    <ThemedText.DeprecatedLargeHeader fontSize="36px" fontWeight={535}>
                      <Trans>
                        {remainingDays === 0
                          ? '0%'
                          : !tvlAmount || tvlAmount === 0 || !rewardsUSD
                          ? '?%'
                          : `${new Intl.NumberFormat('en-US', {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }).format(apr)}%`}
                      </Trans>
                    </ThemedText.DeprecatedLargeHeader>
                  </AutoColumn>
                  <RowStakes>
                    <Child>
                      <AutoColumn justify="space-between">
                        <Label>
                          <Trans>Your Stakes</Trans>
                        </Label>
                        <ThemedText.DeprecatedLargeHeader fontSize="36px" fontWeight={535}>
                          <Trans>{totalDeposits ? `${formatUSDPrice(totalDeposits)}` : '$0.00'}</Trans>
                        </ThemedText.DeprecatedLargeHeader>
                      </AutoColumn>
                    </Child>
                    <Child style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                      <ResponsiveButton
                        style={{ marginRight: '8px', alignItems: 'right', height: '36px' }}
                        onClick={handleClaimNavigation}
                      >
                        <PlusCircle size={20} color="white" style={{ marginRight: '8px' }} /> <Trans>Get LP</Trans>
                      </ResponsiveButton>
                    </Child>
                  </RowStakes>
                </HeaderContainer>

                {/* New "Your Deposits" Section */}

                <RowFlat style={{ gap: '16px', borderTop: `1px solid ${theme.surface3}`, paddingTop: '16px' }}>
                  <AutoRow gap="md" style={{ width: '100%', fontSize: '12px' }}>
                    <Label>
                      <Trans>Total Value Locked</Trans>
                    </Label>
                    <AutoColumn gap="md">
                      <ThemedText.DeprecatedLargeHeader fontSize="16px" fontWeight={535}>
                        {tvlAmount && tvlAmount < 0.1 ? '<$0.1' : formatUSDPrice(tvlAmount)}
                      </ThemedText.DeprecatedLargeHeader>
                    </AutoColumn>
                  </AutoRow>
                </RowFlat>

                {/* <Label>
                  <Trans>Your NFTs</Trans>
                </Label>
                <LightCard padding="12px 12px" display="flex" cellSpacing="5px">
                  {account ? (
                    userPositionsUser && userPositionsUser.length > 0 ? (
                      userPositionsUser.map((position) => {
                        return (
                          <PositionContainer position={position} key={position?.tokenId.toNumber()}>
                            <PositionMetadata key={position?.tokenId.toNumber()} position={position} height={100} />
                          </PositionContainer>
                        )
                      })
                    ) : (
                      <NoNFTDisplay>No Positions Available</NoNFTDisplay>
                    )
                  ) : (
                    <NoNFTDisplay>Connect wallet to view your NFTs</NoNFTDisplay>
                  )}
                </LightCard> */}
              </AutoColumn>
            </DarkCard>

            {account && (
              <>
                <RewardDisplay
                  rewardResults={rewardResults ? rewardResults[0].toString() : '0'}
                  rewardToken={incentives[0].rewardToken}
                  onClaim={handleClaimAction}
                />
                {userPositions && (
                  <PositionsTable
                    positions={userPositions}
                    setModal={setShowConfirm}
                    setFarmActionState={setFarmActionState}
                    setToken={setTokenSelect}
                    incentive={incentives?.[0]}
                    pool={pool}
                    UniV3StakerContract={uniV3StakerContract}
                    tokenRewards={tokenRewards}
                    remainingDays={remainingDays}
                  />
                )}
              </>
            )}
          </AutoColumn>
        ) : (
          <FarmDetailsSkeleton />
        )}
      </PageWrapper>
    </>
  )
}

const ResponsiveRow = styled(RowBetween)`
  @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.sm}px`}) {
    flex-direction: column;
    align-items: flex-start;
    row-gap: 16px;
    width: 100%;
  }
`

const PositionLabelRow = styled(RowFixed)({
  flexWrap: 'wrap',
  gap: 8,
})

const ActiveDot = styled.span`
  background-color: ${({ theme }) => theme.success};
  border-radius: 50%;
  height: 8px;
  width: 8px;
`

const LabelText = styled.div<{ color: string }>`
  align-items: center;
  color: ${({ color }) => color} !important;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`
const BadgeWrapper = styled.div`
  font-size: 14px;
  display: flex;
  justify-content: flex-end;
`

const BadgeText = styled.div`
  font-weight: 535;
  font-size: 14px;
  color: ${({ theme }) => theme.neutral2};
`

const NoNFTDisplay = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 60px;
  color: ${({ theme }) => theme.neutral2};
  font-size: 16px;
  font-weight: 535;
  align-items: center;
  padding: 0px 28px;
  gap: 8px;
`
type PositionContainerProps = {
  position: PositionDetails
}

const PositionContainer = styled.div<PositionContainerProps>`
  &:hover {
    brightness: 1.5;
  }
`
