import { SimpleFetchSDK, SwapSide } from '@paraswap/sdk'
import { ChainId, CurrencyAmount, Token } from '@uniswap/sdk-core'
import { USDC_ON } from '@uniswap/smart-order-router'
import { useWeb3React } from '@web3-react/core'
import { DEFAULT_CHAIN_ID, PARASWAP_PARTNER_ID, PARASWAP_SUPPORTED_CHAINS, ZERO_PERCENT } from 'constants/misc'
import { USDC_SONIC } from 'constants/tokens'
import { useParaswap } from 'hooks/useParaswap'
import { useAtom } from 'jotai'
import { atomWithStorage } from 'jotai/utils'
import { useMemo } from 'react'
import { useQuery } from 'react-query'
import { useUSDPrices } from 'state/cache/hooks'

import { resolveParamsAndQuote } from './MagpieQuoter'

const refreshIntervalMS = 30000
// Cache atom using Jotai's atomWithStorage, persisting data in localStorage
const usdQuoteCacheAtom = atomWithStorage(
  'usdQuoteCache',
  {} as { [key: string]: { value?: number; expiration: number } }
)

// Some tokens in the price map are bad either the price was derrive incorrect from coingecko
// or the price was bad in the rpc/gql
const ignoreTokensFromPriceMap: string[] = []

const fetchUSDQuoteParaswap = async (
  paraswap: SimpleFetchSDK | undefined,
  quoteToken: Token,
  USDC: Token
): Promise<number | undefined> => {
  if (!paraswap || !quoteToken) return undefined

  const srcToken = USDC.address.toLowerCase()
  const destToken = quoteToken.address.toLowerCase()

  try {
    // Calculate $100 worth of the destination token based on its decimals
    const amountInUSDToken = CurrencyAmount.fromRawAmount(USDC, (100 * Math.pow(10, USDC.decimals)).toString())

    // Fetch the Paraswap quote for $100 worth of the destination token (USDC)
    const rate = await paraswap.swap.getRate({
      srcToken,
      destToken,
      srcDecimals: USDC.decimals,
      destDecimals: quoteToken.decimals,
      amount: amountInUSDToken.quotient.toString(), // $100 in USDC based on its decimals
      side: SwapSide.SELL,
      options: {
        partner: PARASWAP_PARTNER_ID,
      },
    })

    const quoteAmount = CurrencyAmount.fromRawAmount(quoteToken, rate.destAmount)
    const quoteAmountSignificant = parseFloat(quoteAmount.toSignificant(quoteToken.decimals))
    const quoteValue = parseFloat(rate.srcUSD) / quoteAmountSignificant

    return quoteValue
  } catch (err) {
    console.error('Failed to fetch API USD Quote rate', err)
    return undefined
  }
}

const fetchUSDQuoteMagpie = async (quoteToken: Token, usdc: Token): Promise<number | undefined> => {
  if (!quoteToken) return undefined

  try {
    const usdAmount = 100
    // Calculate $100 worth of the destination token based on its decimals
    const amountInUSDToken = CurrencyAmount.fromRawAmount(usdc, (usdAmount * Math.pow(10, usdc.decimals)).toString())

    // Note we use zero percent to
    const response = await resolveParamsAndQuote(
      quoteToken.chainId,
      usdc,
      quoteToken,
      amountInUSDToken.quotient,
      ZERO_PERCENT,
      // this is just used for quoting so gasless doesnt matter
      false
    )

    const quoteAmount = CurrencyAmount.fromRawAmount(quoteToken, response.amountOut)
    const quoteAmountSignificant = parseFloat(quoteAmount.toSignificant(quoteToken.decimals))

    //TODO investigate whether this should be usd amount or usdAmount/100 to normalize the amount
    const quoteValue = usdAmount / quoteAmountSignificant

    return quoteValue
  } catch (err) {
    console.error('Failed to fetch API USD Quote rate', err)
    return undefined
  }
}

// Custom hook to fetch and memoize USD value or USDC quote with caching
export const useUSDAPIQuote = (quoteToken?: Token): number | undefined => {
  const paraswap = useParaswap()
  const priceMap = useUSDPrices()
  const { chainId } = useWeb3React()
  const USDC = useMemo(() => USDC_ON(chainId ?? DEFAULT_CHAIN_ID), [chainId])

  // Access the cache atom
  const [cache, setCache] = useAtom(usdQuoteCacheAtom)

  // Function to fetch and resolve the quote
  const fetchUSDQuote = async (): Promise<number | undefined> => {
    if (!quoteToken) return undefined

    const destToken = quoteToken.address.toLowerCase()

    // Check if we have a USD value in the price map
    if (!ignoreTokensFromPriceMap.includes(destToken)) {
      const usdPrice = priceMap?.[destToken] || null
      if (usdPrice) {
        return usdPrice
      }
    }

    // Check the cache for a valid entry
    const now = Date.now()
    const cachedEntry = cache[destToken]
    if (cachedEntry && cachedEntry.expiration > now) {
      return cachedEntry.value
    }

    let quoteValue: number | undefined
    if (PARASWAP_SUPPORTED_CHAINS.includes(Number(chainId))) {
      quoteValue = await fetchUSDQuoteParaswap(paraswap, quoteToken, USDC)
    } else if (chainId === ChainId.SONIC || DEFAULT_CHAIN_ID === ChainId.SONIC) {
      quoteValue = await fetchUSDQuoteMagpie(quoteToken, USDC_SONIC)
    }
    setCache({
      ...cache,
      [destToken]: { value: quoteValue, expiration: now + (refreshIntervalMS - 2000) }, // Cache expires a few seconds before refresh
    })

    return quoteValue
  }

  const { data: quote } = useQuery({
    queryKey: ['fetchUSDQuote', quoteToken?.address],
    queryFn: fetchUSDQuote,
    refetchInterval: refreshIntervalMS,
  })

  // Memoize the returned value to avoid unnecessary recomputation
  return useMemo(() => {
    return quote !== undefined ? quote : undefined
  }, [quote])
}
