import { Trans } from '@lingui/macro'
import { Currency, CurrencyAmount, Percent, Token } from '@uniswap/sdk-core'
import { WETH_ADDRESS as getWethAddress } from '@uniswap/universal-router-sdk'
import { useWeb3React } from '@web3-react/core'
import { SpookyLaunchpadABI } from 'abis/types'
import { AutoColumn } from 'components/Column'
import SwapCurrencyInputPanel from 'components/CurrencyInputPanel/SwapCurrencyInputPanel'
import Modal from 'components/Modal'
import { RowBetween } from 'components/Row'
import { ArrowWrapper, Dots, SwapWrapper } from 'components/swap/styled'
import SwapHeader from 'components/swap/SwapHeader'
import { DEFAULT_CHAIN_ID } from 'constants/misc'
import { parseEther } from 'ethers/lib/utils'
import { ApprovalState, useApproveCallback } from 'hooks/useApproveCallback'
import { SpookyLaunchpad } from 'hooks/useContract'
import JSBI from 'jsbi'
import { useTokenBalance } from 'lib/hooks/useCurrencyBalance'
import tryParseCurrencyAmount from 'lib/utils/tryParseCurrencyAmount'
import { SwapSection } from 'pages/Swap'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { ArrowDown } from 'react-feather'
import { useUserSlippageToleranceWithDefault } from 'state/user/hooks'
import styled, { useTheme } from 'styled-components'
import { BREAKPOINTS, ThemedText } from 'theme'
import { calculateGasMargin } from 'utils/calculateGasMargin'
import { calculateSlippageAmount } from 'utils/calculateSlippageAmount'

import { useToggleAccountDrawer } from '../../components/AccountDrawer'
import { ButtonPrimary } from '../../components/Button'
import { useCurrency, useToken } from '../../hooks/Tokens'
import { useTransactionAdder } from '../../state/transactions/hooks'
import { maxAmountSpend } from '../../utils/maxAmountSpend'
import SwapModalFooter from './AdvancedTokenSwapDetails'

interface BuySellPanelProps {
  token?: Token | null
  account?: string
  tokenAddress: string
  mcapReached?: boolean
}

const SwapContainer = styled.div`
  padding: 24px;
  padding-bottom: 0px;
  width: 100%;
  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    padding: 0;
  }
`

const ContainerBottom = styled.div`
  margin: 24px;
  padding-bottom: 24px;
`

const ArrowContainer = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;
`

const AdvancedTradeDetails = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  justify-content: left;
  margin-bottom: 12px;
  // border: 1px solid ${({ theme }) => theme.surface3};
  // border-radius: 16px;
  width: 100%;
  height: 100%;
`

const AdvancedHeader = styled.div`
  display: flex;
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.surface3};
  padding-bottom: 5px;
  margin-bottom: 5px;
  font-size: 14px;
`

const AdvancedInfoHeader = styled.div`
  display: flex;
  width: 100%;

  flex-direction: row;
  justify-content: space-between;
  font-size: 14px;
`

const AdvancedInfoMain = styled.div`
  display: flex;
  width: 100%;
  color: ${({ theme }) => theme.neutral2};
`

const AdvancedInfo = styled.div`
  display: flex;
  justify-content: flex-end;
  text-align: right;
  width: 100%;
`

const TokenInfoSection2 = styled.div`
  margin-bottom: 20px;
  margin: 20px;
  background: ${({ theme }) => theme.surface1};
  border: 1px solid ${({ theme }) => theme.surface3};
  border-radius: 16px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding: 16px;
  // border-radius: 12px;
  // background: ${({ theme }) => theme.surface2};
  // margin-bottom: 16px;

  h2 {
    margin: 0 0 2px;
    font-size: 24px;
  }
  // @media screen and (max-width: ${BREAKPOINTS.md}px) {
  //   margin: 0;
  // }
`

export async function getPriceQuote(
  launchpadContract: SpookyLaunchpadABI | null,
  token: any,
  amount: any,
  buy: boolean
) {
  try {
    if (!launchpadContract || !amount || !token) return null
    const quote = await launchpadContract.quote(token, parseEther(amount), buy)
    // console.log(`Price quote: ${quote?.toString()}`)
    return quote
  } catch (error) {
    console.error('Error fetching quote:', error)
    return null
  }
}

async function buyTokens(
  launchpadContract: SpookyLaunchpadABI | null,
  tokenAddress: any,
  typedValue: any,
  minAmount: any,
  allowedSlippage: Percent,
  minSlippage: JSBI,
  signer: any,
  addTransaction: any,
  setStatus: (status: string) => void // Function to update the UI
) {
  try {
    const estimateGas = await launchpadContract?.estimateGas.buy(tokenAddress, minSlippage.toString(), {
      value: typedValue,
    })
    if (estimateGas) {
      setStatus('Sending transaction...')
      const tx = await launchpadContract?.connect(signer).buy(tokenAddress, minSlippage.toString(), {
        gasLimit: calculateGasMargin(estimateGas),
        value: typedValue,
      })

      const receipt = await tx?.wait() // Wait for transaction to be mined
      if (receipt?.status === 1) {
        setStatus('Transaction successful!')
      } else {
        setStatus('Transaction failed.')
      }

      // Optional: Log transaction details
      // const description = `Launch Coin`
      // addTransaction(receipt, {
      //   type: TransactionType.LAUNCH_TOKEN,
      //   summary: description,
      // })

      return receipt
    } else {
      throw new Error('Failed to estimate gas')
    }
  } catch (error) {
    setStatus('Error occurred during transaction.')

    console.error('Error during buy:', error)
    throw error
  }
}

async function sellTokens(
  launchpadContract: SpookyLaunchpadABI | null,
  tokenAddress: any,
  typedValue: any,
  minAmount: any,
  allowedSlippage: Percent,
  minSlippage: JSBI,
  signer: any,
  addTransaction: any,
  setStatus: (status: string) => void // Function to update the UI
) {
  try {
    const estimateGas = await launchpadContract?.estimateGas.sell(tokenAddress, typedValue, minSlippage.toString())
    if (estimateGas) {
      setStatus('Sending transaction...')
      const tx = await launchpadContract?.connect(signer).sell(tokenAddress, typedValue, minSlippage.toString(), {
        gasLimit: calculateGasMargin(estimateGas),
      })
      // .then((response: any) => {
      //   const description = `Launch Coin`

      //   addTransaction(response, {
      //     type: TransactionType.LAUNCH_TOKEN,
      //     summary: description,
      //   })
      // })
      // console.log('Tokens bought successfully:', tx)
      const receipt = await tx?.wait() // Wait for transaction to be mined
      if (receipt?.status === 1) {
        setStatus('Transaction successful!')
      } else {
        setStatus('Transaction failed.')
      }
      return receipt
    } else {
      throw new Error('Failed to estimate gas')
    }
  } catch (error) {
    setStatus('Error occurred during transaction.')

    console.error('Error during buy:', error)
    throw error
  }
}

export default function BuySellPanel({ token, account, tokenAddress, mcapReached }: BuySellPanelProps) {
  const { chainId } = useWeb3React()
  const chainIdOrDefault = chainId ?? DEFAULT_CHAIN_ID
  const [tradeType, setTradeType] = useState<'BUY' | 'SELL'>('BUY')
  const [typedValue, setTypedValue] = useState('')
  const toggleWalletDrawer = useToggleAccountDrawer()
  const [quotePrice, setQuotePrice] = useState<CurrencyAmount<Currency>>()
  const ETH = useCurrency('ETH') // Assuming ETH is the base currency
  const [showLaunchModal, setShowLaunchModal] = useState(false)

  const inputCurrency = tradeType === 'BUY' ? ETH : token
  const outputCurrency = tradeType === 'BUY' ? token : ETH

  const wethAddress = getWethAddress(chainIdOrDefault)
  const ethToken = useToken(wethAddress)

  const inputTokenBalance = tradeType === 'BUY' ? ethToken : token
  const outputTokenBalance = tradeType === 'BUY' ? token : ethToken

  const inputBalance = useTokenBalance(account ?? undefined, inputTokenBalance ?? undefined)
  const maxInputAmount: CurrencyAmount<Currency> | undefined = maxAmountSpend(inputBalance)
  const outputBalance = useTokenBalance(account ?? undefined, outputTokenBalance ?? undefined)

  const handleTypeInput = useCallback((value: string) => {
    setTypedValue(value)
  }, [])

  const handleTypeOutput = useCallback((value: string) => {
    console.log(value)
  }, [])

  const handleMaxInput = useCallback(() => {
    if (maxInputAmount) {
      setTypedValue(maxInputAmount.toExact())
    }
  }, [maxInputAmount])

  const handleDismissModal = useCallback(() => {
    setShowLaunchModal(false)
    // setTypedValue('')
    // setQuotePrice(undefined)
    setTransactionStatus('')
    setTransactionError(null)
    setIsCalculating(false)
    // setOneValue(undefined)
    setTransaction(null)
  }, [])
  const LaunchpadContract = SpookyLaunchpad()

  const parsedAmount = useMemo(
    () => tryParseCurrencyAmount(typedValue, inputCurrency ?? undefined),
    [inputCurrency, typedValue]
  )
  const [approvalState, approveCallback] = useApproveCallback(parsedAmount, LaunchpadContract?.address)

  const showApprovalState = approvalState !== ApprovalState.APPROVED && !!parsedAmount

  const DEFAULT_TOKEN_LAUNCHPAD_SLIPPAGE_TOLERANCE = new Percent(1, 100)
  const allowedSlippage = useUserSlippageToleranceWithDefault(DEFAULT_TOKEN_LAUNCHPAD_SLIPPAGE_TOLERANCE) // custom from users
  // const amountsMin = parsedAmount ? calculateSlippageAmount(parsedAmount, allowedSlippage as Percent)[0] : '0'
  // const { callback: swapCallback, error: swapCallbackError } = useSwapCallback(
  //   parsedAmount,
  //   outputCurrency,
  //   allowedSlippage,
  //   account,
  //   recipient,
  // )

  const addTransaction = useTransactionAdder()

  const { provider } = useWeb3React()

  const signer = provider?.getSigner()

  // const [approvalA, approveACallback] = useApproveCallback(
  //   parsedAmount ? parsedAmount : undefined,
  //   tokenConvertContract ? tokenConvertContract.address : undefined
  // )

  const [oneValue, setOneValue] = useState<CurrencyAmount<Currency>>()
  const [transactionError, setTransactionError] = useState<string | null>(null)

  const minSlippageAm = useMemo(() => {
    if (quotePrice && allowedSlippage) {
      return calculateSlippageAmount(quotePrice, allowedSlippage)[0]
    }
    return null
  }, [quotePrice, allowedSlippage])
  const [isCalculating, setIsCalculating] = useState(false)

  const [transactionStatus, setTransactionStatus] = useState('')

  useEffect(() => {
    const fetchQuote = async (value: string) => {
      if (LaunchpadContract && value) {
        setIsCalculating(true)
        try {
          const curr = inputCurrency === ETH ? false : true
          const quote = await getPriceQuote(LaunchpadContract, tokenAddress, value, curr)
          const quoteCurrencyAmount = CurrencyAmount.fromRawAmount(outputCurrency as Currency, quote?.toString() ?? '0')
          if (value === '1' && inputCurrency === ETH && typedValue === '') {
            setOneValue(quoteCurrencyAmount)
          } else {
            setQuotePrice(quoteCurrencyAmount)
          }
        } catch (error) {
          console.error('Error fetching quote:', error)
        } finally {
          setIsCalculating(false)
        }
      }
    }

    fetchQuote(typedValue || '1')
  }, [ETH, LaunchpadContract, inputCurrency, tokenAddress, typedValue, outputCurrency])

  const [transaction, setTransaction] = useState(null) // State to store the transaction

  const handleSwap = async () => {
    if (!LaunchpadContract || !quotePrice || !typedValue || !minSlippageAm) return
    const parsedTypedValue = parseEther(typedValue)
    try {
      await buyTokens(
        LaunchpadContract,
        tokenAddress,
        parsedTypedValue,
        quotePrice,
        allowedSlippage,
        minSlippageAm,
        signer,
        setTransactionStatus,
        (tx: any) => {
          console.log('Transaction added:', tx)
          setTransaction(tx)
        }
      )
    } catch (error) {
      console.error('Error during token purchase:', error)
      setTransactionError(error.message || 'Transaction failed')
    }
  }

  const handleSell = async () => {
    if (!LaunchpadContract || !quotePrice || !typedValue || !minSlippageAm) return
    const parsedTypedValue = parseEther(typedValue)

    try {
      await sellTokens(
        LaunchpadContract,
        tokenAddress,
        parsedTypedValue,
        quotePrice,
        allowedSlippage,
        minSlippageAm,
        signer,
        setTransactionStatus,
        (tx: any) => {
          console.log('Transaction added:', tx)
          setTransaction(tx)
        }
      )
    } catch (error) {
      console.error('Error during token purchase:', error)
    }
  }

  const theme = useTheme()

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

  const renderMinSlippageAm = (minSlippageAm: JSBI | null) => {
    if (!minSlippageAm) return 'Loading...'

    try {
      const WEI_TO_ETHER = JSBI.exponentiate(JSBI.BigInt(10), JSBI.BigInt(18))

      const etherValue = JSBI.toNumber(JSBI.divide(minSlippageAm, WEI_TO_ETHER))

      return numberWithCommas(etherValue.toFixed(2))
    } catch (error) {
      console.error('Error formatting minSlippageAm with JSBI:', error)
      return 'Error'
    }
  }

  const isMcapReached = useMemo(() => mcapReached, [mcapReached])

  if (isMcapReached) {
    return (
      <TokenInfoSection2>
          <h2>Presale complete.</h2>
        <ThemedText.BodySecondary>Launch coming soon...</ThemedText.BodySecondary>
      </TokenInfoSection2>
    )
  }

  return (
    <>
      <Modal isOpen={showLaunchModal} onDismiss={handleDismissModal}>
        <SwapModalFooter
          inputCurrency={inputCurrency ?? undefined}
          outputCurrency={outputCurrency ?? undefined}
          minSlippageAmount={minSlippageAm ? renderMinSlippageAm(minSlippageAm) : ''}
          expectedOutput={quotePrice ? parseFloat(quotePrice.toSignificant(18)).toFixed(2) : 0}
          exchangeRate={oneValue ? parseFloat(oneValue.toSignificant(18)).toFixed(2) : ''}
          typedValue={typedValue}
          quotePrice={quotePrice ? parseFloat(quotePrice.toSignificant(18)) : undefined}
          // quotePrice={quotePrice}
          allowedSlippage={allowedSlippage}
          onConfirm={tradeType === 'BUY' ? handleSwap : handleSell}
          text={transaction}
        ></SwapModalFooter>
      </Modal>
      <AutoColumn gap="md">
        <SwapContainer>
          <SwapWrapper style={{ paddingTop: '10px', background: theme.surface1 }}>
            <SwapHeader isUniXTrade={false} autoSlippage={allowedSlippage} chainId={chainId} />

            <SwapSection style={{ margin: '20px' }}>
              <SwapCurrencyInputPanel
                label={<Trans>You Pay</Trans>}
                value={typedValue}
                onUserInput={handleTypeInput}
                onMax={handleMaxInput}
                onLaunchpadClick={() => setTradeType((prev) => (prev === 'BUY' ? 'SELL' : 'BUY'))}
                showMaxButton={true}
                currency={inputCurrency}
                otherCurrency={outputCurrency}
                id="buy-sell-input"
              />
            </SwapSection>
            <ArrowWrapper clickable={true} style={{ marginTop: '-37px', marginBottom: '-37px' }}>
              <ArrowContainer
                data-testid="swap-currency-button"
                onClick={() => setTradeType((prev) => (prev === 'BUY' ? 'SELL' : 'BUY'))}
                color={theme.neutral1}
              >
                <ArrowDown size="16" color={theme.neutral1} />
              </ArrowContainer>
            </ArrowWrapper>

            <SwapSection style={{ margin: '20px' }}>
              <SwapCurrencyInputPanel
                label={<Trans>You receive</Trans>}
                value={quotePrice ? quotePrice.toExact() : '-'}
                onUserInput={handleTypeOutput}
                onMax={handleMaxInput}
                showMaxButton={false}
                currency={outputCurrency}
                otherCurrency={inputCurrency}
                disabled={true}
                id="buy-sell-input"
              />
            </SwapSection>
            {/* {account && (
          <StyledBalanceMax onClick={handleMaxInput}>
            <Trans>Balance: {inputBalance?.toSignificant() ?? '0'}</Trans>
          </StyledBalanceMax>
        )} */}

            <ContainerBottom>
              {/* {isCalculating && (
                <div style={{ margin: '10px 0', textAlign: 'center', color: theme.neutral1 }}>
                  <Trans>Quote calculating...</Trans>
                </div>
              )} */}
              {oneValue && quotePrice && !isCalculating ? (
                <AdvancedTradeDetails>
                  <AdvancedHeader>
                    1 {ETH?.symbol} = {parseFloat(oneValue.toSignificant(18)).toFixed(2)} {token?.symbol}
                  </AdvancedHeader>
                  <AutoColumn style={{ width: '100%' }}>
                    <AdvancedInfoHeader>
                      <AdvancedInfoMain>Minimum output</AdvancedInfoMain>
                      <AdvancedInfo>
                        {minSlippageAm ? renderMinSlippageAm(minSlippageAm) : 'Loading...'}
                      </AdvancedInfo>{' '}
                    </AdvancedInfoHeader>
                    <AdvancedInfoHeader>
                      <AdvancedInfoMain>Expected output</AdvancedInfoMain>
                      <AdvancedInfo>
                        {quotePrice ? numberWithCommas(parseFloat(quotePrice.toSignificant(18)).toFixed(2)) : 'Loading...'}
                      </AdvancedInfo>{' '}
                    </AdvancedInfoHeader>
                  </AutoColumn>
                </AdvancedTradeDetails>
              ) : isCalculating ? (
                <div style={{ margin: '10px 0', textAlign: 'center', color: theme.neutral1 }}>
                  <Trans>Quote calculating...</Trans>
                </div>
              ) : (
                <></>
              )}
              {!account ? (
                <ButtonPrimary onClick={toggleWalletDrawer}>
                  <Trans>Connect Wallet</Trans>
                </ButtonPrimary>
              ) : (
                <>
                  {showApprovalState ? (
                    <ButtonPrimary
                      onClick={approveCallback}
                      disabled={approvalState === ApprovalState.PENDING}
                      width="100%"
                    >
                      {approvalState === ApprovalState.PENDING ? (
                        <Dots>
                          <Trans>Approving {parsedAmount?.currency?.symbol}</Trans>
                        </Dots>
                      ) : (
                        <Trans>Approve {parsedAmount?.currency?.symbol}</Trans>
                      )}
                    </ButtonPrimary>
                  ) : tradeType === 'BUY' ? (
                    <>
                      <ButtonPrimary onClick={() => setShowLaunchModal(true)} disabled={!typedValue}>
                        <Trans>
                          {tradeType === 'BUY' ? 'Buy' : 'Sell'} {outputCurrency?.symbol}
                        </Trans>
                      </ButtonPrimary>
                    </>
                  ) : (
                    <>
                      <ButtonPrimary onClick={() => setShowLaunchModal(true)} disabled={!typedValue}>
                        <Trans>Sell {inputCurrency?.symbol}</Trans>
                      </ButtonPrimary>
                    </>
                  )}
                  {/* <StyledBalanceMax>
              <Trans>Balance: {outputBalance?.toSignificant(6) ?? '0'}</Trans>
            </StyledBalanceMax> */}

                  {/* {swapCallbackError && <ThemedText.Error>{swapCallbackError}</ThemedText.Error>} */}
                </>
              )}
              {/* transactionError && <div style={{ color: 'red' }}>{transactionError}</div>} */}
            </ContainerBottom>
          </SwapWrapper>
        </SwapContainer>
      </AutoColumn>
    </>
  )
}
