import { useWeb3React } from '@web3-react/core'
import { DiscordIcon, TwitterIcon } from 'components/About/Icons'
import Img from 'components/Img'
import PriceChart, { formatTimeAgo } from 'components/PriceChart'
import { RowBetween } from 'components/Row'
import { SpookyLaunchpad } from 'hooks/useContract'
import { TelegramIcon, WebsiteIcon } from 'nft/components/icons'
import { useGet24hVolume, useLaunchpadTokenById } from 'pages/LaunchPad/hooks/useLaunchpadTokens'
import React, { useEffect, useState } from 'react'
import { ArrowLeft } from 'react-feather'
import { Link, useParams } from 'react-router-dom'
import styled from 'styled-components'
import { BREAKPOINTS } from 'theme'
import { getExplorerLink } from 'utils/getExplorerLink'
import { ExplorerDataType } from 'utils/getExplorerLink'

import { useToken } from '../../hooks/Tokens'
import Chatbox from '../LaunchPad/Chat/chatWidget'
import BuySellPanel from './BuySellPanel'
import { ContentWrapper, LeftPanel, MiddlePanel, Panel, TokenStatsWrapper, Wrapper } from './styled'

const BreadcrumbNavLink = styled(Link)`
  display: flex;
  color: ${({ theme }) => theme.neutral2};
  font-size: 14px;
  line-height: 20px;
  align-items: center;
  gap: 4px;
  text-decoration: none;
  margin-bottom: 16px;
  transition-duration: ${({ theme }) => theme.transition.duration.fast};
  width: fit-content;

  margin-left: 20px;
  &:hover {
    color: ${({ theme }) => theme.neutral3};
  }

  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    margin-left: 0px;
  }
`

const TokenStats = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  font-size: 52px;
  @media screen and (max-width: ${BREAKPOINTS.lg}px) {
    font-size: 32px;
  }
`

const TokenStatsSecondary = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  font-size: 52px;
  color: ${({ theme }) => theme.neutral2};
  @media screen and (max-width: ${BREAKPOINTS.lg}px) {
    font-size: 32px;
  }
`

const ImgWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    height: 70px;
    width: 70px;

    @media screen and (max-width: ${BREAKPOINTS.lg}px) {
      height: 100px;
      width: 100px;
    }
  }
`

const TokenInfo = styled.div`
  display: flex;
  justify-content: left;
  align-items: left;
  width: 100%;
`

const SocialLinks = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 4px 0 12px;
`

const TokenInfoSection2 = styled.div`
  margin-bottom: 20px;
  margin: 20px;
  background: ${({ theme }) => theme.surface1};
  border: 1px solid ${({ theme }) => theme.surface3};
  border-radius: 16px;

  padding: 16px;
  // border-radius: 12px;
  // background: ${({ theme }) => theme.surface2};
  // margin-bottom: 16px;

  h2 {
    margin: 0 0 2px;
    font-size: 24px;
  }
  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    margin: 0;
  }
`

const ChatPanel = styled.div``

const LoadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 60vh;
  gap: 16px;
  color: ${({ theme }) => theme.neutral2};
`

const createLink = (link: string) => {
  const ret = link.includes('https') || link.includes('http') ? link : 'https://'.concat(link)
  return ret
}

export default function TokenPage() {
  const { account, chainId } = useWeb3React()
  const { tokenAddress } = useParams<{ tokenAddress: string }>()
  const tokenLaunchpad = useToken(tokenAddress) ?? null
  const [isMcapReached, setIsMcapReached] = useState(false)
  const { token: tokenById, loading, error, refetch: refetchUserPositions } = useLaunchpadTokenById(tokenAddress)
  const token = tokenById?.tokenCreated

  useEffect(() => {
    if (token) {
      if (token.tokenLauncheds.length == 0 && token.fakePoolMCapReacheds.length == 1) {
        setIsMcapReached(true)
      }
    }
  }, [token])
  const LaunchpadContract = SpookyLaunchpad()
  const currentTimestamp = Math.floor(Date.now() / 1000)

  const { volume, refetch: refetchVolume } = useGet24hVolume(tokenAddress || '0', currentTimestamp - 24 * 60 * 60)
  const { volume: volume24hAgo, refetch: refetchVolume24h } = useGet24hVolume(
    tokenAddress || '0',
    currentTimestamp - 24 * 60 * 60 * 2
  )
  let discordLink, websiteLink, twitterLink, telegramLink
  if (token != undefined)
    for (const link of token?.links) {
      if (link.toLowerCase().includes('t.me')) telegramLink = link
      else if (link.toLowerCase().includes('discord')) discordLink = link
      else if (link.toLowerCase().includes('twitter') || link.toLowerCase().includes('x.com')) twitterLink = link
      else websiteLink = link
    }

  useEffect(() => {
    const interval = setInterval(() => {
      refetchUserPositions()
      refetchVolume()
      refetchVolume24h()
    }, 2000) // Poll every 2 seconds

    return () => clearInterval(interval)
  }, [refetchUserPositions, refetchVolume, refetchVolume24h])

  useEffect(() => {
    if (!LaunchpadContract) return

    const handleSwap = async (
      sender: string,
      amount0In: any,
      amount1In: any,
      amount0Out: any,
      amount1Out: any,
      to: string
    ) => {
      console.log('New swap detected on token page, refreshing data...')
      refetchUserPositions()
      refetchVolume()
      refetchVolume24h()
    }

    LaunchpadContract.on('Swap', handleSwap)

    return () => {
      LaunchpadContract.off('Swap', handleSwap)
    }
  }, [LaunchpadContract, refetchUserPositions, refetchVolume, refetchVolume24h])

  useEffect(() => {
    if (token) {
      if (token.tokenLauncheds.length == 0 && token.fakePoolMCapReacheds.length == 1) {
        setIsMcapReached(true)
      }
    }
  }, [token])

  if (!token || !tokenAddress || !tokenById) {
    return (
      <Wrapper>
        <LoadingWrapper>
          <Img src="https://assets.spooky.fi/grimSpinner2.gif" width={227} />
          <div>Loading token data...</div>
        </LoadingWrapper>
      </Wrapper>
    )
  }

  return (
    <Wrapper>
      <BreadcrumbNavLink to="/launchpad">
        <ArrowLeft size={14} /> Launchpad
      </BreadcrumbNavLink>
      <Panel>
        <ImgWrapper>
          <img src={'https://harlequin-tiny-dove-45.mypinata.cloud/ipfs/' + token.imageCid} />
        </ImgWrapper>{' '}
        <TokenStatsWrapper>
          <TokenStats>{' ' + token.name}</TokenStats>
          <TokenStatsSecondary>{' ' + token.symbol}</TokenStatsSecondary>
        </TokenStatsWrapper>
      </Panel>
      <ContentWrapper>
        <MiddlePanel>
          <PriceChart
            tokenAddress={tokenAddress}
            tokenName={token.name}
            tokenSymbol={token.symbol}
            token={token}
            tokenPrice={tokenById.price}
            volume24h={volume}
            volume24hAgo={volume24hAgo}
          />
        </MiddlePanel>
        <LeftPanel>
          <BuySellPanel
            token={tokenLaunchpad}
            account={account}
            tokenAddress={tokenAddress}
            mcapReached={isMcapReached}
          />
          <TokenInfoSection2 style={{ marginTop: '0px' }}>
            <RowBetween>
              <h2>Info</h2>
              {token.links.length > 0 && (
                <SocialLinks>
                  {websiteLink && (
                    <a
                      style={{ display: 'flex' }}
                      href={createLink(websiteLink)}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <WebsiteIcon height={24} width={24} />
                    </a>
                  )}
                  {telegramLink && (
                    <a
                      style={{ display: 'flex' }}
                      href={createLink(telegramLink)}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <TelegramIcon height={24} width={24} />
                    </a>
                  )}
                  {twitterLink && (
                    <a
                      style={{ display: 'flex' }}
                      href={createLink(twitterLink)}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <TwitterIcon size={20} />
                    </a>
                  )}
                  {discordLink && (
                    <a
                      style={{ display: 'flex' }}
                      href={createLink(discordLink)}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <DiscordIcon size={20} />
                    </a>
                  )}
                </SocialLinks>
              )}
            </RowBetween>

            <div style={{ color: 'grey', display: 'flex', justifyContent: 'space-between' }}>
              {'Creation time '}
              <span style={{ color: 'white' }}>{formatTimeAgo(token.blockTimestamp)}</span>
            </div>
            <div style={{ color: 'grey', display: 'flex', justifyContent: 'space-between' }}>
              {' Created by '}
              {chainId && (
                <a
                  style={{ textDecoration: 'none' }}
                  href={getExplorerLink(chainId, token?.creator, ExplorerDataType.ADDRESS)}
                >
                  <span style={{ color: 'white' }}>{`${token?.creator.substring(0, 6)}...${token?.creator.slice(
                    -4
                  )}`}</span>
                </a>
              )}
            </div>
            <div style={{ color: 'grey', marginBottom: '20px', display: 'flex', justifyContent: 'space-between' }}>
              Token address
              {chainId && (
                <a
                  style={{ textDecoration: 'none' }}
                  href={getExplorerLink(chainId, token?.token, ExplorerDataType.TOKEN)}
                >
                  <span style={{ color: 'white' }}>{`${token?.token.substring(0, 6)}...${token?.token.slice(
                    -4
                  )}`}</span>
                </a>
              )}
            </div>
            <TokenInfo>{token.description}</TokenInfo>
          </TokenInfoSection2>
        </LeftPanel>
      </ContentWrapper>
      <ChatPanel>
        <Chatbox roomId={tokenAddress} isExpandable={true} />
      </ChatPanel>
    </Wrapper>
  )
}
