import { constructSDK } from '@orbs-network/liquidity-hub-sdk'
import { ChainId } from '@uniswap/sdk-core'
import { useWeb3React } from '@web3-react/core'
import { DEFAULT_CHAIN_ID, LIQUIDITY_HUB_PARTNER_ID } from 'constants/misc'
import { useMemo } from 'react'

// eslint-disable-next-line import/no-unused-modules
export function useLiquidityHubSDK() {
  const { chainId } = useWeb3React()

  return useMemo(() => {
    return getLiquidityHubSDK(chainId)
  }, [chainId])
}

export function getLiquidityHubSDK(chainId: ChainId | undefined) {
  return constructSDK({ chainId: chainId ?? DEFAULT_CHAIN_ID, partner: LIQUIDITY_HUB_PARTNER_ID })
}
