import styled from 'styled-components'
import { BREAKPOINTS } from 'theme'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
  max-width: 100vw;
`

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 1200px;
  width: 100%;
  align-items: center;

  @media screen and (max-width: ${BREAKPOINTS.lg}px) {
    flex-direction: column;
    justify-content: center;
  }
`

export const Panel = styled.div`
  display: flex;
  flex-direction: row;
  margin-right: 20px;
  width: 100%;
  margin-left: 20px;
  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    // flex-direction: column;
    // justify-content: flex-start;
    // // align-items: left;
    // margin: 0px 10px;
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: 20px;
  }
`

export const TokenStatsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  // margin-right: 20px;
  width: 100%;
  // margin-left: 20px;
  @media screen and (max-width: ${BREAKPOINTS.lg}px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: left;
    margin: 0px 10px;
  }
`

export const LeftPanel = styled.div`
  margin-right: 20px;
  align-self: flex-start;
  min-width: 350px;
  max-width: 430px;
  @media screen and (max-width: ${BREAKPOINTS.lg}px) {
    // flex-direction: column;
    // justify-content: center;
    width: 100%;
    // max-width: 100%;
    min-width: 100%;
  }
`

export const MiddlePanel = styled.div`
  margin: 20px;
  min-width: 600px;
  align-self: flex-start;
  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    // flex-direction: column;
    // justify-content: center;
    min-width: 100%;
    margin: 0;
  }
`

// export const StyledBalanceMax = styled(RowBetween)`
//   color: ${({ theme }) => theme.text3};
//   font-size: 14px;
//   padding: 0 12px;
//   margin-top: -12px;
//   margin-bottom: 12px;
// `

// export const BuySellButton = styled(ButtonPrimary)<{ isSelected: boolean }>`
//   margin-top: 10px;
//   width: 48%;
//   background-color: ${({ theme, isSelected }) => (isSelected ? theme.accent1 : theme.background)};
//   color: ${({ theme, isSelected }) => (isSelected ? theme.white : theme.accent1)};
//   &:not(:last-child) {
//     margin-right: 4%;
//   }
// `
