/* eslint-disable import/no-unused-modules */
// hooks/useLaunchpadTokens.ts
import { ChainId } from '@uniswap/sdk-core'
import { useWeb3React } from '@web3-react/core'
import { DEFAULT_CHAIN_ID } from 'constants/misc'
import { useCallback, useEffect, useState } from 'react'

export interface LaunchpadToken {
  id: string
  name: string
  symbol: string
  creator: string
  description: string
  imageCid: string
  links: string[]
  price: string
  supply: string
  token: string
  blockTimestamp: number
  blockNumber: number
  transactionHash: string
  lpStrategy: string
}

export interface BoughtAndSold {
  newPrice: string
  blockTimestamp: number
  buyer?: string
  seller?: string
}

export interface BoughtAndSoldExtended extends BoughtAndSold {
  ethIn?: string
  ethOut?: string
  tokensIn?: string
  tokensOut?: string
}

export interface BoughtAndSolds {
  boughts: BoughtAndSold[]
  solds: BoughtAndSold[]
}

export interface BoughtAndSoldsExtended {
  boughts: BoughtAndSoldExtended[]
  solds: BoughtAndSoldExtended[]
}

type GraphQLResponse<T> = {
  data: T
  errors?: { message: string }[]
}

const LAUNCHPAD_SUBGRAPH_URL: Record<number, string | undefined> = {
  [ChainId.FANTOM]: process.env.REACT_APP_LAUNCHPAD_SUBGRAPH_URL_FANTOM,
  [ChainId.SONIC]: process.env.REACT_APP_LAUNCHPAD_SUBGRAPH_URL_SONIC,
}

const V3_SUBGRAPH_URL: Record<number, string | undefined> = {
  [ChainId.SONIC]: process.env.REACT_APP_V3_GRAPH_URL_SONIC,
}

export function useWNativeUSDPrice() {
  const { chainId } = useWeb3React()
  const chainIdOrDefault = chainId ?? DEFAULT_CHAIN_ID
  const [wnativePrice, setWNativeUsdPrice] = useState(0)

  const fetchTokens = useCallback(async () => {
    if (!chainId || !V3_SUBGRAPH_URL[chainIdOrDefault]) return

    const query = `
      {
        bundle(id: "1") {
          ethPriceUSD
        }
      }
    `

    const options = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ query }),
    }

    try {
      const response = await fetch(V3_SUBGRAPH_URL[chainIdOrDefault], options)
      if (!response.ok) throw new Error('Network response was not ok')

      const result = await response.json()
      if (result.errors) throw new Error(result.errors.map((e) => e.message).join(', '))

      setWNativeUsdPrice(result.data.bundle.ethPriceUSD)
    } catch (err) {
    } finally {
    }
  }, [chainId])

  useEffect(() => {
    fetchTokens()
  }, [fetchTokens])

  return {
    wnativePrice,
    refetch: fetchTokens,
  }
}

export function useLaunchpadTokens(first = 100) {
  const { chainId } = useWeb3React()
  const chainIdOrDefault = chainId ?? DEFAULT_CHAIN_ID
  const [tokens, setTokens] = useState<LaunchpadToken[]>([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<Error | null>(null)

  const fetchTokens = useCallback(async () => {
    if (!chainId || !LAUNCHPAD_SUBGRAPH_URL[chainIdOrDefault]) return

    const query = `
      {
        tokenCreateds(
          first: ${first}
          orderBy: blockTimestamp
          orderDirection: desc
        ) {
          id
          name
          symbol
          creator
          description
          imageCid
          links
          price
          supply
          token
          blockTimestamp
          blockNumber
          transactionHash
          lpStrategy
        }
      }
    `

    const options = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ query }),
    }

    try {
      const response = await fetch(LAUNCHPAD_SUBGRAPH_URL[chainIdOrDefault], options)
      if (!response.ok) throw new Error('Network response was not ok')

      const result: GraphQLResponse<{ tokenCreateds: LaunchpadToken[] }> = await response.json()
      if (result.errors) throw new Error(result.errors.map((e) => e.message).join(', '))

      setTokens(result.data.tokenCreateds)
    } catch (err) {
      setError(err as Error)
    } finally {
      setLoading(false)
    }
  }, [chainId, first])

  useEffect(() => {
    fetchTokens()
  }, [fetchTokens])

  return {
    tokens,
    loading,
    error,
    refetch: fetchTokens,
  }
}

export function useLaunchpadTokenById(id?: any) {
  const { chainId } = useWeb3React()
  const chainIdOrDefault = chainId ?? DEFAULT_CHAIN_ID

  const [token, setToken] = useState<any>()
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<Error | null>(null)

  const fetchToken = useCallback(async () => {
    if (!chainIdOrDefault || !LAUNCHPAD_SUBGRAPH_URL[chainIdOrDefault]) return

    const query = `
      {
        pool(id: "${id}") {
          tokenCreated {
            id
            name
            symbol
            creator
            description
            imageCid
            links
            price
            supply
            token
            blockTimestamp
          }
          price
          tokenCreated {
            tokenLauncheds {
              token
            }
            fakePoolMCapReacheds {
              token
            }
          }
        }
      }
    `
    const options = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ query }),
    }

    try {
      const response = await fetch(LAUNCHPAD_SUBGRAPH_URL[chainIdOrDefault], options)
      if (!response.ok) throw new Error('Network response was not ok')

      const result = await response.json()
      if (result.errors) throw new Error(result.errors.map((e) => e.message).join(', '))

      setToken(result.data.pool)
    } catch (err) {
      setError(err as Error)
    } finally {
      setLoading(false)
    }
  }, [chainIdOrDefault, id])

  useEffect(() => {
    if (id !== '') fetchToken()
  }, [fetchToken, id])

  return {
    token,
    loading,
    error,
    refetch: fetchToken,
  }
}

export function useLaunchpadTokensFromPools(first = 100) {
  const { chainId } = useWeb3React()
  const chainIdOrDefault = chainId ?? DEFAULT_CHAIN_ID

  const [tokensFromPools, setTokensFromPools] = useState<any[]>([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<Error | null>(null)

  const fetchTokens = useCallback(async () => {
    if (!chainId || !LAUNCHPAD_SUBGRAPH_URL[chainIdOrDefault]) return

    const query = `
      {
        pools(first: ${first}, orderBy: price, orderDirection: desc) {
          tokenCreated {
            id
            name
            symbol
            creator
            description
            imageCid
            links
            supply
            token
            blockTimestamp
          }
          price
          tokenCreated {
            tokenLauncheds {
              token
            }
            fakePoolMCapReacheds {
              token
            }
          }
        }
      }
    `

    const options = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ query }),
    }

    try {
      const response = await fetch(LAUNCHPAD_SUBGRAPH_URL[chainIdOrDefault], options)
      if (!response.ok) throw new Error('Network response was not ok')

      const result: GraphQLResponse<{ tokenCreateds: any[] }> = await response.json()
      if (result.errors) throw new Error(result.errors.map((e) => e.message).join(', '))

      result.data.pools.sort(function (a, b) {
        return b.price - a.price || b.tokenCreated.blockTimestamp - a.tokenCreated.blockTimestamp
      })

      setTokensFromPools(result.data.pools)
    } catch (err) {
      setError(err as Error)
    } finally {
      setLoading(false)
    }
  }, [chainId, first])

  useEffect(() => {
    fetchTokens()
  }, [fetchTokens])

  return {
    tokensFromPools,
    loading,
    error,
    refetch: fetchTokens,
  }
}

export function useGetTokenPrice(token: string) {
  const { chainId } = useWeb3React()
  const chainIdOrDefault = chainId ?? DEFAULT_CHAIN_ID
  const [priceMap, setPriceMap] = useState(new Map())
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<Error | null>(null)
  let price = 0

  const getTokenPrice = useCallback(async () => {
    if (!chainId || !LAUNCHPAD_SUBGRAPH_URL) return

    const query = `
      {
        boughts(
          first: 1
          orderBy: blockTimestamp
          orderDirection: desc
          where: {token: "${token}"}
        ) {
          newPrice
          blockNumber
        },
        solds(
          first: 1
          orderBy: blockTimestamp
          orderDirection: desc
          where: {token: "${token}"}
        ) {
          newPrice
          blockNumber
        }
      }
    `

    const options = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ query }),
    }

    try {
      const response = await fetch(LAUNCHPAD_SUBGRAPH_URL[chainIdOrDefault], options)
      if (!response.ok) throw new Error('Network response was not ok')

      const result = await response.json()
      if (result.errors) throw new Error(result.errors.map((e) => e.message).join(', '))
      console.log(result)

      if (result.data.boughts.length == 0 && result.data.solds.length == 0) {
        price = 10000000000
      } else if (result.data.boughts.length == 0) {
        price = result.data.solds[0].newPrice
      } else if (result.data.solds.length == 0) {
        price = result.data.boughts[0].newPrice
      } else {
        price =
          result.data.boughts[0].blockNumber > result.data.solds[0].blockNumber
            ? result.data.boughts[0].newPrice
            : result.data.solds[0].newPrice
      }
      console.log(price)
    } catch (err) {
      setError(err as Error)
    } finally {
      setLoading(false)
    }
  }, [chainId, token])

  useEffect(() => {
    getTokenPrice()
  }, [getTokenPrice])

  return {
    price,
  }
}

export function useGetTrades(token: string) {
  const { chainId } = useWeb3React()
  const chainIdOrDefault = chainId ?? DEFAULT_CHAIN_ID

  const [trades, setTrades] = useState<BoughtAndSoldExtended[]>([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<Error | null>(null)

  const fetchTokens = useCallback(async () => {
    if (!chainId || !LAUNCHPAD_SUBGRAPH_URL[chainIdOrDefault]) return

    const query = `
      {
        boughts(
          orderBy: blockTimestamp
          orderDirection: asc
          where: {token: "${token}"}
        ) {
          newPrice
          blockTimestamp
          buyer
          ethIn
        },
        solds(
          orderBy: blockTimestamp
          orderDirection: asc
          where: {token: "${token}"}
        ) {
          newPrice
          blockTimestamp
          seller
          ethOut
        }
      }
    `

    const options = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ query }),
    }

    try {
      const response = await fetch(LAUNCHPAD_SUBGRAPH_URL[chainIdOrDefault], options)
      if (!response.ok) throw new Error('Network response was not ok')

      const result: GraphQLResponse<BoughtAndSoldsExtended> = await response.json()

      const fakeSells: BoughtAndSoldExtended[] = [
        { blockTimestamp: 1733414679, seller: '0x9fa4a493bad12de74810e0b4322ec050756c4802', newPrice: '9910025000' },
        { blockTimestamp: 1733514920, seller: '0x9fa4a493bad12de74810e0b4322ec050756c4802', newPrice: '10052260100' },
      ]

      const orders: BoughtAndSoldExtended[] = result.data.boughts.concat(result.data.solds)
      //orders = orders.concat(fakeSells)
      orders.sort((a, b) => a.blockTimestamp - b.blockTimestamp)

      setTrades(orders)
    } catch (err) {
      setError(err as Error)
    } finally {
      setLoading(false)
    }
  }, [chainId, token])

  useEffect(() => {
    fetchTokens()
  }, [fetchTokens])

  return {
    trades,
    loading,
    error,
    refetch: fetchTokens,
  }
}

export function useGetLatestTradesWithAmount(token: string, amount: number) {
  const { chainId } = useWeb3React()
  const chainIdOrDefault = chainId ?? DEFAULT_CHAIN_ID

  const [tradesWithAmount, setTradesWithAmount] = useState<BoughtAndSoldExtended[]>([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<Error | null>(null)

  const fetchTokens = useCallback(async () => {
    if (!chainId || !LAUNCHPAD_SUBGRAPH_URL[chainIdOrDefault]) return

    const query = `
      {
        boughts(
          first: ${amount}
          orderBy: blockTimestamp
          orderDirection: desc
          where: {token: "${token}"}
        ) {
          newPrice
          blockTimestamp
          buyer
          ethIn
          tokensOut
        },
        solds(
          first: ${amount}
          orderBy: blockTimestamp
          orderDirection: desc
          where: {token: "${token}"}
        ) {
          newPrice
          blockTimestamp
          seller
          ethOut
          tokensIn
        }
      }
    `

    const options = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ query }),
    }

    try {
      const response = await fetch(LAUNCHPAD_SUBGRAPH_URL[chainIdOrDefault], options)
      if (!response.ok) throw new Error('Network response was not ok')

      const result: GraphQLResponse<BoughtAndSoldsExtended> = await response.json()

      const orders: BoughtAndSoldExtended[] = result.data.boughts.concat(result.data.solds)
      orders.sort((a, b) => b.blockTimestamp - a.blockTimestamp)

      setTradesWithAmount(orders)
    } catch (err) {
      setError(err as Error)
    } finally {
      setLoading(false)
    }
  }, [chainId, token, amount])

  useEffect(() => {
    fetchTokens()
  }, [fetchTokens])

  return {
    tradesWithAmount,
    loading,
    error,
    refetch: fetchTokens,
  }
}

export function useGetRecentSwaps(first: number) {
  const { chainId } = useWeb3React()
  const chainIdOrDefault = chainId ?? DEFAULT_CHAIN_ID

  const [recentSwaps, setRecentSwaps] = useState<any[]>([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<Error | null>(null)

  const fetchTokens = useCallback(async () => {
    if (!chainId || !LAUNCHPAD_SUBGRAPH_URL[chainIdOrDefault]) return

    const query = `
      {
        swaps(first: ${first}, orderBy: blockTimestamp, orderDirection: desc) {
          amount0In
          amount0Out
          amount1In
          amount1Out
          blockTimestamp
          sender
          transactionHash
          pool {
            tokenCreated {
              symbol
            }
            token
          }
        }
      }
    `

    const options = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ query }),
    }

    try {
      const response = await fetch(LAUNCHPAD_SUBGRAPH_URL[chainIdOrDefault], options)
      if (!response.ok) throw new Error('Network response was not ok')

      const result: GraphQLResponse<any> = await response.json()

      setRecentSwaps(result.data.swaps)
    } catch (err) {
      setError(err as Error)
    } finally {
      setLoading(false)
    }
  }, [chainId, first])

  useEffect(() => {
    fetchTokens()
  }, [fetchTokens])

  return {
    recentSwaps,
    loading,
    error,
    refetch: fetchTokens,
  }
}

export function useGet24hVolume(token: string, lastDate: number) {
  const { chainId } = useWeb3React()
  const chainIdOrDefault = chainId ?? DEFAULT_CHAIN_ID

  const [volume, setVolume] = useState<any>([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<Error | null>(null)

  const fetchTokens = useCallback(async () => {
    if (!chainId || !LAUNCHPAD_SUBGRAPH_URL[chainIdOrDefault]) return

    const query = `
      {
        poolDayDatas(
          first: 1
          orderBy: date
          orderDirection: asc
          where: {pool_: {token: "${token}"}${lastDate == 0 ? '}' : `, date_gte: ${lastDate}}`}
        ) {
          dailyVolumeETH
          date
        }
      }
    `

    const options = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ query }),
    }

    try {
      const response = await fetch(LAUNCHPAD_SUBGRAPH_URL[chainIdOrDefault], options)
      if (!response.ok) throw new Error('Network response was not ok')

      const result: GraphQLResponse<any> = await response.json()

      const dateDifference =
        lastDate - result.data.poolDayDatas[0].date < 0
          ? -1 * (lastDate - result.data.poolDayDatas[0].date)
          : lastDate - result.data.poolDayDatas[0].date
      setVolume(dateDifference <= 24 * 60 * 60 ? result.data.poolDayDatas[0].dailyVolumeETH : '0')
    } catch (err) {
      setError(err as Error)
    } finally {
      setLoading(false)
    }
  }, [chainId, token])

  useEffect(() => {
    fetchTokens()
  }, [fetchTokens])

  return {
    volume,
    loading,
    error,
    refetch: fetchTokens,
  }
}
