import { Trans } from '@lingui/macro'
import { ChainId, Token } from '@uniswap/sdk-core'
import { useWeb3React } from '@web3-react/core'
import { StyledLoadingButtonSpinner } from 'components/AccountDrawer/AuthenticatedHeader'
import { ButtonPrimary } from 'components/Button'
import { AutoColumn } from 'components/Column'
import Img from 'components/Img'
import { AutoRow, RowBetween } from 'components/Row'
import { BOO_ADDRESS, XBOO_ADDRESS } from 'constants/addresses'
import { DEFAULT_CHAIN_ID } from 'constants/misc'
import { BigNumber } from 'ethers'
import useBooSupply, { useXBooRatio } from 'hooks/useBooBalance'
import { useConvertLogContract, useSpookV2, useXbooContract } from 'hooks/useContract'
import { useBooLandStakeV2 } from 'hooks/useStakeV2'
import { useBooLandUnstakeV2 } from 'hooks/useUnstakeV2'
import { useTokenBalance } from 'lib/hooks/useCurrencyBalance'
import { SpookyIcon, XBooIcon } from 'nft/components/icons'
import { formatWeiToDecimal } from 'nft/utils'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Button } from 'rebass'
import { useUSDPricesWithFallback } from 'state/cache/hooks'
import styled from 'styled-components'
import { BREAKPOINTS, Separator } from 'theme'
import { registerToken } from 'utils/registerToken'

import StakeModal from './StakeModal'
import UnstakeModal from './UnstakeModal'

const TitleRow = styled(RowBetween)`
  color: ${({ theme }) => theme.chain_250};
  font-size: 2rem;
  display: flex;
  text-align: left;
  padding-left: 10px;
  padding-right: 10px;
  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    font-size: 1.5rem;
  }
`

const BridgeWrapper = styled.div`
  background: ${({ theme }) => theme.surface1};
  width: 100%;
  max-width: 800px; /* Adjust if needed */
  margin: 0;
  padding: 24px;
  display: flex; /* Use flexbox to split into halves */
  justify-content: space-between; /* Space between the two sections */
  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    flex-direction: column;
  }
`

const LeftSide = styled.div`
  flex: 1;
  margin-right: 30px;
  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    margin-right: 0;
  }
`

const RightSide = styled.div`
  flex: 1;
  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    margin-left: 0;
  }
  padding: 20px;
  background: linear-gradient(to top, rgba(115, 204, 231, 0.1), rgba(115, 204, 231, 0)) 0% 0%,
    linear-gradient(to bottom, rgba(115, 204, 231, 0.1), rgba(115, 204, 231, 0)) 0% 100%,
    linear-gradient(to right, rgba(115, 204, 231, 0.1), rgba(115, 204, 231, 0)) 100% 0%,
    linear-gradient(to left, rgba(115, 204, 231, 0.1), rgba(115, 204, 231, 0)) 0% 0%;
  background-size: 100% 50%, 100% 50%, 50% 100%, 50% 100%;
  background-repeat: no-repeat;

  display: flex;
  flex-direction: column;
  align-items: center; /* Center items horizontally */
  justify-content: center; /* Center items vertically */
`
const StakeContainer = styled.div`
  align-items: center;
  background: ${({ theme }) => theme.surface1};
  width: 100%;
  max-width: 1000px;
  min-width: 0;
  border-radius: 16px;
  border: 1px solid ${({ theme }) => theme.surface3};
  margin: 0;
  padding: 24px 24px 0 24px;
`

const StakeTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 0;
  margin-bottom: 24px;
  padding: 0px;
  // background: linear-gradient(
  //   90deg,
  //   rgba(${({ theme }) => theme.chain_250}, 0.2) 0%,
  //   rgba(${({ theme }) => theme.chain_250}, 0.1) 50%,
  //   rgba(${({ theme }) => theme.chain_250}, 0) 100%
  // );
  // border-bottom: 1px solid ${({ theme }) => theme.neutral3};
`

const InfoText = styled.p`
  font-size: 14px;
`

const Upper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 20px;
  // padding: 20px;
`

const ButtonStakeContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 5px;
`

const ButtonDisplay = styled(Button)`
  color: ${({ theme }) => theme.neutral2};
  background: none;
  cursor: pointer;
`

const ButtonStake = styled(ButtonPrimary)`
  border-radius: 24px;
  padding: 5px;
  margin: 5px;
  max-width: 70%;
`

const StatWrapper = styled.div`
  display: flex;
  margin: 20px;
  flex-direction: column;
`

const StatContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
`

const StatTitle = styled.div`
  display: flex;
  width: 100%;
  color: ${({ theme }) => theme.chain_250};
`

const StatValue = styled.div`
  display: flex;
  width: 100%;
  align-items: right;
  justify-content: right;
  text-align: right;
`

const StatBooIcon = styled.div`
  display: flex;
  margin-right: 5px;
`

// const ProgressWrapper = styled.div`
//   width: 100%;
//   margin-top: 1rem;
//   height: 4px;
//   border-radius: 4px;
//   background-color: ${({ theme }) => theme.surface2};
//   position: relative;
// `

// const Progress = styled.div<{ status: 'for' | 'against'; percentageString?: string }>`
//   height: 4px;
//   border-radius: 4px;
//   background-color: ${({ theme, status }) => (status === 'for' ? theme.chain_250 : theme.critical)};
//   width: ${({ percentageString }) => percentageString ?? '0%'};
// `

const Opacity = styled.div`
  display: flex;
  opacity: 0.5;
`

const BooContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px;
`

export default function StakeBooV2() {
  const { chainId, account } = useWeb3React()
  const chainIdOrDefault = chainId ?? DEFAULT_CHAIN_ID

  const BOO_TOKEN_NAME = 'BOO'
  const BREWBOO_ADDRESS = '0xc3815bF058fB94243Ebc6c559dfc59ceaEeF00eA'

  const BooLandContract = useXbooContract()
  const Boo = useSpookV2()
  const BOO_TOKEN = new Token(chainIdOrDefault, BOO_ADDRESS[chainIdOrDefault], 18, BOO_TOKEN_NAME, BOO_TOKEN_NAME)
  const XBOO_TOKEN = new Token(chainIdOrDefault, XBOO_ADDRESS[chainIdOrDefault], 18, `x${BOO_TOKEN_NAME}`, `x${BOO_TOKEN_NAME}`)
  const BrewBooV3 = useConvertLogContract(BREWBOO_ADDRESS)
  const xBooRatio = useXBooRatio()
  const booAmountInXbooContract = parseFloat((useBooSupply(XBOO_ADDRESS[chainIdOrDefault])).toString()) / 1e18

  const xBooRatioFormatted = xBooRatio ? parseFloat(xBooRatio.toString()) / 1e18 : 0
  const [claimableBoo, setClaimableBoo] = useState<number | null>(0)
  const [boughtBackBoo, setBoughtBackBoo] = useState<number | null>(0)
  const [boughtBackBoo7d, setBoughtBackBoo7d] = useState<number | null>(0)

  useEffect(() => {
    const fetchBooBalanceInXBOO = async () => {
      if (!account) return

      try {
        // Fetch the BOO balance in the xBOO contract
        const booBalance = await BooLandContract?.BOOBalance(account)

        const formattedBooBalance = booBalance ? parseFloat(booBalance.toString()) / 1e18 : 0 // Adjust decimals based on actual token decimals
        setClaimableBoo(formattedBooBalance)
      } catch (error) {
        console.error('Failed to fetch BOO balance from xBOO contract:', error)
      }
    }

    fetchBooBalanceInXBOO()
  }, [account, BooLandContract])

  useEffect(() => {
    const fetchBoughtBackBoo = async () => {
      let booAmount: BigNumber = BigNumber.from(0)
      let res1
      try {
        res1 = await fetch(`https://api.findblock.xyz/v1/chain/${chainIdOrDefault}/block/before/${Math.floor(Date.now() / 1000) - 86400}?inclusive=true`, {
          method: 'GET',
        })
      } catch {
        console.log("failed findblock")
      }
      let block1DayAgo:number = (await res1?.json()).number
      //let blockLatest:number = (await provider?.getBlock("latest"))?.number
      
      const buyBacks = await BrewBooV3?.queryFilter(BrewBooV3?.filters.LogConvert(), block1DayAgo)
      for (const buyback of buyBacks) {
        booAmount = booAmount.add(buyback.args.amountBOO)
      }
      const booBoughtback = (formatWeiToDecimal(booAmount.toHexString()).replaceAll(',', '') as unknown as number) / 1
      setBoughtBackBoo(Number.isNaN(booBoughtback) ? 0 : booBoughtback)

      booAmount = BigNumber.from(0)
      try {
        res1 = await fetch(`https://api.findblock.xyz/v1/chain/${chainIdOrDefault}/block/before/${Math.floor(Date.now() / 1000) - 86400*7}?inclusive=true`, {
          method: 'GET',
        })
      } catch {
        console.log("failed findblock7")
      }
      let block7DayAgo:number = (await res1?.json()).number
      const buyBacks7 = await BrewBooV3?.queryFilter(BrewBooV3?.filters.LogConvert(), block7DayAgo)
      for (const buyback7 of buyBacks7) {
        booAmount = booAmount.add(buyback7.args.amountBOO)
      }
      const booBoughtback7 = (formatWeiToDecimal(booAmount.toHexString()).replaceAll(',', '') as unknown as number) / 1
      setBoughtBackBoo7d(Number.isNaN(booBoughtback7) ? 0 : booBoughtback7)
    }

    fetchBoughtBackBoo()
  }, [BooLandContract, BrewBooV3])

  const { onStake } = useBooLandStakeV2(account, Boo)
  const { onUnstake } = useBooLandUnstakeV2()

  const [pendingTx, setPendingTx] = useState(false)
  const [, setShowConfirm] = useState(false)
  const [isStakeModalOpen, setIsStakeModalOpen] = useState(false)
  const [isUnstakeModalOpen, setIsUnstakeModalOpen] = useState(false)

  //TODO: unify these two calls in a singular "useTokenBalances" call
  const booBalance = useTokenBalance(account, BOO_TOKEN)
  const xbooBalance = useTokenBalance(account, XBOO_TOKEN)

  const priceBooPrecise = useUSDPricesWithFallback()[BOO_ADDRESS[chainIdOrDefault]]

  const usdformatter = Intl.NumberFormat('en', { notation: 'compact' })


  const tvl = useMemo(() => {
    if (booAmountInXbooContract && priceBooPrecise) {
      const totalBoo = Number(booAmountInXbooContract)

      return totalBoo * priceBooPrecise
    }
    return 0
  }, [booAmountInXbooContract, priceBooPrecise])

  const claimBooBalance = useMemo(() => {
    if (claimableBoo && priceBooPrecise) {
      return claimableBoo * priceBooPrecise
    }
    return 0
  }, [claimableBoo, priceBooPrecise])

  const tradeFeeAPR = (boughtBackBoo * 365.25 / Number(booAmountInXbooContract)) * 100
  const tradeFeeAPR7d = (boughtBackBoo7d * 365.25 / (7 * Number(booAmountInXbooContract))) * 100

  const handleStake = () => {
    setIsStakeModalOpen(true)
  }

  const handleUnstake = () => {
    setIsUnstakeModalOpen(true)
  }

  const registerXBOO = useCallback(() => {
    return registerToken(
      XBOO_ADDRESS[chainIdOrDefault],
      `x${BOO_TOKEN_NAME}`,
      18,
      `https://assets.spooky.fi/tokens/x${BOO_TOKEN_NAME}.png`
    )
  }, [])

  return (
    <>
      <StakeModal
        max={booBalance}
        isOpen={isStakeModalOpen}
        onConfirm={onStake}
        tokenAddr={BOO_ADDRESS[ChainId.FANTOM]}
        tokenName={BOO_TOKEN_NAME}
        setAttemptingTxn={setPendingTx}
        setShowConfirm={setShowConfirm}
        onDismiss={() => setIsStakeModalOpen(false)}
      />
      <UnstakeModal
        max={xbooBalance}
        isOpen={isUnstakeModalOpen}
        onConfirm={onUnstake}
        tokenAddr={XBOO_ADDRESS[ChainId.FANTOM]}
        tokenName={BOO_TOKEN_NAME}
        setAttemptingTxn={setPendingTx}
        setShowConfirm={setShowConfirm}
        onDismiss={() => setIsUnstakeModalOpen(false)}
      />
      <AutoColumn gap="lg" justify="center">
        <AutoColumn gap="lg" style={{ width: '100%', marginTop: '100px' }}></AutoColumn>

        <StakeContainer>
          <StakeTitleContainer>
            <AutoColumn gap="lg" style={{ width: '100%', marginTop: '0px' }}>
              <TitleRow padding="0">
                <Trans> Stake {`${BOO_TOKEN_NAME}`}, Receive {`x${BOO_TOKEN_NAME}`} Immediately </Trans>
              </TitleRow>
              {/*<TitleRow padding="0">
                <ThemedText.SubHeader>
                  <Trans> Get xBOO buyback receipt tokens here for step 2. </Trans>
                </ThemedText.SubHeader>
              </TitleRow>*/}
            </AutoColumn>
          </StakeTitleContainer>
          <AutoRow style={{ width: '100%', justifyContent: 'center', flexWrap: 'nowrap' }}>
            <BridgeWrapper>
              <LeftSide>
                <AutoRow
                  style={{
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginBottom: '10px',
                    display: 'flex',
                    flexWrap: 'wrap',
                  }}
                >
                  <Upper>
                    {/* <ProgressWrapper>
                      <Progress
                        status="for"
                        percentageString={booBalance?.greaterThan(0) ? `${booBalance.toFixed(0) ?? 0}%` : '0%'}
                      />
                    </ProgressWrapper> */}
                    <AutoRow
                      style={{
                        alignItems: 'center',
                        justifyContent: 'space-evenly',
                        marginBottom: '10px',
                        display: 'flex',
                        flexWrap: 'wrap',
                      }}
                    ></AutoRow>
                    <AutoRow
                      style={{
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        marginBottom: '10px',
                        display: 'flex',
                        flexWrap: 'nowrap',
                      }}
                    >
                      <BooContainer>
                        1 {`x${BOO_TOKEN_NAME}`}
                        <Opacity>
                          <XBooIcon width={90} height={90} />
                        </Opacity>
                      </BooContainer>
                      =
                      <BooContainer>
                        {xBooRatioFormatted.toLocaleString(undefined, {
                          maximumFractionDigits: 4,
                        })}
                        &nbsp;{BOO_TOKEN_NAME}
                        <Opacity>
                          <SpookyIcon width={90} height={90} />
                        </Opacity>
                      </BooContainer>
                    </AutoRow>
                    <Separator />
                    <StatWrapper>
                      {account && (
                        <>
                          <StatContainer>
                            <StatTitle>Your Claimable {BOO_TOKEN_NAME}</StatTitle>
                            <StatValue>
                              <StatBooIcon>
                                <Img
                                  src={`https://assets.spooky.fi/tokens/${BOO_TOKEN_NAME}.png`}
                                  alt={`${BOO_TOKEN_NAME} Token`}
                                  height="20px"
                                  width="20px"
                                />
                              </StatBooIcon>
                              {claimBooBalance !== null || claimBooBalance !== undefined ? (
                                claimableBoo?.toLocaleString(undefined, {
                                  maximumFractionDigits: 2,
                                }) +
                                ` ($${usdformatter.format(claimBooBalance)})`
                              ) : (
                                <StyledLoadingButtonSpinner />
                              )}
                            </StatValue>
                          </StatContainer>
                        </>
                      )}
                      <StatContainer>
                        <StatTitle> TVL </StatTitle>
                        <StatValue>
                          <StatBooIcon>
                            {tvl !== 0 && <Img
                                  src={`https://assets.spooky.fi/tokens/${BOO_TOKEN_NAME}.png`}
                                  alt={`${BOO_TOKEN_NAME} Token`}
                              height="20px"
                              width="20px"
                            />}
                          </StatBooIcon>
                          {tvl === 0 ? (
                            <StyledLoadingButtonSpinner />
                          ) : (
                            `${(tvl / priceBooPrecise).toLocaleString(undefined, {
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 0,
                            })}` +
                            ` ($${usdformatter.format(tvl)
                              })`
                          )}
                        </StatValue>
                      </StatContainer>
                      <StatContainer>
                            <StatTitle>Distributed (24h)</StatTitle>
                            <StatValue>
                              <StatBooIcon>
                                {boughtBackBoo !== null && priceBooPrecise !== null && !Number.isNaN(boughtBackBoo * priceBooPrecise) && <Img
                                  src={`https://assets.spooky.fi/tokens/${BOO_TOKEN_NAME}.png`}
                                  alt={`${BOO_TOKEN_NAME} Token`}
                                  height="20px"
                                  width="20px"
                                />}
                              </StatBooIcon>
                              {boughtBackBoo !== null && priceBooPrecise !== null && !Number.isNaN(boughtBackBoo * priceBooPrecise) ? (
                                boughtBackBoo?.toLocaleString(undefined, {
                                  minimumFractionDigits: 1,
                                  maximumFractionDigits: 1,
                                }) +
                                ` ($${usdformatter.format(boughtBackBoo * priceBooPrecise)})`
                              ) : (
                                <StyledLoadingButtonSpinner />
                              )}
                            </StatValue>
                          </StatContainer>
                          <StatContainer>
                            <StatTitle>Distributed (7d)</StatTitle>
                            <StatValue>
                              <StatBooIcon>
                                {boughtBackBoo7d !== null && priceBooPrecise !== null && !Number.isNaN(boughtBackBoo7d * priceBooPrecise) && <Img
                                  src={`https://assets.spooky.fi/tokens/${BOO_TOKEN_NAME}.png`}
                                  alt={`${BOO_TOKEN_NAME} Token`}
                                  height="20px"
                                  width="20px"
                                />}
                              </StatBooIcon>
                              {boughtBackBoo7d !== null && priceBooPrecise !== null && !Number.isNaN(boughtBackBoo7d * priceBooPrecise) ? (
                                boughtBackBoo7d?.toLocaleString(undefined, {
                                  minimumFractionDigits: 1,
                                  maximumFractionDigits: 1,
                                }) +
                                ` ($${usdformatter.format(boughtBackBoo7d * priceBooPrecise)})`
                              ) : (
                                <StyledLoadingButtonSpinner />
                              )}
                            </StatValue>
                          </StatContainer>
                      <StatContainer>
                        <StatTitle>APR (24h avg.)</StatTitle>
                        <StatValue>
                          {tradeFeeAPR === Infinity ||
                          tradeFeeAPR === null ||
                          tradeFeeAPR === 0 ||
                          tradeFeeAPR === undefined ||
                          isNaN(tradeFeeAPR) ? (
                            <StyledLoadingButtonSpinner />
                          ) : (
                            `${tradeFeeAPR.toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}%`
                          )}
                        </StatValue>
                      </StatContainer>
                      <StatContainer>
                        <StatTitle>APR (7d avg.)</StatTitle>
                        <StatValue>
                          {tradeFeeAPR7d === Infinity ||
                          tradeFeeAPR7d === null ||
                          tradeFeeAPR7d === 0 ||
                          tradeFeeAPR7d === undefined ||
                          isNaN(tradeFeeAPR7d) ? (
                            <StyledLoadingButtonSpinner />
                          ) : (
                            `${tradeFeeAPR7d.toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}%`
                          )}
                        </StatValue>
                      </StatContainer>
                    </StatWrapper>
                  </Upper>
                  <Separator />
                  {account && (
                    <ButtonContainer>
                      <>
                        <ButtonStakeContainer>
                          <ButtonStake onClick={handleStake} disabled={pendingTx}>
                            <Trans>Stake</Trans>
                          </ButtonStake>

                          {claimableBoo != 0 && (<ButtonStake onClick={handleUnstake} disabled={pendingTx}>
                            <Trans>Unstake</Trans>
                          </ButtonStake>)}
                        </ButtonStakeContainer>
                      </>
                      <ButtonDisplay variant="text" onClick={registerXBOO}>
                        Display {`x${BOO_TOKEN_NAME}`} in Wallet
                      </ButtonDisplay>
                    </ButtonContainer>
                  )}
                </AutoRow>
              </LeftSide>
              <RightSide>
                <Img src="https://assets.spooky.fi/stakesteak.png" alt="New Info" width="240px" />
                <InfoText>
                  Stake {`${BOO_TOKEN_NAME}`} here and receive {`x${BOO_TOKEN_NAME}`} as receipt representing your share of the pool. This pool
                  automatically compounds and earns 15% of all V2 trade fees to buy back {`${BOO_TOKEN_NAME}`}, which means the
                  {` x${BOO_TOKEN_NAME}`} to {`${BOO_TOKEN_NAME}`} ratio will grow over time!
                  <br />
                  <br />
                  Like liquidity providing (LP), you will earn fees according to your share in the pool, and your {`x${BOO_TOKEN_NAME} `}
                  receipt is needed as proof when claiming the rewards.
                </InfoText>
              </RightSide>
            </BridgeWrapper>
          </AutoRow>
        </StakeContainer>
      </AutoColumn>
    </>
  )
}
