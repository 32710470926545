import { TradeType } from '@uniswap/sdk-core'

import { VoteOption } from '../governance/types'

export interface SerializableTransactionReceipt {
  to: string
  from: string
  contractAddress: string
  transactionIndex: number
  blockHash: string
  transactionHash: string
  blockNumber: number
  status?: number
}

/**
 * Be careful adding to this enum, always assign a unique value (typescript will not prevent duplicate values).
 * These values is persisted in state and if you change the value it will cause errors
 */
export enum TransactionType {
  APPROVAL = 0,
  SWAP,
  DEPOSIT_LIQUIDITY_STAKING,
  WITHDRAW_LIQUIDITY_STAKING,
  CLAIM,
  VOTE,
  DELEGATE,
  WRAP,
  CREATE_V3_POOL,
  ADD_LIQUIDITY_V3_POOL,
  ADD_LIQUIDITY_V2_POOL,
  ADD_LIQUIDITY_AND_FARM_V2,
  CLAIM_ALL,
  MIGRATE_LIQUIDITY_V3,
  COLLECT_FEES,
  REMOVE_LIQUIDITY_V3,
  SUBMIT_PROPOSAL,
  QUEUE,
  EXECUTE,
  BUY,
  SEND,
  RECEIVE,
  MINT,
  BURN,
  BORROW,
  REPAY,
  DEPLOY,
  CANCEL,
  ADD_LIQUIDITY_V3_FARM,
  REMOVE_FARM_V3,
  CONVERT = 100,
  STAKE_BOO,
  UNSTAKE_BOO,
  STAKE_FARM_V3,
  UNSTAKE_FARM_V3,
  DEPOSIT_FARM_V3,
  WITHDRAW_FARM_V3,
  CREATE_FARM_V3,
  LAUNCH_TOKEN,
}

interface BaseTransactionInfo {
  type: TransactionType
}

export interface VoteTransactionInfo extends BaseTransactionInfo {
  type: TransactionType.VOTE
  governorAddress: string
  proposalId: number
  decision: VoteOption
  reason: string
}

export interface QueueTransactionInfo extends BaseTransactionInfo {
  type: TransactionType.QUEUE
  governorAddress: string
  proposalId: number
}

export interface ExecuteTransactionInfo extends BaseTransactionInfo {
  type: TransactionType.EXECUTE
  governorAddress: string
  proposalId: number
}

export interface DelegateTransactionInfo extends BaseTransactionInfo {
  type: TransactionType.DELEGATE
  delegatee: string
}

export interface ApproveTransactionInfo extends BaseTransactionInfo {
  type: TransactionType.APPROVAL
  tokenAddress: string
  spender: string
  amount: string
}

interface BaseSwapTransactionInfo extends BaseTransactionInfo {
  type: TransactionType.SWAP
  tradeType: TradeType
  inputCurrencyId: string
  outputCurrencyId: string
  isUniswapXOrder: boolean
  additionalInfo?: string
}

export interface BaseConversionTransactionInfo extends BaseTransactionInfo {
  type: TransactionType.CONVERT
  inputCurrencyId: string
  outputCurrencyId: string
  inputAmountRaw: string
  outputAmountRaw: string
}

export interface BaseStakeBooTransactionInfo extends BaseTransactionInfo {
  type: TransactionType.STAKE_BOO
  summary: string
}

export interface BaseUnstakeBooTransactionInfo extends BaseTransactionInfo {
  type: TransactionType.UNSTAKE_BOO
  summary: string
}

export interface LaunchTokenTransactionInfo extends BaseTransactionInfo {
  type: TransactionType.LAUNCH_TOKEN
  summary: string
}

export interface ExactInputSwapTransactionInfo extends BaseSwapTransactionInfo {
  tradeType: TradeType.EXACT_INPUT
  inputCurrencyAmountRaw: string
  expectedOutputCurrencyAmountRaw: string
  minimumOutputCurrencyAmountRaw: string
  settledOutputCurrencyAmountRaw?: string
}
export interface ExactOutputSwapTransactionInfo extends BaseSwapTransactionInfo {
  tradeType: TradeType.EXACT_OUTPUT
  outputCurrencyAmountRaw: string
  expectedInputCurrencyAmountRaw: string
  maximumInputCurrencyAmountRaw: string
}

interface DepositLiquidityStakingTransactionInfo {
  type: TransactionType.DEPOSIT_LIQUIDITY_STAKING
  token0Address: string
  token1Address: string
}

interface WithdrawLiquidityStakingTransactionInfo {
  type: TransactionType.WITHDRAW_LIQUIDITY_STAKING
  token0Address: string
  token1Address: string
}

export interface WrapTransactionInfo {
  type: TransactionType.WRAP
  unwrapped: boolean
  currencyAmountRaw: string
  chainId?: number
}

export interface ClaimTransactionInfo {
  type: TransactionType.CLAIM
  recipient: string
  farmAmountRaw?: string
}

export interface ClaimAllTransactionInfo {
  type: TransactionType.CLAIM_ALL
  recipient: string
  farmAmountRaw?: string
}

export interface CreateV3PoolTransactionInfo {
  type: TransactionType.CREATE_V3_POOL
  baseCurrencyId: string
  quoteCurrencyId: string
}

export interface AddLiquidityV3PoolTransactionInfo {
  type: TransactionType.ADD_LIQUIDITY_V3_POOL
  createPool: boolean
  baseCurrencyId: string
  quoteCurrencyId: string
  feeAmount: number
  expectedAmountBaseRaw: string
  expectedAmountQuoteRaw: string
}

export interface AddLiquidityV3FarmTransactionInfo {
  type: TransactionType.ADD_LIQUIDITY_V3_FARM
  baseCurrencyId: string
  quoteCurrencyId: string
  expectedTokenId: string
}

export interface RemoveFarmV3TransactionInfo {
  type: TransactionType.REMOVE_FARM_V3
  baseCurrencyId: string
  quoteCurrencyId: string
  expectedTokenId: string
}

export interface AddLiquidityV2PoolTransactionInfo {
  type: TransactionType.ADD_LIQUIDITY_V2_POOL
  baseCurrencyId: string
  quoteCurrencyId: string
  expectedAmountBaseRaw: string
  expectedAmountQuoteRaw: string
}

export interface AddLiquidityV2AndFarmTransactionInfo {
  type: TransactionType.ADD_LIQUIDITY_AND_FARM_V2
  baseCurrencyId: string
  quoteCurrencyId: string
  expectedAmountBaseRaw: string
  expectedAmountQuoteRaw: string
}

export interface MigrateV2LiquidityToV3TransactionInfo {
  type: TransactionType.MIGRATE_LIQUIDITY_V3
  baseCurrencyId: string
  quoteCurrencyId: string
  isFork: boolean
}

export interface CollectFeesTransactionInfo {
  type: TransactionType.COLLECT_FEES
  currencyId0: string
  currencyId1: string
  expectedCurrencyOwed0: string
  expectedCurrencyOwed1: string
}

export interface RemoveLiquidityV3TransactionInfo {
  type: TransactionType.REMOVE_LIQUIDITY_V3
  baseCurrencyId: string
  quoteCurrencyId: string
  expectedAmountBaseRaw: string
  expectedAmountQuoteRaw: string
}

interface SubmitProposalTransactionInfo {
  type: TransactionType.SUBMIT_PROPOSAL
}

interface DepositFarmV3TransactionInfo {
  type: TransactionType.DEPOSIT_FARM_V3
  baseCurrencyId: string
  quoteCurrencyId: string
  expectedTokenId: string
}

interface WithdrawFarmV3TransactionInfo {
  type: TransactionType.WITHDRAW_FARM_V3
  baseCurrencyId: string
  quoteCurrencyId: string
  expectedTokenId: string
}

interface UnstakeFarmV3TransactionInfo {
  type: TransactionType.UNSTAKE_FARM_V3
  baseCurrencyId: string
  quoteCurrencyId: string
  expectedTokenId: string
}

interface StakeFarmV3TransactionInfo {
  type: TransactionType.STAKE_FARM_V3
  baseCurrencyId: string
  quoteCurrencyId: string
  expectedTokenId: string
}

interface CreateFarmV3TransactionInfo {
  type: TransactionType.CREATE_FARM_V3
  baseCurrencyId: string
  quoteCurrencyId: string
}

export type TransactionInfo =
  | ApproveTransactionInfo
  | ExactOutputSwapTransactionInfo
  | ExactInputSwapTransactionInfo
  | ClaimTransactionInfo
  | ClaimAllTransactionInfo
  | VoteTransactionInfo
  | QueueTransactionInfo
  | ExecuteTransactionInfo
  | DelegateTransactionInfo
  | DepositLiquidityStakingTransactionInfo
  | WithdrawLiquidityStakingTransactionInfo
  | WrapTransactionInfo
  | CreateV3PoolTransactionInfo
  | AddLiquidityV3PoolTransactionInfo
  | AddLiquidityV3FarmTransactionInfo
  | RemoveFarmV3TransactionInfo
  | AddLiquidityV2PoolTransactionInfo
  | AddLiquidityV2AndFarmTransactionInfo
  | MigrateV2LiquidityToV3TransactionInfo
  | CollectFeesTransactionInfo
  | RemoveLiquidityV3TransactionInfo
  | SubmitProposalTransactionInfo
  | BaseConversionTransactionInfo
  | BaseStakeBooTransactionInfo
  | BaseUnstakeBooTransactionInfo
  | StakeFarmV3TransactionInfo
  | UnstakeFarmV3TransactionInfo
  | DepositFarmV3TransactionInfo
  | WithdrawFarmV3TransactionInfo
  | CreateFarmV3TransactionInfo
  | LaunchTokenTransactionInfo
export interface TransactionDetails {
  hash: string
  receipt?: SerializableTransactionReceipt
  lastCheckedBlockNumber?: number
  addedTime: number
  confirmedTime?: number
  deadline?: number
  from: string
  info: TransactionInfo
  nonce?: number
  cancelled?: boolean
}
