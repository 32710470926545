import { TransactionRequest, Web3Provider } from '@ethersproject/providers'
import { useWeb3React } from '@web3-react/core'
import { gaslessWhenPossibleAtom } from 'components/AccountDrawer/useGaslessToggle'
import { BEST_SWAP_NAME } from 'constants/misc'
import { useAtomValue } from 'jotai/utils'
import { useCallback } from 'react'
import { IHasTradeProperties, TradeFillType } from 'state/routing/types'
import { isMagpieQuote } from 'state/routing/utils'
import { calculateGasMargin } from 'utils/calculateGasMargin'
import { UserRejectedRequestError, WrongChainError } from 'utils/errors'
import { executeMagpieSwap, getMagpieTransaction } from 'utils/magpieApi'
import { didUserReject, swapErrorToUserReadableMessage } from 'utils/swapErrorToUserReadableMessage'

import { ModifiedSwapError } from './useUniversalRouter'
import { parseUnits } from 'ethers/lib/utils'

// eslint-disable-next-line import/no-unused-modules
export function useMagpieCallback(
  trade: IHasTradeProperties | null | undefined,
  recipientAddressOrName: string | null
) {
  const { account, chainId, provider } = useWeb3React()
  const gaslessWhenPossible = useAtomValue(gaslessWhenPossibleAtom)

  const IS_GASLESS_ENABLED = JSON.parse(process.env.REACT_APP_IS_MAGPIE_GASLESS_ENABLED ?? 'false')

  return useCallback(async () => {
    try {
      if (!account) throw new Error('missing account')
      if (!chainId) throw new Error('missing chainId')
      if (!provider) throw new Error('missing provider')
      if (!trade || !trade.quote) throw new Error('missing quote')
      if (!isMagpieQuote(trade.quote)) throw new Error('trade is not magpie rate')
      const connectedChainId = await provider.getSigner().getChainId()
      if (chainId !== connectedChainId) throw new WrongChainError()

      let tx: TransactionRequest

      try {
        if (!trade.quote) {
          throw new Error('Could not resolve paraswap ')
        }

        if (IS_GASLESS_ENABLED && gaslessWhenPossible && trade.inputAmount.currency.isToken) {
          const signer = provider.getSigner()
          const { domain, types, message } = trade.quote.typedData
          const signedData = await signer._signTypedData(domain, types, message)
          await executeMagpieSwap({
            chainId,
            quoteId: trade.quote.id,
            swapSignature: signedData,
          })

          return {
            type: TradeFillType.Classic as const,
            response: {
              hash: '',
              nonce: 0,
            },
            isGasless: true,
          }
        }

        tx = await getMagpieTransaction(trade.quote.id, false)
      } catch (e) {
        console.error(e)
        throw new Error(
          `For rebase or taxed tokens, try client side routing in settings instead of ${BEST_SWAP_NAME}. Ensure that you are using the correct slippage.`
        )
      }

      const txWithGasEstimate = await getTransctionOrFallback(provider, tx)
      const response = await provider
        .getSigner()
        .sendTransaction(txWithGasEstimate)
        .then((response) => {
          if (tx.data !== response.data) {
            if (!response.data || response.data.length === 0 || response.data === '0x') {
              throw new ModifiedSwapError()
            }
          }
          return response
        })
      return {
        type: TradeFillType.Classic as const,
        response,
      }
    } catch (swapError: unknown) {
      if (swapError instanceof ModifiedSwapError) throw swapError

      // GasEstimationErrors are already traced when they are thrown.
      // if (!(swapError instanceof GasEstimationError)) setTraceError(swapError)

      // Cancellations are not failures, and must be accounted for as 'cancelled'.
      if (didUserReject(swapError)) {
        // This error type allows us to distinguish between user rejections and other errors later too.
        throw new UserRejectedRequestError(swapErrorToUserReadableMessage(swapError))
      }

      throw new Error(swapErrorToUserReadableMessage(swapError))
    }
  }, [IS_GASLESS_ENABLED, account, chainId, gaslessWhenPossible, provider, trade])
}

// Magpie sometimes fails when using legacy transactions
// but some version of iphones fail when getting new transactions
// due to metamask not respecting the transaction type and injecting
// a gas price.  As a work around we will try to get the legacy transaction
// and if that fails we will fallback to use new transactions
// this will minimize error in both cases
async function getTransctionOrFallback(provider: Web3Provider, tx: TransactionRequest) {
  try {

    const { type, maxFeePerGas, maxPriorityFeePerGas, ...legacyStyleTx } = tx

    const estimate = await provider.getSigner().estimateGas(legacyStyleTx)
    return {
      ...legacyStyleTx,
      gasLimit: calculateGasMargin(estimate),
    }
  } catch (ex) {
    const feeData = await provider.getFeeData()

    const HARD_CAP = parseUnits("5", "gwei");

    let maxPriorityFeePerGas = feeData.maxPriorityFeePerGas

    // Enforce cap
    if (maxPriorityFeePerGas && maxPriorityFeePerGas.gt(HARD_CAP)) {
      maxPriorityFeePerGas = HARD_CAP;
    }

    const txWithPriorityFee = {
      ...tx,
      ...(feeData.maxFeePerGas ? { maxFeePerGas: feeData.maxFeePerGas } : {}),
      // TODO: we may wanna cap this and not rely on the provider
      ...(maxPriorityFeePerGas ? { maxPriorityFeePerGas } : {}),
      type: 2,
    }

    // Fetch fee data

    const estimate = await provider.getSigner().estimateGas(txWithPriorityFee)

    // strip the gas price incase the original tx included it
    const { gasPrice, ...txWithoutGasPrice } = txWithPriorityFee

    return {
      ...txWithoutGasPrice,
      gasLimit: calculateGasMargin(estimate),
    }
  }
}