/* eslint-disable import/no-unused-modules */
import { tokenPriceCacheAtom } from 'components/PriceFetchers/CoinGeckoPriceFetcher'
import { logger } from 'ethers'
import { useAtom } from 'jotai'
import { useCallback, useMemo } from 'react'
import { useAppDispatch, useAppSelector } from 'state/hooks'

import { AppState } from '../reducer'
import { cacheRPCTokenPrices, cacheV2GraphQLTokenPrices, cacheV3GraphQLTokenPrices, PriceMap } from './actions'

export function useCacheV3State(): AppState['cache'] {
  return useAppSelector((state) => state.cache)
}

export function useCachedRPCTokenPrices(): PriceMap {
  const { rpcTokenAddressToPriceMap } = useCacheV3State()
  return rpcTokenAddressToPriceMap
}

export function useCachedV2GraphQLTokenPrices(): PriceMap {
  const { v2GraphQLTokenAddressToPriceMap } = useCacheV3State()
  return v2GraphQLTokenAddressToPriceMap
}

export function useUSDPrices(): PriceMap {
  //   const { v2GraphQLTokenAddressToPriceMap, rpcTokenAddressToPriceMap } = useCacheV3State()
  //   const [tokenPriceCache] = useAtom(tokenPriceCacheAtom)
  //
  //   return useMemo(() => {
  //     const pricesWithFallback: PriceMap = {}
  //
  //     // Iterate over the tokens in rpcTokenAddressToPriceMap and apply the fallback logic
  //     for (const tokenAddressCheckSum in rpcTokenAddressToPriceMap) {
  //       const tokenAddress = tokenAddressCheckSum.toLowerCase()
  //       // 1. Check CoinGecko prices first
  //       if (tokenPriceCache[tokenAddress]?.price && Number.isFinite(tokenPriceCache[tokenAddress].price)) {
  //         pricesWithFallback[tokenAddress] = tokenPriceCache[tokenAddress].price
  //       }
  //       // 2. If no CoinGecko price, check GraphQL prices
  //       else if (
  //         v2GraphQLTokenAddressToPriceMap[tokenAddress] &&
  //         Number.isFinite(v2GraphQLTokenAddressToPriceMap[tokenAddress])
  //       ) {
  //         pricesWithFallback[tokenAddress] = v2GraphQLTokenAddressToPriceMap[tokenAddress]
  //       }
  //     }
  //
  //     return pricesWithFallback
  //   }, [tokenPriceCache, v2GraphQLTokenAddressToPriceMap, rpcTokenAddressToPriceMap])
  return useUSDPricesWithFallback()
}

export function useUSDPricesWithFallback(): PriceMap {
  const { v2GraphQLTokenAddressToPriceMap, v3GraphQLTokenAddressToPriceMap, rpcTokenAddressToPriceMap } =
    useCacheV3State()
  const [tokenPriceCache] = useAtom(tokenPriceCacheAtom)

  return useMemo(() => {
    const pricesWithFallback: PriceMap = {}

    // Iterate over the tokens in rpcTokenAddressToPriceMap and apply the fallback logic
    for (const tokenAddressCheckSum in rpcTokenAddressToPriceMap) {
      const tokenAddress = tokenAddressCheckSum.toLowerCase()

      // 1. Check CoinGecko prices first
      if (tokenPriceCache[tokenAddress]?.price && Number.isFinite(tokenPriceCache[tokenAddress].price)) {
        pricesWithFallback[tokenAddress] = tokenPriceCache[tokenAddress].price
        logger.debug(`Token ${tokenAddress} price from CoinGecko: ${pricesWithFallback[tokenAddress]}`)
      } else if (
        //2. Check GraphQL V3 prices
        v3GraphQLTokenAddressToPriceMap[tokenAddress] &&
        Number.isFinite(v3GraphQLTokenAddressToPriceMap[tokenAddress])
      ) {
        pricesWithFallback[tokenAddress] = v3GraphQLTokenAddressToPriceMap[tokenAddress]
        logger.debug(`Token ${tokenAddress} price from GraphQL v3: ${pricesWithFallback[tokenAddress]}`)
      }
      // 3. If no V3 price, check GraphQL V2 prices
      else if (
        v2GraphQLTokenAddressToPriceMap[tokenAddress] &&
        Number.isFinite(v2GraphQLTokenAddressToPriceMap[tokenAddress])
      ) {
        pricesWithFallback[tokenAddress] = v2GraphQLTokenAddressToPriceMap[tokenAddress]
        logger.debug(`Token ${tokenAddress} price from GraphQL: ${pricesWithFallback[tokenAddress]}`)
      }
      // 4. If no CoinGecko or GraphQL price, use RPC prices as the final fallback
      else if (Number.isFinite(rpcTokenAddressToPriceMap[tokenAddress])) {
        pricesWithFallback[tokenAddress] = rpcTokenAddressToPriceMap[tokenAddress]
        logger.debug(`Token ${tokenAddress} price from RPC: ${pricesWithFallback[tokenAddress]}`)
      } else {
        logger.debug(`No valid price found for token ${tokenAddress}`)
      }
    }

    return pricesWithFallback
  }, [tokenPriceCache, v2GraphQLTokenAddressToPriceMap, rpcTokenAddressToPriceMap])
}

// export function useUSDPricesWithFallback(): PriceMap {
//   const { v2GraphQLTokenAddressToPriceMap, rpcTokenAddressToPriceMap } = useCacheV3State()
//   const [tokenPriceCache] = useAtom(tokenPriceCacheAtom)
//   return useMemo(() => {
//     const pricesWithFallback = { ...v2GraphQLTokenAddressToPriceMap }
//     for (const key in rpcTokenAddressToPriceMap) {
//       //TODO: fix the RPC map so it does not return inifite values
//       if (!v2GraphQLTokenAddressToPriceMap[key] && Number.isFinite(rpcTokenAddressToPriceMap[key])) {
//         pricesWithFallback[key] = rpcTokenAddressToPriceMap[key]
//       }
//     }
//     return pricesWithFallback
//   }, [v2GraphQLTokenAddressToPriceMap, rpcTokenAddressToPriceMap])
// }

//TODO: invalidate cache on chain switch
export function useCacheActionHandlers(): {
  onRPCTokenPricesChange: (priceData: PriceMap) => void
  onV2GraphQLTokenPricesChange: (priceData: PriceMap) => void
  onV3GraphQLTokenPricesChange: (priceData: PriceMap) => void
} {
  const dispatch = useAppDispatch()

  const onRPCTokenPricesChange = useCallback(
    (priceData: PriceMap) => {
      dispatch(cacheRPCTokenPrices(priceData))
    },
    [dispatch]
  )

  const onV2GraphQLTokenPricesChange = useCallback(
    (priceData: PriceMap) => {
      dispatch(cacheV2GraphQLTokenPrices(priceData))
    },
    [dispatch]
  )

  const onV3GraphQLTokenPricesChange = useCallback(
    (priceData: PriceMap) => {
      dispatch(cacheV3GraphQLTokenPrices(priceData))
    },
    [dispatch]
  )

  return {
    onRPCTokenPricesChange,
    onV2GraphQLTokenPricesChange,
    onV3GraphQLTokenPricesChange,
  }
}
