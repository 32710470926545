import { Trans } from '@lingui/macro'
import { ChainId } from '@uniswap/sdk-core'
import { useWeb3React } from '@web3-react/core'
import { PoolsIcon } from 'components/Icons/Pools'
import { YourPoolsIcon } from 'components/Icons/YourPools'
import { DEFAULT_CHAIN_ID } from 'constants/misc'
import { useOnClickOutside } from 'hooks/useOnClickOutside'
import { Box } from 'nft/components/Box'
import { Column, Row } from 'nft/components/Flex'
import { body } from 'nft/css/common.css'
import { ReactNode, useReducer, useRef } from 'react'
import { Link, Plus } from 'react-feather'
import { NavLink, NavLinkProps, useLocation } from 'react-router-dom'
import styled from 'styled-components'

import * as styles from './MenuDropdown.css'
import { NavDropdown } from './NavDropdown'
import { activeMenuLiquidity, menuItem } from './style.css'

const PrimaryMenuRow = ({
  to,
  href,
  close,
  children,
}: {
  to?: NavLinkProps['to']
  href?: string
  close?: () => void
  children: ReactNode
}) => {
  return (
    <>
      {to ? (
        <NavLink to={to} className={styles.MenuRow}>
          <Row onClick={close}>{children}</Row>
        </NavLink>
      ) : (
        <Row cursor="pointer" as="a" href={href} target="_blank" rel="noopener noreferrer" className={styles.MenuRow}>
          {children}
        </Row>
      )}
    </>
  )
}

const StyledBox = styled(Box)`
  align-items: center;
  display: flex;
  justify-content: center;
`
const PrimaryMenuRowText = ({ children }: { children: ReactNode }) => {
  return <StyledBox className={`${styles.PrimaryText} ${body}`}>{children}</StyledBox>
}

PrimaryMenuRow.Text = PrimaryMenuRowText

const Icon = ({ href, children }: { href?: string; children: ReactNode }) => {
  return (
    <>
      <Box
        as={href ? 'a' : 'div'}
        href={href ?? undefined}
        target={href ? '_blank' : undefined}
        rel={href ? 'noopener noreferrer' : undefined}
        display="flex"
        flexDirection="column"
        color="neutral1"
        background="none"
        border="none"
        justifyContent="center"
        textAlign="center"
        marginRight="12"
      >
        {children}
      </Box>
    </>
  )
}

const LiquidityDropdown = () => {
  const [isOpen, toggleOpen] = useReducer((s) => !s, false)
  const { chainId } = useWeb3React()
  const ref = useRef<HTMLDivElement>(null)
  const { pathname } = useLocation()
  const isActive = pathname.startsWith('/pool') || pathname.startsWith('/add')

  const chainIdOrDefault = chainId ?? DEFAULT_CHAIN_ID

  useOnClickOutside(ref, isOpen ? toggleOpen : undefined)

  const linkNewPosition =
    chainId === ChainId.FANTOM ? '/add/ETH/0x841FAD6EAe12c286d1Fd18d1d525DFfA75C7EFFE/3000' : '/add/ETH'

  return (
    <>
      <Box position="relative" ref={ref} marginRight="4" width="full">
        <Box
          className={isActive ? activeMenuLiquidity : menuItem}
          display={{ sm: 'flex', lg: 'flex', xxl: 'flex' }}
          width="full"
          color={isOpen ? 'neutral1' : 'white'}
          onClick={toggleOpen}
          cursor="pointer"
        >
          <PoolsIcon height="24px" width="24px" fill="rgb(115, 204, 231)" />
          Liquidity
        </Box>
        {isOpen && (
          <NavDropdown top={{ sm: 'unset', lg: '56' }} bottom={{ sm: '50', lg: 'unset' }} right="0">
            <Column gap="16">
              <Column paddingX="8" gap="4">
                <>
                  <PrimaryMenuRow to="/pools" close={toggleOpen}>
                    <Icon>
                      <YourPoolsIcon width={24} height={24} fill="rgb(115, 204, 231)" />
                    </Icon>
                    <PrimaryMenuRow.Text>
                      <Trans>Your Liquidity</Trans>
                    </PrimaryMenuRow.Text>
                  </PrimaryMenuRow>
                  <PrimaryMenuRow to={linkNewPosition} close={toggleOpen}>
                    <Icon>
                      <Plus width={24} height={24} color="rgb(115, 204, 231)" />
                    </Icon>
                    <PrimaryMenuRow.Text>
                      <Trans>Add Liquidity</Trans>
                    </PrimaryMenuRow.Text>
                  </PrimaryMenuRow>
                  {chainIdOrDefault === ChainId.SONIC && (
                    <PrimaryMenuRow href="https://app.steer.finance/spookyswap" close={toggleOpen}>
                      <Icon>
                        <Link color="rgb(115, 204, 231)" />
                      </Icon>
                      <PrimaryMenuRow.Text>
                        <Trans>Steer ALM</Trans>
                      </PrimaryMenuRow.Text>
                    </PrimaryMenuRow>
                  )}
                </>
              </Column>
            </Column>
          </NavDropdown>
        )}
      </Box>
    </>
  )
}

export default LiquidityDropdown
