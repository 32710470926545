import styled from 'styled-components'
import { BREAKPOINTS } from 'theme'

export const ChartWrapper = styled.div`
  width: 95%;
  min-width: 300px;
  align-items: center;
  height: calc(100vh - 600px); // Tall chart taking most of viewport height
  // background: ${({ theme }) => theme.surface2};
  border-radius: 12px;
  padding: 16px;
  margin-bottom: 24px;

  @media (max-width: 768px) {
    width: 100%;
    margin-left: 0;
    height: 400px;
  }
  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    // flex-direction: column;
    // justify-content: center;
    display: none;
  }
`

export const LeftPanel = styled.div`
  width: 100%;
  height: 100%;
  padding: 16px;
  background: ${({ theme }) => theme.surface1};
  border: 1px solid ${({ theme }) => theme.surface3};
  border-radius: 16px;
  overflow-y: auto;

  @media (max-width: 768px) {
    position: relative;
    top: 0;
    width: 100%;
    height: auto;
    border-right: none;
    margin-bottom: 16px;
  }
`

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 40px;
  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    flex-direction: column;
    // justify-content: center;
    // min-width: 100%;
    // margin: 0;
  }
`

export const Column = styled.div`
  width: 100%;
  height: 100%;
  margin-top: 20px;
  background: ${({ theme }) => theme.surface1};
  border: 1px solid ${({ theme }) => theme.surface3};
  border-radius: 16px;

  padding: 16px;
  // border-radius: 12px;
  // background: ${({ theme }) => theme.surface2};
  // margin-bottom: 16px;

  h2 {
    margin: 0 0 16px;
    font-size: 24px;
  }
`

export const TokenInfoSection = styled.div`
  width: 100%;
  height: 100%;
  margin-bottom: 20px;
  background: ${({ theme }) => theme.surface1};
  border: 1px solid ${({ theme }) => theme.surface3};
  border-radius: 16px;

  padding: 16px;
  // border-radius: 12px;
  // background: ${({ theme }) => theme.surface2};
  // margin-bottom: 16px;

  h2 {
    margin: 0 0 16px;
    font-size: 24px;
  }
`

export const TokenInfoHeader = styled.div`
  padding: 16px;
  // border-radius: 12px;
  // background: ${({ theme }) => theme.surface1};
  // margin-bottom: 16px;

  h2 {
    margin: 0 0 16px;
    font-size: 24px;
  }
`

export const TokenMetrics = styled.div`
  display: grid;
  gap: 12px;

  div {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  label {
    color: ${({ theme }) => theme.white};
  }

  span {
    font-weight: 500;
  }
`

export const SocialLinks = styled.div`
  display: flex;
  gap: 12px;
  // margin-bottom: 24px;

  a {
    padding: 8px 12px;
    border-radius: 8px;
    background: ${({ theme }) => theme.surface3};
    color: ${({ theme }) => theme.text1};
    text-decoration: none;
    font-size: 14px;

    &:hover {
      background: ${({ theme }) => theme.surface4};
    }
  }
`

export const Section = styled.div`
  padding: 16px;
  background: ${({ theme }) => theme.surface2};
  border-radius: 12px;
  margin-bottom: 16px;
`

export const PriceHeader = styled.div`
  display: flex;
  align-items: baseline;
  gap: 8px;
  margin-bottom: 16px;

  h2 {
    margin: 0;
    font-size: 24px;
  }

  .price {
    font-size: 20px;
    font-weight: 500;
  }

  .change {
    font-size: 14px;
    color: ${({ theme, positive }: { theme: any; positive?: boolean }) => (positive ? theme.success : theme.error)};
  }
`

export const LinkIcon = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 8px;
  vertical-align: middle;
`
