import { ChainId, Currency, CurrencyAmount, Percent, Price, TradeType } from '@uniswap/sdk-core'
import { useWeb3React } from '@web3-react/core'
import { gaslessWhenPossibleAtom } from 'components/AccountDrawer/useGaslessToggle'
import { useAtomValue } from 'jotai/utils'
import JSBI from 'jsbi'
import React from 'react'
import { useQuery } from 'react-query'
import { IHasQuoteProperties, MagpieQuoteResponse, QuoteMethod, TradeFillType } from 'state/routing/types'
import { computeFiatValuePriceImpact } from 'utils/computeFiatValuePriceImpact'
import { getMagpieQuote, MAGPIE_CHAINID_TO_NETWORK, MAGPIE_NATIVE_ADDRESS } from 'utils/magpieApi'

import { calculateFiatValue, QuoterProps, Quoters } from './QuoteManager'

const MAGPIE_FEE = process.env.REACT_APP_MAGPIE_FEE_AMOUNT_PREFIX_ZERO ?? '0.001'
const MAGPIE_FEE_ADDRESS = process.env.REACT_APP_MAGPIE_FEE_ADDRESS ?? '0xBc05E9E160675DE53914F81Fe5589Ae8376e0B88'
const IS_GASLESS_ENABLED = JSON.parse(process.env.REACT_APP_IS_MAGPIE_GASLESS_ENABLED ?? 'false')

// eslint-disable-next-line import/no-unused-modules
export async function resolveParamsAndQuote(
  chainId: ChainId,
  fromCurrency: Currency,
  toCurrency: Currency,
  sellAmountBI: JSBI,
  slippagePercent: Percent,
  userGasless: boolean,
  fromAddress?: string,
  toAddress?: string
): Promise<MagpieQuoteResponse> {
  const network = MAGPIE_CHAINID_TO_NETWORK[chainId]!
  const fromTokenAddress = fromCurrency.isToken ? fromCurrency.address : MAGPIE_NATIVE_ADDRESS
  const toTokenAddress = toCurrency.isToken ? toCurrency.address : MAGPIE_NATIVE_ADDRESS
  // we need to divide by 100 because it should be formatted its returning format as percentage e.g .5% is really 0.005 not .5
  const slippage = Number(slippagePercent.divide(100).toFixed(4))
  const sellAmount = sellAmountBI.toString()

  const isGasless = IS_GASLESS_ENABLED && userGasless && fromCurrency.isToken

  return await getMagpieQuote({
    network,
    fromTokenAddress,
    toTokenAddress,
    slippage,
    sellAmount,
    gasless: isGasless, //fromCurrency.isToken,
    fromAddress,
    toAddress,
    affiliateAddress: MAGPIE_FEE_ADDRESS,
    affiliateFeeInPercentage: MAGPIE_FEE,
  })
}

// eslint-disable-next-line import/no-unused-modules
export const MagpieQuoter: React.FC<QuoterProps> = ({
  swapParams,
  onQuoteUpdate,
  inputFiatValuePerToken,
  outputFiatValuePerToken,
}) => {
  const { account } = useWeb3React()
  const gaslessWhenPossible = useAtomValue(gaslessWhenPossibleAtom)

  // Consolidated function to fetch and resolve the quote
  const fetchAndResolveQuote = async () => {
    try {
      const rawQuoteResponse = await resolveParamsAndQuote(
        swapParams.inputCurrency.chainId,
        swapParams.inputCurrency,
        swapParams.outputCurrency,
        swapParams.amount,
        swapParams.allowedSlippage,
        gaslessWhenPossible,
        account,
        account
      )

      // Resolve the optimal rate into a quote
      const resolvedQuote = resolveQuoteFromRawResponse(
        rawQuoteResponse,
        swapParams.inputCurrency,
        swapParams.amount,
        swapParams.outputCurrency,
        inputFiatValuePerToken,
        outputFiatValuePerToken
      )
      const isQuoteValid = resolvedQuote.outputAmount && resolvedQuote.outputAmount.greaterThan(0)

      // Prepare the final quote object
      const quote: IHasQuoteProperties = {
        ...resolvedQuote,
        isValid: isQuoteValid,
      }

      return quote
    } catch (err) {
      console.error('Failed to fetch Paraswap rate', err)
      return null
    }
  }

  // Resolving the fetched rate into the IHasQuoteProperties format
  const resolveQuoteFromRawResponse = (
    rawQuoteResponse: MagpieQuoteResponse,
    inputCurrency: Currency,
    inputAmount: JSBI,
    outputCurrency: Currency,
    inputFiatValuePerToken?: number,
    outputFiatValuePerToken?: number
  ): IHasQuoteProperties => {
    const inputCurrencyAmount = CurrencyAmount.fromRawAmount(inputCurrency, inputAmount.toString())
    const outputCurrencyAmount = CurrencyAmount.fromRawAmount(outputCurrency, rawQuoteResponse.amountOut)

    const internalFiatInputValue = calculateFiatValue(inputFiatValuePerToken, inputCurrencyAmount)
    const fiatInputValue: number | undefined = internalFiatInputValue

    const internalFiatOutputValue = calculateFiatValue(outputFiatValuePerToken, outputCurrencyAmount)
    const fiatOutputValue: number | undefined = internalFiatOutputValue

    return {
      inputAmount: inputCurrencyAmount,
      outputAmount: outputCurrencyAmount,
      executionPrice: new Price(
        inputCurrencyAmount.currency,
        outputCurrencyAmount.currency,
        inputCurrencyAmount.quotient,
        outputCurrencyAmount.quotient
      ),
      quote: rawQuoteResponse,
      quoteMethod: QuoteMethod.MAGPIE,
      fillType: TradeFillType.Magpie,
      tradeType: TradeType.EXACT_INPUT,
      postTaxOutputAmount: outputCurrencyAmount,
      approveInfo: { needsApprove: false }, // Mocked for now, update if needed
      priceImpact: computeFiatValuePriceImpact(fiatInputValue, fiatOutputValue), // Use rate.destUSD directly for price impact
      fiatValueInput: { data: Number(fiatInputValue), isLoading: false },
      fiatValueOutput: { data: Number(fiatOutputValue), isLoading: false },
      quoter: Quoters.MAGPIE,
      dependencies: {},
      wrapInfo: { needsWrap: false },
    }
  }

  // useQuery hook to fetch and resolve quotes
  useQuery({
    queryKey: ['magpie-quote', swapParams],
    queryFn: fetchAndResolveQuote, // Directly call fetchAndResolveQuote
    enabled: true,
    refetchInterval: 5000, // Auto-refetch every 5 seconds
    onSuccess: (quote) => {
      if (quote) {
        onQuoteUpdate(Quoters.MAGPIE, quote)
      }
    },
  })

  return null // No rendering necessary
}
