import { Percent, TradeType } from '@uniswap/sdk-core'
import { useWeb3React } from '@web3-react/core'
import { PermitSignature } from 'hooks/usePermitAllowance'
import { useCallback } from 'react'
import { IHasTradeProperties, QuoteMethod } from 'state/routing/types'
import { isQuoteClassicTrade } from 'state/routing/utils'
import { rateMaximumAmountIn, rateMinimumAmountOut } from 'utils/calculateSlippageAmount'

import { useTransactionAdder } from '../state/transactions/hooks'
import {
  ExactInputSwapTransactionInfo,
  ExactOutputSwapTransactionInfo,
  TransactionType,
} from '../state/transactions/types'
import { currencyId } from '../utils/currencyId'
import { useLiquidityHubCallback } from './useLiquidityHubCallback'
import { useMagpieCallback } from './useMagpieCallback'
import { useParaswapCallback } from './useParaswapCallback'
import useTransactionDeadline from './useTransactionDeadline'
import { useUniversalRouterSwapCallback } from './useUniversalRouter'

export type SwapResult = Awaited<ReturnType<ReturnType<typeof useSwapCallback>>>

// Returns a function that will execute a swap, if the parameters are all valid
// and the user has approved the slippage adjusted input amount for the trade
export function useSwapCallback(
  trade: IHasTradeProperties | undefined, // trade to execute, required
  fiatValues: { amountIn?: number; amountOut?: number }, // usd values for amount in and out, logged for analytics
  allowedSlippage: Percent, // in bips
  permitSignature: PermitSignature | undefined,
  recipient: string | null
) {
  const deadline = useTransactionDeadline()

  const addTransaction = useTransactionAdder()
  const { account, chainId } = useWeb3React()

  const universalRouterSwapCallback = useUniversalRouterSwapCallback(
    trade && isQuoteClassicTrade(trade?.quote) ? trade.quote : undefined,
    fiatValues,
    {
      slippageTolerance: allowedSlippage,
      deadline,
      permit: permitSignature,
    }
  )

  const paraswapCallback = useParaswapCallback(trade, recipient)
  const magpieCallback = useMagpieCallback(trade, recipient)
  const liquidityHubCallback = useLiquidityHubCallback(trade, recipient)

  let swapCallback: any = universalRouterSwapCallback

  switch (trade?.quoteMethod) {
    case QuoteMethod.MAGPIE:
      swapCallback = magpieCallback
      break
    case QuoteMethod.LIQUIDITY_HUB:
      swapCallback = liquidityHubCallback
      break
    case QuoteMethod.BEST_TRADE_API:
      swapCallback = paraswapCallback
      break
  }

  return useCallback(async () => {
    if (!trade) throw new Error('missing trade')
    if (!account || !chainId) throw new Error('wallet must be connected to swap')

    const result = await swapCallback()

    const swapInfo: ExactInputSwapTransactionInfo | ExactOutputSwapTransactionInfo = {
      type: TransactionType.SWAP,
      inputCurrencyId: currencyId(trade.inputAmount.currency),
      outputCurrencyId: currencyId(trade.outputAmount.currency),
      isUniswapXOrder: false,
      ...(trade.tradeType === TradeType.EXACT_INPUT
        ? {
            tradeType: TradeType.EXACT_INPUT,
            inputCurrencyAmountRaw: trade.inputAmount.quotient.toString(),
            expectedOutputCurrencyAmountRaw: trade.postTaxOutputAmount.quotient.toString(),
            minimumOutputCurrencyAmountRaw: rateMinimumAmountOut(
              trade,
              allowedSlippage,
              trade.outputAmount
            ).quotient.toString(),
          }
        : {
            tradeType: TradeType.EXACT_OUTPUT,
            maximumInputCurrencyAmountRaw: rateMaximumAmountIn(
              trade,
              allowedSlippage,
              trade.inputAmount
            ).quotient.toString(),
            outputCurrencyAmountRaw: trade.postTaxOutputAmount.quotient.toString(),
            expectedInputCurrencyAmountRaw: trade.inputAmount.quotient.toString(),
          }),
    }

    if (result?.isGasless) {
      return result
    }

    addTransaction(result.response, swapInfo, deadline?.toNumber())

    return result
  }, [account, addTransaction, allowedSlippage, chainId, deadline, swapCallback, trade])
}
