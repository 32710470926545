import { useWeb3React } from '@web3-react/core'
import { BEST_SWAP_NAME } from 'constants/misc'
import { useCallback } from 'react'
import { IHasTradeProperties, TradeFillType } from 'state/routing/types'
import { isQuoteLiquidityHub } from 'state/routing/utils'
import { UserRejectedRequestError, WrongChainError } from 'utils/errors'
import { didUserReject, swapErrorToUserReadableMessage } from 'utils/swapErrorToUserReadableMessage'

import { getLiquidityHubSDK } from './useLiquidityHubSDK'
import { ModifiedSwapError } from './useUniversalRouter'

// eslint-disable-next-line import/no-unused-modules
export function useLiquidityHubCallback(
  trade: IHasTradeProperties | null | undefined,
  recipientAddressOrName: string | null
) {
  const { account, chainId, provider } = useWeb3React()

  return useCallback(async () => {
    try {
      if (!account) throw new Error('missing account')
      if (!chainId) throw new Error('missing chainId')
      if (!provider) throw new Error('missing provider')
      if (!trade || !trade.quote) throw new Error('missing quote')
      if (!isQuoteLiquidityHub(trade.quote)) throw new Error('trade is not liquidity hub')
      const connectedChainId = await provider.getSigner().getChainId()
      if (chainId !== connectedChainId) throw new WrongChainError()

      try {
        if (!trade.quote) {
          throw new Error('Could not resolve liquidityHub quote ')
        }

        const signer = provider.getSigner()
        const { domain, types, values } = trade.quote.permitData
        const signedData = await signer._signTypedData(domain, types, values)

        const liquidityHub = getLiquidityHubSDK(chainId)
        // Call Liquidity Hub sdk swap and wait for transaction hash
        const txHash = await liquidityHub.swap(trade.quote, signedData)

        if (!txHash) {
          throw new Error('Swap failed')
        }

        return {
          type: TradeFillType.Classic as const,
          response: {
            hash: txHash,
            nonce: 0,
          },
          isGasless: true,
        }
      } catch (e) {
        console.error(e)
        throw new Error(
          `For rebase or taxed tokens, try client side routing in settings instead of ${BEST_SWAP_NAME}. Ensure that you are using the correct slippage.`
        )
      }
    } catch (swapError: unknown) {
      if (swapError instanceof ModifiedSwapError) throw swapError

      // GasEstimationErrors are already traced when they are thrown.
      // if (!(swapError instanceof GasEstimationError)) setTraceError(swapError)

      // Cancellations are not failures, and must be accounted for as 'cancelled'.
      if (didUserReject(swapError)) {
        // This error type allows us to distinguish between user rejections and other errors later too.
        throw new UserRejectedRequestError(swapErrorToUserReadableMessage(swapError))
      }

      throw new Error(swapErrorToUserReadableMessage(swapError))
    }
  }, [account, chainId, provider, trade])
}
