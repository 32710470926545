import { gql } from '@apollo/client'
import dayjs from 'dayjs'
import { apolloClient } from 'graphql/thegraph/v2apollo'
import { useEffect, useMemo, useState } from 'react'

interface UniswapDayData {
  id: string
  date: string
  dailyVolumeUSD: string
}

const UNI_DAY_DATAS = gql`
  query uniswapDayDatas($startTime: Int!, $skip: Int!) {
    uniswapDayDatas(first: 1000, skip: $skip, where: { date_gt: $startTime }, orderBy: date, orderDirection: asc) {
      id
      date
      dailyVolumeUSD
    }
  }
`

const BUYBACK_TRADE_FEE = 0.0003
const FAST_INTERVAL = 10000 // 10 seconds
const SLOW_INTERVAL = 60000 // 1 minute

// Fetch 8 days of data FROM V2 !!!
export const get8DayData = async (): Promise<UniswapDayData[]> => {
  let data: UniswapDayData[] = []
  let skip = 0
  let allFound = false
  const eightDaysAgo = (Math.floor(dayjs().unix() / 86400) - 8) * 86400

  try {
    while (!allFound) {
      const result = await apolloClient.query({
        query: UNI_DAY_DATAS,
        variables: {
          startTime: eightDaysAgo,
          skip,
        },
        fetchPolicy: 'cache-first',
      })
      skip += 1000
      data = data.concat(result.data.uniswapDayDatas)
      if (result.data.uniswapDayDatas.length < 1000) {
        allFound = true
      }
    }
  } catch (e) {
    console.error(e)
  }

  return data
}

// Use CoinGecko Polyfill Hook
const cgBtcVolume = 'https://api.coingecko.com/api/v3/exchanges/spookyswap'
const cgBtcPrice = 'https://api.coingecko.com/api/v3/simple/price?ids=bitcoin&vs_currencies=usd'

function useGetCoingeckoPolyfill1DayAvgYearFee() {
  const [vol, setVol] = useState<number | null>(null)
  const [yearlyFees, setYearlyFees] = useState(0)

  useEffect(() => {
    async function fetchCoingeckoVolume() {
      let response
      let btcPriceResp
      try {
        response = await fetch(cgBtcVolume, {
          method: 'GET',
          cache: 'no-store',
          headers: {
            'Access-Control-Request-Method': 'GET',
            Origin: 'https://spooky.fi',
          },
        })
        btcPriceResp = await fetch(cgBtcPrice, {
          method: 'GET',
          cache: 'no-store',
          headers: {
            'Access-Control-Request-Method': 'GET',
            Origin: 'https://spooky.fi',
          },
        })
      } catch (error) {
        console.error('Failed to fetch coingecko boo volumes', cgBtcVolume, error)
        return
      }

      const btcVol = (await response.json()).trade_volume_24h_btc_normalized
      const btcPrice = (await btcPriceResp.json()).bitcoin.usd

      setVol(btcPrice * btcVol)
    }
    fetchCoingeckoVolume()
  }, [])

  useMemo(() => {
    const val = vol ? vol * BUYBACK_TRADE_FEE * 365 : 0
    setYearlyFees(val)
  }, [vol])

  return yearlyFees
}

function useGet7DayAvgYearFees() {
  const coingeckoYearlyFees = useGetCoingeckoPolyfill1DayAvgYearFee()

  const [vol, setVol] = useState<UniswapDayData[]>([])
  const [yearlyFees, setYearlyFees] = useState<number>(0)

  useEffect(() => {
    const fetchData = async () => {
      const uniData = await get8DayData()
      setVol(uniData)
    }
    fetchData()

    const fastInterval = setInterval(() => {
      fetchData()
    }, FAST_INTERVAL)

    const slowInterval = setInterval(() => {
      fetchData()
    }, SLOW_INTERVAL)

    return () => {
      clearInterval(fastInterval)
      clearInterval(slowInterval)
    }
  }, [])

  useMemo(() => {
    let temp = 0
    for (let index = 0; index < vol.length; index++) {
      temp += Number(vol[index].dailyVolumeUSD)
    }
    const val = vol.length ? (temp / vol.length) * BUYBACK_TRADE_FEE * 365 : 0
    setYearlyFees(val)
  }, [vol])

  // Return the Coingecko fees if yearlyFees unavailable
  return yearlyFees || coingeckoYearlyFees
}

export default useGet7DayAvgYearFees
