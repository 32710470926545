import { Contract } from '@ethersproject/contracts'
import { constructFetchFetcher, constructSimpleSDK } from '@paraswap/sdk'
// import { Currency, ETHER, Token } from '@uniswap/sdk'
import { Currency } from '@uniswap/sdk-core'
import { useWeb3React } from '@web3-react/core'
import { DEFAULT_CHAIN_ID, PARASWAP_SUPPORTED_CHAINS } from 'constants/misc'
import { useMemo } from 'react'

const PARASWAP_NATIVE_ADDRESS = '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE'
// eslint-disable-next-line import/no-unused-modules
export function getBestTradeCurrencyAddress(currency: Currency) {
  return currency.isNative ? PARASWAP_NATIVE_ADDRESS : currency.address
}

const fetcher = constructFetchFetcher(window.fetch)

// eslint-disable-next-line import/no-unused-modules
export function useParaswap() {
  const { account, chainId, provider } = useWeb3React()

  const chainIdOrDefault = chainId ?? DEFAULT_CHAIN_ID
  return useMemo(() => {
    if (!provider || !PARASWAP_SUPPORTED_CHAINS.includes(chainIdOrDefault)) {
      return
    }

    if (!account) {
      return constructSimpleSDK({ chainId: chainIdOrDefault, fetcher, version: '5' })
    }

    // with ethers.js
    const providerOptionsEther = {
      ethersProviderOrSigner: provider, // JsonRpcProvider
      EthersContract: Contract,
      account,
    }

    const paraswapSDK = constructSimpleSDK({ chainId: chainIdOrDefault, fetcher, version: '5' }, providerOptionsEther)

    return paraswapSDK
  }, [chainIdOrDefault, provider, account])
}
