import { CurrencyAmount, Token } from '@uniswap/sdk-core'
import { ButtonPrimary } from 'components/Button'
import Modal from 'components/Modal'
import { Contract } from 'ethers/lib/ethers'
import React, { useState } from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-flow: column;
  align-items: center;
  background: ${({ theme }) => theme.surface1};
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); // 50% black overlay
    z-index: -1;
  }
`

const Container = styled.div`
  width: 100%;
  max-width: 400px;
  padding: 32px 24px;
  display: flex;
  flex-flow: column;
  align-items: center;
  text-align: center;
`

const TitleRow = styled.div`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 16px;
`

const Input = styled.input`
  width: 100%;
  padding: 12px;
  margin-bottom: 16px;
  border-radius: 8px;
  background: ${({ theme }) => theme.surface2};
  border: 1px solid ${({ theme }) => theme.surface2};
  color: ${({ theme }) => theme.neutral3};
  text-align: right;

  &:focus {
    border: 1px solid ${({ theme }) => theme.neutral3}; // Change border color on focus
    outline: none; // Remove default outline
  }
`

const BalanceRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 14px;
  color: ${({ theme }) => theme.neutral3};
  margin-bottom: 16px;
`

const ButtonRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 16px;
`

const ButtonMax = styled.div`
  display: flex;
  background: none;
  color: ${({ theme }) => theme.accent1};
  cursor: pointer;
`

const ButtonStake = styled(ButtonPrimary)`
  border-radius: 24px;
  padding: 5px;
  margin: 5px;
  max-width: 70%;
`

interface DepositModalProps {
  isOpen: boolean
  max?: CurrencyAmount<Token>
  onConfirm: (amount: string) => Promise<any>
  onDismiss?: () => void
  tokenAddr?: string
  tokenName?: string
  isPermitApproval?: boolean
  addLiquidityUrl?: string
  setAttemptingTxn: (attempting: boolean) => void
  setShowConfirm: (show: boolean) => void
  title?: string
  stakingTokenDecimals?: number
  isUnstake?: boolean
  lpContract?: Contract
}

const StakeModal: React.FC<DepositModalProps> = ({
  isOpen,
  setShowConfirm,
  setAttemptingTxn,
  max,
  tokenName,
  onConfirm,
  onDismiss,
  title = `Stake ${tokenName}`,
}) => {
  const [val, setVal] = useState('')

  const handleConfirm = async () => {
    setAttemptingTxn(true)
    await onConfirm(val)
    setShowConfirm(true)
    setAttemptingTxn(false)
    onDismiss?.()
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setVal(e.target.value)
  }

  const handleSelectMax = () => {
    if (max) {
      setVal(max.toExact())
    }
  }

  if (!isOpen) return null

  const content = (
    <Wrapper>
      <Container>
        <TitleRow>{title}</TitleRow>
        <Input type="text" value={val} onChange={handleChange} placeholder="Enter amount to stake" />
        <BalanceRow>
          {tokenName} Balance: {Number(max?.toExact()).toFixed(2)}
          <ButtonMax onClick={handleSelectMax} style={{ marginLeft: '8px' }}>
            Max
          </ButtonMax>
        </BalanceRow>
        <ButtonRow>
          <ButtonStake onClick={onDismiss}>Cancel</ButtonStake>
          <ButtonStake onClick={handleConfirm} disabled={!Number(val) || Number(val) > Number(max?.toExact())}>
            Confirm
          </ButtonStake>
        </ButtonRow>
      </Container>
    </Wrapper>
  )

  return (
    <Modal isOpen={isOpen} onDismiss={onDismiss} height={undefined}>
      {content}
    </Modal>
  )
}

export default StakeModal
