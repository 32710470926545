import { useWeb3React } from '@web3-react/core'
import { DEFAULT_CHAIN_ID } from 'constants/misc'
import { numberToWei } from 'nft/utils'
import { useCallback } from 'react'
import { useTransactionAdder } from 'state/transactions/hooks'
import { TransactionType } from 'state/transactions/types'
import { BooLandStakeV2 } from 'utils/callhelpers'
import { formatBalance } from 'utils/formatBalance'

import { useXbooContract } from './useContract'
import { getPermitSignature } from './usePermitAllowance'

export const useBooLandStakeV2 = (account: string, Boo: any) => {
  const addTransaction = useTransactionAdder()
  const BooLandContract = useXbooContract()
  const { chainId, provider } = useWeb3React()
  const chainIdOrDefault = chainId ?? DEFAULT_CHAIN_ID

  const handleStake = useCallback(
    async (amount: string) => {
      const permitDeadline = Math.floor(Date.now() / 1000) + 180 // Set deadline to 3 minutes from now
      if (!BooLandContract) {
        return
      }
      // Generate the permit signature
      const sig = await getPermitSignature(
        account,
        Boo,
        BooLandContract.address,
        numberToWei(Number(amount)),
        permitDeadline,
        account,
        chainIdOrDefault,
        provider
      )

      try {
        // Perform the staking transaction with the permit signature
        const txHash = BooLandStakeV2(BooLandContract, amount, sig, permitDeadline)

        txHash.then((response: any) => {
          const description = `Stake ${formatBalance(Number(amount))} BOO to Mirror World`

          addTransaction(response, {
            type: TransactionType.STAKE_BOO,
            summary: description,
          })
        })
      } catch (error) {
        console.error('Error during staking:', error)
        // Handle error appropriately
      }
    },
    [BooLandContract, account, Boo, chainIdOrDefault, provider, addTransaction]
  )

  return { onStake: handleStake }
}
