import { t, Trans } from '@lingui/macro'
import { BrowserEvent, InterfaceElementName, SwapEventName } from '@uniswap/analytics-events'
import { Currency, Percent } from '@uniswap/sdk-core'
import { TraceEvent } from 'analytics'
import Column, { AutoColumn } from 'components/Column'
import Img from 'components/Img'
import CurrencyLogo from 'components/Logo/CurrencyLogo'
import { MouseoverTooltip } from 'components/Tooltip'
import { SITE_NAME, ZERO_PERCENT } from 'constants/misc'
import { SwapResult } from 'hooks/useSwapCallback'
import { ReactNode } from 'react'
import { AlertTriangle } from 'react-feather'
import { IHasTradeProperties } from 'state/routing/types'
import styled, { DefaultTheme, useTheme } from 'styled-components'
import { ThemedText } from 'theme'
import { formatPriceImpact } from 'utils/formatNumbers'
import { getPriceImpactColor } from 'utils/prices'

import { ButtonError } from '../../components/Button'
import Row, { AutoRow, RowBetween } from '../../components/Row'
import { Label } from '../../components/swap//SwapModalHeaderAmount'
import { SwapCallbackError } from '../../components/swap/styled'

enum ConfirmModalState {
  REVIEWING,
  PERMITTING,
  PENDING_CONFIRMATION,
}

const DetailsContainer = styled(Column)`
  padding: 0 8px;
  background: ${({ theme }) => theme.surface1};
`

const StyledAlertTriangle = styled(AlertTriangle)`
  margin-right: 8px;
  min-width: 24px;
`

const ConfirmButton = styled(ButtonError)`
  height: 56px;
  margin-top: 10px;
`

const RowTop = styled(Row)`
  border-top: 1px solid ${({ theme }) => theme.surface3};
  padding-top: 20px;
`

export const AdvancedHeader = styled.div`
  display: flex;
  font-size: 16px;
  align-items: center;
  justify-content: center;
  text-align: center;
`

const SwapAmount = styled.div`
  display: flex;
  font-size: 34px;
  font-weight: bold;
  // align-items: center;
  // justify-content: center;
  // text-align: center;
`

const DetailRowValue = styled(ThemedText.BodySmall)<{ warningColor?: keyof DefaultTheme }>`
  text-align: right;
  overflow-wrap: break-word;
  ${({ warningColor, theme }) => warningColor && `color: ${theme[warningColor]};`};
`

export default function SwapModalFooter({
  trade,
  allowedSlippage,
  swapResult,
  onConfirm,
  swapErrorMessage,
  disabledConfirm,
  inputCurrency,
  outputCurrency,
  minSlippageAmount,
  expectedOutput,
  exchangeRate,
  typedValue,
  quotePrice,
  text,
  fiatValueInput,
  fiatValueOutput,
  showAcceptChanges,
  onAcceptChanges,
}: {
  trade?: IHasTradeProperties
  swapResult?: SwapResult
  allowedSlippage: Percent
  onConfirm?: () => void
  swapErrorMessage?: ReactNode
  disabledConfirm?: boolean
  inputCurrency?: Currency
  outputCurrency?: Currency
  minSlippageAmount?: string
  expectedOutput?: string | number
  exchangeRate?: string
  typedValue?: string
  quotePrice?: number
  text?: any
  fiatValueInput?: { data?: number; isLoading: boolean }
  fiatValueOutput?: { data?: number; isLoading: boolean }
  showAcceptChanges?: boolean
  onAcceptChanges?: () => void
}) {
  const theme = useTheme()
  return (
    <AutoColumn style={{ width: '100%', padding: '20px 10px', background: theme.surface1 }}>
      {text === 'Transaction successful!' && (
        <>
          <AutoColumn>
            <AdvancedHeader>Swap Success</AdvancedHeader>

            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Img src="https://assets.spooky.fi/happy_grim.gif" width={227} />
              <div style={{ color: theme.success }}>Swap successful!</div>
            </div>
          </AutoColumn>
        </>
      )}
      {(text === 'Error occurred during transaction.' || text === 'Transaction failed') && (
        <>
          <AutoColumn>
            <AdvancedHeader>Swap Cancelled</AdvancedHeader>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Img src="https://assets.spooky.fi/icons/404.png" width={227} />
              <div style={{ color: theme.critical }}>Error occured during transaction.</div>
            </div>
          </AutoColumn>
        </>
      )}
      {text === 'Sending transaction...' && (
        <>
          <AutoColumn>
            <AdvancedHeader>Check Wallet...</AdvancedHeader>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Img src="https://assets.spooky.fi/grimSpinner2.gif" width={227} />
              <div style={{ color: theme.neutral3 }}>Proceed to your wallet</div>
            </div>
          </AutoColumn>
        </>
      )}
      {!text && (
        <>
          <DetailsContainer gap="md">
            <AutoColumn>
              <AdvancedHeader>Review Swap</AdvancedHeader>
              <AutoColumn>
                <Label>You Pay</Label>
                <RowBetween>
                  <SwapAmount>
                    {typedValue} {inputCurrency?.symbol}
                  </SwapAmount>
                  <CurrencyLogo currency={inputCurrency} size="36px"></CurrencyLogo>
                </RowBetween>
              </AutoColumn>
              <AutoColumn>
                <Label>You Receive</Label>
                <RowBetween>
                  <SwapAmount>
                    {quotePrice && quotePrice.toFixed(2)} {outputCurrency?.symbol}
                  </SwapAmount>
                  <CurrencyLogo currency={outputCurrency} size="36px"></CurrencyLogo>
                </RowBetween>
              </AutoColumn>
            </AutoColumn>
            <AutoColumn gap="md" style={{ padding: '0px 5px' }}>
              <ThemedText.BodySmall>
                <RowTop align="flex-start" justify="space-between" gap="sm">
                  <Label>
                    <Trans>Exchange rate</Trans>
                  </Label>
                  <DetailRowValue>{`1 ${inputCurrency?.symbol} = ${exchangeRate ?? '-'} ${
                    outputCurrency?.symbol
                  }`}</DetailRowValue>
                </RowTop>
              </ThemedText.BodySmall>

              {/* <TokenTaxLineItem trade={trade} type="input" />
        <TokenTaxLineItem trade={trade} type="output" /> */}
              <ThemedText.BodySmall>
                <Row align="flex-start" justify="space-between" gap="sm">
                  <MouseoverTooltip
                    text={
                      <Trans>
                        The minimum amount you are guaranteed to receive. If the price slips any further, your
                        transaction will revert.
                      </Trans>
                    }
                  >
                    <Label cursor="help">
                      <Trans>Minimum output</Trans>
                    </Label>
                  </MouseoverTooltip>
                  {/* <DetailRowValue warningColor={getPriceImpactColor(trade.priceImpact)}>
              {trade.priceImpact ? formatPriceImpact(trade.priceImpact) : '-'}
            </DetailRowValue> */}
                  {minSlippageAmount}
                </Row>
              </ThemedText.BodySmall>
              <ThemedText.BodySmall>
                <Row align="flex-start" justify="space-between" gap="sm">
                  <MouseoverTooltip
                    text={
                      <Trans>
                        The maximum amount you are guaranteed to spend. If the price slips any further, your transaction
                        will revert.
                      </Trans>
                    }
                  >
                    <Label cursor="help">
                      <Trans>Expected output</Trans>
                    </Label>
                  </MouseoverTooltip>
                  {/* <DetailRowValue warningColor={getPriceImpactColor(trade.priceImpact)}>
              {trade.priceImpact ? formatPriceImpact(trade.priceImpact) : '-'}
            </DetailRowValue> */}
                  {expectedOutput}
                </Row>
              </ThemedText.BodySmall>
              <ThemedText.BodySmall>
                <Row align="flex-start" justify="space-between" gap="sm">
                  {/* <MouseoverTooltip
              text={
                trade.tradeType === TradeType.EXACT_INPUT ? (
                  <Trans>
                    The minimum amount you are guaranteed to receive. If the price slips any further, your transaction
                    will revert.
                  </Trans>
                ) : (
                  <Trans>
                    The maximum amount you are guaranteed to spend. If the price slips any further, your transaction
                    will revert.
                  </Trans>
                )
              }
            >
              <Label cursor="help">
                {trade.tradeType === TradeType.EXACT_INPUT ? (
                  <Trans>Minimum received</Trans>
                ) : (
                  <Trans>Maximum sent</Trans>
                )}
              </Label> */}
                  {/* </MouseoverTooltip>
            <DetailRowValue>
              {trade.tradeType === TradeType.EXACT_INPUT
                ? `${rateMinimumAmountOut(trade, allowedSlippage, trade.outputAmount).toSignificant(6)} ${
                    trade.outputAmount.currency.symbol
                  }`
                : `${rateMaximumAmountIn(trade, allowedSlippage, trade.inputAmount).toSignificant(6)} ${
                    trade.inputAmount.currency.symbol
                  }`}
            </DetailRowValue> */}
                </Row>
              </ThemedText.BodySmall>
            </AutoColumn>
          </DetailsContainer>
          <AutoRow style={{ background: theme.surface1 }}>
            <TraceEvent
              events={[BrowserEvent.onClick]}
              element={InterfaceElementName.CONFIRM_SWAP_BUTTON}
              name={SwapEventName.SWAP_SUBMITTED_BUTTON_CLICKED}
              // properties={formatSwapButtonClickEventProperties({
              //   trade,
              //   swapResult,
              //   allowedSlippage,
              //   transactionDeadlineSecondsSinceEpoch,
              //   isAutoSlippage,
              //   isAutoRouterApi: routerPreference === RouterPreference.API,
              //   routes,
              //   fiatValueInput: fiatValueInput.data,
              //   fiatValueOutput: fiatValueOutput.data,
              // })}
            >
              <ConfirmButton
                data-testid="confirm-swap-button"
                onClick={onConfirm}
                disabled={disabledConfirm}
                $borderRadius="12px"
                id={InterfaceElementName.CONFIRM_SWAP_BUTTON}
              >
                <ThemedText.HeadlineSmall color="deprecated_accentTextLightPrimary">
                  <Trans>Confirm swap</Trans>
                </ThemedText.HeadlineSmall>
              </ConfirmButton>
            </TraceEvent>

            {swapErrorMessage ? <SwapCallbackError error={swapErrorMessage} /> : null}
          </AutoRow>
        </>
      )}
    </AutoColumn>
  )
}

function TokenTaxLineItem({ trade, type }: { trade: IHasTradeProperties; type: 'input' | 'output' }) {
  const [currency, percentage] =
    type === 'input' ? [trade.inputAmount.currency, trade.inputTax] : [trade.outputAmount.currency, trade.outputTax]

  if (percentage.equalTo(ZERO_PERCENT)) return null

  return (
    <ThemedText.BodySmall>
      <Row align="flex-start" justify="space-between" gap="sm">
        <MouseoverTooltip
          text={
            <>
              <Trans>
                Some tokens take a fee when they are bought or sold, which is set by the token issuer. {SITE_NAME} does
                not receive any of these fees.
              </Trans>{' '}
            </>
          }
        >
          <Label cursor="help">{t`${currency.symbol} fee`}</Label>
        </MouseoverTooltip>
        <DetailRowValue warningColor={getPriceImpactColor(percentage)}>{formatPriceImpact(percentage)}</DetailRowValue>
      </Row>
    </ThemedText.BodySmall>
  )
}
