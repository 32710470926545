import { Trans } from '@lingui/macro'
import { BrowserEvent, InterfaceElementName, InterfaceEventName, InterfaceSectionName } from '@uniswap/analytics-events'
import { ChainId, Currency, CurrencyAmount } from '@uniswap/sdk-core'
import { useWeb3React } from '@web3-react/core'
import { Trace, TraceEvent, useTrace } from 'analytics'
import { useToggleAccountDrawer } from 'components/AccountDrawer'
import { ButtonLight, ButtonPrimary } from 'components/Button'
import { LightCard } from 'components/Card'
import { AutoColumn } from 'components/Column'
import ConvertHeader from 'components/Conversion/ConvertHeader'
import SwapCurrencyInputPanel, { Ribbon } from 'components/CurrencyInputPanel/SwapCurrencyInputPanel'
import CurrencyLogo from 'components/Logo/CurrencyLogo'
import { NetworkAlert } from 'components/NetworkAlert/NetworkAlert'
import { RowFlat } from 'components/Row'
import { ErrorModalContent, PendingModalError } from 'components/swap/PendingModalContent/ErrorModalContent'
import { PageWrapper, SwapWrapper } from 'components/swap/styled'
import TransactionConfirmationModal, { ConfirmationModalContent } from 'components/TransactionConfirmationModal'
import { BOO_ADDRESS, BOO_NEW_ADDRESS, BOO_OLD_ADDRESS } from 'constants/addresses'
import { getChainInfo } from 'constants/chainInfo'
import { asSupportedChain } from 'constants/chains'
import { DEFAULT_CHAIN_ID } from 'constants/misc'
import { ethers } from 'ethers'
import { parseEther } from 'ethers/lib/utils'
import { ApprovalState, useApproveCallback } from 'hooks/useApproveCallback'
import { useFantomAdapterContract, useTokenContract, useTokenConvertContract } from 'hooks/useContract'
import { useLocalCurrencyPrice } from 'hooks/useLocalCurrencyPrice'
import usePrevious from 'hooks/usePrevious'
import { useSwitchChain } from 'hooks/useSwitchChain'
import { useSingleCallResult } from 'lib/hooks/multicall'
import { Dots } from 'pages/Pool/styled'
import { ButtonSwapContainer, SwapSection } from 'pages/Swap'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Text } from 'rebass'
import { useAppSelector } from 'state/hooks'
import { Field } from 'state/swap/actions'
import { useDerivedConvertInfo } from 'state/swap/hooks'
import { initialState as initialSwapState, SwapState } from 'state/swap/reducer'
import styled from 'styled-components'
import { useIsDarkMode } from 'theme/components/ThemeToggle'
import { maybeLogFirstSwapAction } from 'tracing/swapFlowLoggers'
import { getChainFromUrl } from 'utils/dynamicSwapRoute'
import { maxAmountSpend } from 'utils/maxAmountSpend'
import { didUserReject } from 'utils/swapErrorToUserReadableMessage'

const OutputSwapSection = styled(SwapSection)`
  border-bottom: ${({ theme }) => `1px solid ${theme.surface1}`};
`

const DEFAULT_SWAP_RATE = 1000

enum ConversionState {
  NO_CONVERSION,
  CONVERTING,
  CONVERTED,
  ERROR = 3,
}

export default function ConvertPage({ className }: { className?: string }) {
  const { chainId: connectedChainId } = useWeb3React()
  const location = useLocation()

  const tokenConvertContract = useTokenConvertContract()
  const swapRate = 1
  const inputToken = "0x841fad6eae12c286d1fd18d1d525dffa75c7effe"
  const outputToken = "0x841fad6eae12c286d1fd18d1d525dffa75c7effe"

  if (!inputToken || !outputToken) {
    return null
  }

  const offChain = getChainFromUrl(location)
  const supportedChainId = asSupportedChain(connectedChainId)

  return (
    connectedChainId == 250 ? (<PageWrapper>
      <Convert
        className={className}
        chainId={supportedChainId ?? offChain}
        prefilledState={{
          [Field.INPUT]: { currencyId: inputToken },
          [Field.OUTPUT]: { currencyId: outputToken },
        }}
        swapRate={swapRate}
      />
      <NetworkAlert />
    </PageWrapper>) : <h1>Connect to Fantom Opera to migrate BOO to Sonic</h1>
  )
}

function Convert({
  className,
  prefilledState = {},
  chainId,
  swapRate,
}: {
  className?: string
  prefilledState?: Partial<SwapState>
  chainId?: ChainId
  swapRate?: number
}) {
  const { account, chainId: connectedChainId, connector } = useWeb3React()
  const trace = useTrace()

  const chainIdOrDefault = connectedChainId ?? DEFAULT_CHAIN_ID

  const [conversionState, setConversionState] = useState<ConversionState>(ConversionState.NO_CONVERSION)
  const [inputCurrencyId, setInputCurrencyId] = useState<string | null | undefined>(prefilledState.INPUT?.currencyId)
  const [outputCurrencyId, setOutputCurrencyId] = useState<string | null | undefined>(prefilledState.OUTPUT?.currencyId)

  const [showConfirm, setShowConfirm] = useState<boolean>(false)
  const [attemptingTxn, setAttemptingTxn] = useState<boolean>(false) // clicked confirm
  const [txHash, setTxHash] = useState<string>('')

  const tokenConvertContract = useTokenContract(BOO_ADDRESS[chainIdOrDefault])
  const adapterContract = useFantomAdapterContract()
  // input token & output token
  const [inputAmount, setInputAmount] = useState('')
  const convertState = useMemo(
    () => ({
      typedValue: inputAmount,
      [Field.INPUT]: { currencyId: inputCurrencyId },
      [Field.OUTPUT]: { currencyId: outputCurrencyId },
    }),
    [inputAmount, inputCurrencyId, outputCurrencyId]
  )

  // SWITCH CHAIN
  const switchChain = useSwitchChain()
  const switchingChain = useAppSelector((state) => state.wallets.switchingChain)
  const isDark = useIsDarkMode()

  // toggle wallet when disconnected
  const toggleWalletDrawer = useToggleAccountDrawer()

  // RESET STATE ON CHAIN CHANGE
  const previousConnectedChainId = usePrevious(connectedChainId)
  const previousPrefilledState = usePrevious(prefilledState)

  useEffect(() => {
    const combinedInitialState = { ...initialSwapState, ...prefilledState }
    const chainChanged = previousConnectedChainId && previousConnectedChainId !== connectedChainId
    const prefilledInputChanged =
      previousPrefilledState &&
      previousPrefilledState?.[Field.INPUT]?.currencyId !== prefilledState?.[Field.INPUT]?.currencyId
    const prefilledOutputChanged =
      previousPrefilledState &&
      previousPrefilledState?.[Field.OUTPUT]?.currencyId !== prefilledState?.[Field.OUTPUT]?.currencyId
    if (chainChanged || prefilledInputChanged || prefilledOutputChanged) {
      setInputCurrencyId(combinedInitialState.INPUT.currencyId)
      setOutputCurrencyId(combinedInitialState.OUTPUT.currencyId)
      setInputAmount('')
      setConversionState(ConversionState.NO_CONVERSION)
    }
  }, [connectedChainId, prefilledState, previousConnectedChainId, previousPrefilledState])

  // CONVERT HANDLING
  const convertInfo = useDerivedConvertInfo(convertState, chainId)
  const { currencyBalances, parsedAmount, currencies } = convertInfo
  // const { execute: onConvert, inputError: convertInputError } = useConvertCallback(
  //   currencies[Field.INPUT],
  //   currencies[Field.OUTPUT],
  //   inputAmount
  // )

  // APPROVAL HANDLING
  const [approvalA, approveACallback] = useApproveCallback(
    parsedAmount ? parsedAmount : undefined,
    "0x3AF1CF07F9960e06B76D701c0CD7F1120707378A"
  )
  const showApprovalA = approvalA !== ApprovalState.APPROVED && !!parsedAmount
  const fiatValueInput = useLocalCurrencyPrice(parsedAmount, currencies[Field.INPUT] ?? undefined)
  const fiatValueOutput = useLocalCurrencyPrice(parsedAmount, currencies[Field.OUTPUT] ?? undefined)

  const handleTypeInput = useCallback((value: string) => {
    setInputAmount(value)
    setConversionState(ConversionState.NO_CONVERSION)
  }, [])

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const handleTypeOutput = useCallback((value: string) => {}, [])

  const formattedAmounts = useMemo(
    () => ({
      [Field.INPUT]: inputAmount ?? '',
      [Field.OUTPUT]: parsedAmount?.multiply(swapRate ?? DEFAULT_SWAP_RATE).toExact() ?? '',
    }),
    [inputAmount, parsedAmount, swapRate]
  )

  const maxInputAmount: CurrencyAmount<Currency> | undefined = useMemo(
    () => maxAmountSpend(currencyBalances[Field.INPUT]),
    [currencyBalances]
  )
  const showMaxButton = false//Boolean(maxInputAmount?.greaterThan(0) && !parsedAmount?.equalTo(maxInputAmount))

  const OFT_CONTRACT_ADDRESS = '0x7A0C53F7eb34C5BC8B01691723669adA9D6CB384'

  const handleOnConvert = useCallback(async () => {
    if (!account || !chainId || !inputAmount || !tokenConvertContract || !adapterContract) return

    setAttemptingTxn(true)
    try {
      setConversionState(ConversionState.CONVERTING)

      // Approve
      // const approveTx = await tokenConvertContract.approve(OFT_CONTRACT_ADDRESS, parseEther(inputAmount)) // Approve the OFT contract to spend BOO
      // await approveTx.wait()

      const accountBytesLike = "0x000000000000000000000000".concat(account.substring(2)) // Step 2: Quote the fee for the transaction
      const sendParam = {
        dstEid: 30332,
        to: accountBytesLike,
        amountLD: parseEther(inputAmount),
        minAmountLD: parseEther(inputAmount),
        extraOptions: '0x',
        composeMsg: '0x',
        oftCmd: '0x',
      }

      const [nativeFee, lzTokenFee] = await adapterContract.quoteSend(sendParam, false)
      // Log fee for debugging
      const fee = {
        nativeFee,
        lzTokenFee,
      }

      const sendTx = await adapterContract.send(sendParam, fee, account, { value: nativeFee })

      await sendTx.wait()

      setTxHash(sendTx.hash) // Set the transaction hash
      setConversionState(ConversionState.CONVERTED)
    } catch (error) {
      console.error('Error during conversion:', error)
      setConversionState(ConversionState.ERROR)
    } finally {
      setAttemptingTxn(false)
    }
  }, [account, chainId, inputAmount, tokenConvertContract, adapterContract])
  // const handleOnConvert = useCallback(async () => {
  //   if (!onConvert || !chainId || !account) return
  //   setAttemptingTxn(true)

  //   try {
  //     setConversionState(ConversionState.CONVERTING)
  //     setShowConfirm(true)

  //     const txHash = await onConvert()
  //     if (!txHash) {
  //       throw Error('Tx failed')
  //     }
  //     //TX Hash received here
  //     setTxHash(txHash)
  //     setAttemptingTxn(false)
  //     setInputAmount('')
  //   } catch (error) {
  //     setAttemptingTxn(false)
  //     setConversionState(ConversionState.ERROR)
  //   }
  // }, [onConvert, chainId, account])

  const handleMaxInput = useCallback(() => {
    maxInputAmount && setInputAmount(maxInputAmount.toExact())
    maybeLogFirstSwapAction(trace)
  }, [maxInputAmount, trace])

  const handleDismissConfirmation = useCallback(() => {
    setShowConfirm(false)
    setConversionState(ConversionState.NO_CONVERSION)
    setTxHash('')
  }, [])

  const modalHeader = () => {
    return (
      <AutoColumn gap="20px">
        <RowFlat style={{ display: 'flex', alignContent: 'center', justifyContent: 'center', marginTop: '10px' }}>
          <CurrencyLogo currency={currencies.INPUT} size="32" />
        </RowFlat>
        <LightCard mt="0px" $borderRadius="20px">
          <RowFlat>
            <Text fontSize="18px" fontWeight={400} justifyContent="center" alignContent="center" textAlign="center">
              This action will irreversibly migrate your BOO tokens from Fantom to Sonic. LayerZero charges a 0.1 FTM bridge fee, while SpookySwap takes no fees.
            </Text>
          </RowFlat>
        </LightCard>
      </AutoColumn>
    )
  }

  const pendingText = 'pending'

  const convertElement = (
    <SwapWrapper isDark={isDark} className={className} id="swap-page">
      <TransactionConfirmationModal
        isOpen={showConfirm}
        onDismiss={handleDismissConfirmation}
        attemptingTxn={attemptingTxn}
        hash={txHash}
        reviewContent={() =>
          conversionState === ConversionState.ERROR ? (
            <ErrorModalContent errorType={PendingModalError.CONVERT_ERROR} onRetry={handleOnConvert} />
          ) : (
            <ConfirmationModalContent
              title={<Trans>Migrating BOO to Sonic</Trans>}
              onDismiss={handleDismissConfirmation}
              topContent={modalHeader}
              bottomContent={() => (
                <>
                  {/* {convertInputError ? (
                    <ButtonError style={{ marginTop: '1rem' }} error={true} disabled>
                      <Text fontWeight={535} fontSize={20}>
                        {convertInputError}
                      </Text>
                    </ButtonError>
                  ) : ( */}
                  <ButtonPrimary style={{ marginTop: '1rem' }} onClick={handleOnConvert}>
                    <Text fontWeight={535} fontSize={20}>
                      <Trans>Confirm</Trans>
                    </Text>{' '}
                  </ButtonPrimary>
                  {/* )} */}
                </>
              )}
            />
          )
        }
        pendingText={pendingText}
      />
      <ConvertHeader chainId={chainId} />

      <div style={{ display: 'relative', paddingBottom: '5px' }}>
        <SwapSection>
          <Trace name="conversions-page">
            <SwapCurrencyInputPanel
              label={<Trans>You pay</Trans>}
              value={formattedAmounts[Field.INPUT]}
              showMaxButton={showMaxButton}
              currency={currencies[Field.INPUT] ?? null}
              onUserInput={handleTypeInput}
              onMax={handleMaxInput}
              fiatValue={fiatValueInput ?? undefined}
              showCommonBases
              id={InterfaceSectionName.CURRENCY_INPUT_PANEL}
              loading={false}
            />
          </Trace>
        </SwapSection>
      </div>
      <AutoColumn gap="xs">
        <div>
          <OutputSwapSection>
            <Trace section={InterfaceSectionName.CURRENCY_OUTPUT_PANEL}>
              <Ribbon className="right" fontSize="5px" bgColor="#FFD700" textColor="#000">
                NEW!
              </Ribbon>
              <SwapCurrencyInputPanel
                value={formattedAmounts[Field.OUTPUT]}
                onUserInput={handleTypeOutput}
                disabled={true}
                label={<Trans>You receive</Trans>}
                showMaxButton={false}
                hideBalance={false}
                fiatValue={fiatValueOutput ?? undefined}
                currency={currencies[Field.OUTPUT] ?? null}
                showCommonBases
                id={InterfaceSectionName.CURRENCY_OUTPUT_PANEL}
                loading={false}
              />
            </Trace>
          </OutputSwapSection>
        </div>

        <ButtonSwapContainer>
          {!account ? (
            <TraceEvent
              events={[BrowserEvent.onClick]}
              name={InterfaceEventName.CONNECT_WALLET_BUTTON_CLICKED}
              element={InterfaceElementName.CONNECT_WALLET_BUTTON}
            >
              <ButtonLight onClick={toggleWalletDrawer} fontWeight={535} $borderRadius="16px">
                <Trans>Connect Wallet</Trans>
              </ButtonLight>
            </TraceEvent>
          ) : showApprovalA ? (
            <ButtonPrimary onClick={approveACallback} disabled={approvalA === ApprovalState.PENDING} width="100%">
              {approvalA === ApprovalState.PENDING ? (
                <Dots>
                  <Trans>Approving {parsedAmount?.currency?.symbol}</Trans>
                </Dots>
              ) : (
                <Trans>Approve {parsedAmount?.currency?.symbol}</Trans>
              )}
            </ButtonPrimary>
          ) : switchingChain ? (
            <ButtonPrimary $borderRadius="16px" disabled={true}>
              <Trans>Connecting to {getChainInfo(switchingChain)?.label}</Trans>
            </ButtonPrimary>
          ) : chainId && chainId !== connectedChainId ? (
            <ButtonPrimary
              $borderRadius="16px"
              onClick={async () => {
                try {
                  await switchChain(connector, chainId)
                } catch (error) {
                  if (didUserReject(error)) {
                    // Ignore error, which keeps the user on the previous chain.
                  } else {
                    // TODO(WEB-3306): This UX could be improved to show an error state.
                    throw error
                  }
                }
              }}
            >
              Connect to {getChainInfo(chainId)?.label}
            </ButtonPrimary>
          ) : (
            <ButtonPrimary
              $borderRadius="16px"
              onClick={() => {
                setShowConfirm(true)
              }}
              fontWeight={535}
              data-testid="wrap-button"
              disabled={inputAmount == ''}
            >
              <Trans>Migrate</Trans>
            </ButtonPrimary>
          )}
        </ButtonSwapContainer>
      </AutoColumn>
    </SwapWrapper>
  )

  return <>{convertElement}</>
}
