import { i18n } from '@lingui/core'
import { SwapOrderStatus, TransactionStatus } from 'graphql/data/__generated__/types-and-hooks'
import { UniswapXOrderStatus } from 'lib/hooks/orders/types'
import { TransactionType } from 'state/transactions/types'

// use even number because rows are in groups of 2
export const DEFAULT_NFT_QUERY_AMOUNT = 26

const TransactionTitleTable: { [key in TransactionType]: { [state in TransactionStatus]: string } } = {
  [TransactionType.SWAP]: {
    [TransactionStatus.Pending]: i18n.t('Swapping'),
    [TransactionStatus.Confirmed]: i18n.t('Swapped'),
    [TransactionStatus.Failed]: i18n.t('Swap failed'),
  },
  [TransactionType.WRAP]: {
    [TransactionStatus.Pending]: i18n.t('Wrapping'),
    [TransactionStatus.Confirmed]: i18n.t('Wrapped'),
    [TransactionStatus.Failed]: i18n.t('Wrap failed'),
  },
  [TransactionType.ADD_LIQUIDITY_V3_POOL]: {
    [TransactionStatus.Pending]: i18n.t('Adding liquidity'),
    [TransactionStatus.Confirmed]: i18n.t('Added liquidity'),
    [TransactionStatus.Failed]: i18n.t('Add liquidity failed'),
  },
  [TransactionType.REMOVE_LIQUIDITY_V3]: {
    [TransactionStatus.Pending]: i18n.t('Removing liquidity'),
    [TransactionStatus.Confirmed]: i18n.t('Removed liquidity'),
    [TransactionStatus.Failed]: i18n.t('Remove liquidity failed'),
  },
  [TransactionType.CREATE_V3_POOL]: {
    [TransactionStatus.Pending]: i18n.t('Creating pool'),
    [TransactionStatus.Confirmed]: i18n.t('Created pool'),
    [TransactionStatus.Failed]: i18n.t('Create pool failed'),
  },
  [TransactionType.COLLECT_FEES]: {
    [TransactionStatus.Pending]: i18n.t('Collecting fees'),
    [TransactionStatus.Confirmed]: i18n.t('Collected fees'),
    [TransactionStatus.Failed]: i18n.t('Collect fees failed'),
  },
  [TransactionType.APPROVAL]: {
    [TransactionStatus.Pending]: i18n.t('Approving'),
    [TransactionStatus.Confirmed]: i18n.t('Approved'),
    [TransactionStatus.Failed]: i18n.t('Approval failed'),
  },
  [TransactionType.CLAIM]: {
    [TransactionStatus.Pending]: i18n.t('Claiming'),
    [TransactionStatus.Confirmed]: i18n.t('Claimed'),
    [TransactionStatus.Failed]: i18n.t('Claim failed'),
  },
  [TransactionType.CLAIM_ALL]: {
    [TransactionStatus.Pending]: i18n.t('Claiming all'),
    [TransactionStatus.Confirmed]: i18n.t('Claimed all'),
    [TransactionStatus.Failed]: i18n.t('Claim all failed'),
  },
  [TransactionType.BUY]: {
    [TransactionStatus.Pending]: i18n.t('Buying'),
    [TransactionStatus.Confirmed]: i18n.t('Bought'),
    [TransactionStatus.Failed]: i18n.t('Buy failed'),
  },
  [TransactionType.SEND]: {
    [TransactionStatus.Pending]: i18n.t('Sending'),
    [TransactionStatus.Confirmed]: i18n.t('Sent'),
    [TransactionStatus.Failed]: i18n.t('Send failed'),
  },
  [TransactionType.RECEIVE]: {
    [TransactionStatus.Pending]: i18n.t('Receiving'),
    [TransactionStatus.Confirmed]: i18n.t('Received'),
    [TransactionStatus.Failed]: i18n.t('Receive failed'),
  },
  [TransactionType.MINT]: {
    [TransactionStatus.Pending]: i18n.t('Minting'),
    [TransactionStatus.Confirmed]: i18n.t('Minted'),
    [TransactionStatus.Failed]: i18n.t('Mint failed'),
  },
  [TransactionType.BURN]: {
    [TransactionStatus.Pending]: i18n.t('Burning'),
    [TransactionStatus.Confirmed]: i18n.t('Burned'),
    [TransactionStatus.Failed]: i18n.t('Burn failed'),
  },
  [TransactionType.VOTE]: {
    [TransactionStatus.Pending]: i18n.t('Voting'),
    [TransactionStatus.Confirmed]: i18n.t('Voted'),
    [TransactionStatus.Failed]: i18n.t('Vote failed'),
  },
  [TransactionType.QUEUE]: {
    [TransactionStatus.Pending]: i18n.t('Queuing'),
    [TransactionStatus.Confirmed]: i18n.t('Queued'),
    [TransactionStatus.Failed]: i18n.t('Queue failed'),
  },
  [TransactionType.EXECUTE]: {
    [TransactionStatus.Pending]: i18n.t('Executing'),
    [TransactionStatus.Confirmed]: i18n.t('Executed'),
    [TransactionStatus.Failed]: i18n.t('Execute failed'),
  },
  [TransactionType.BORROW]: {
    [TransactionStatus.Pending]: i18n.t('Borrowing'),
    [TransactionStatus.Confirmed]: i18n.t('Borrowed'),
    [TransactionStatus.Failed]: i18n.t('Borrow failed'),
  },
  [TransactionType.REPAY]: {
    [TransactionStatus.Pending]: i18n.t('Repaying'),
    [TransactionStatus.Confirmed]: i18n.t('Repaid'),
    [TransactionStatus.Failed]: i18n.t('Repay failed'),
  },
  [TransactionType.DEPLOY]: {
    [TransactionStatus.Pending]: i18n.t('Deploying'),
    [TransactionStatus.Confirmed]: i18n.t('Deployed'),
    [TransactionStatus.Failed]: i18n.t('Deploy failed'),
  },
  [TransactionType.CANCEL]: {
    [TransactionStatus.Pending]: i18n.t('Cancelling'),
    [TransactionStatus.Confirmed]: i18n.t('Cancelled'),
    [TransactionStatus.Failed]: i18n.t('Cancel failed'),
  },
  [TransactionType.DELEGATE]: {
    [TransactionStatus.Pending]: i18n.t('Delegating'),
    [TransactionStatus.Confirmed]: i18n.t('Delegated'),
    [TransactionStatus.Failed]: i18n.t('Delegate failed'),
  },
  [TransactionType.DEPOSIT_LIQUIDITY_STAKING]: {
    [TransactionStatus.Pending]: i18n.t('Depositing'),
    [TransactionStatus.Confirmed]: i18n.t('Deposited'),
    [TransactionStatus.Failed]: i18n.t('Deposit failed'),
  },
  [TransactionType.WITHDRAW_LIQUIDITY_STAKING]: {
    [TransactionStatus.Pending]: i18n.t('Withdrawing'),
    [TransactionStatus.Confirmed]: i18n.t('Withdrew'),
    [TransactionStatus.Failed]: i18n.t('Withdraw failed'),
  },
  [TransactionType.ADD_LIQUIDITY_V2_POOL]: {
    [TransactionStatus.Pending]: i18n.t('Adding V2 liquidity'),
    [TransactionStatus.Confirmed]: i18n.t('Added V2 liquidity'),
    [TransactionStatus.Failed]: i18n.t('Add V2 liquidity failed'),
  },
  [TransactionType.ADD_LIQUIDITY_AND_FARM_V2]: {
    [TransactionStatus.Pending]: i18n.t('Adding V2 liquidity and Farm'),
    [TransactionStatus.Confirmed]: i18n.t('Added V2 liquidity and Farm'),
    [TransactionStatus.Failed]: i18n.t('Add V2 liquidity and Farm failed'),
  },
  [TransactionType.MIGRATE_LIQUIDITY_V3]: {
    [TransactionStatus.Pending]: i18n.t('Migrating liquidity'),
    [TransactionStatus.Confirmed]: i18n.t('Migrated liquidity'),
    [TransactionStatus.Failed]: i18n.t('Migrate liquidity failed'),
  },
  [TransactionType.SUBMIT_PROPOSAL]: {
    [TransactionStatus.Pending]: i18n.t('Submitting proposal'),
    [TransactionStatus.Confirmed]: i18n.t('Submitted proposal'),
    [TransactionStatus.Failed]: i18n.t('Submit proposal failed'),
  },
  [TransactionType.ADD_LIQUIDITY_V3_FARM]: {
    [TransactionStatus.Pending]: i18n.t('Adding V3 Farm Stake'),
    [TransactionStatus.Confirmed]: i18n.t('Added V3 Farm Stake'),
    [TransactionStatus.Failed]: i18n.t('Add V3 Farm Stake failed'),
  },
  [TransactionType.REMOVE_FARM_V3]: {
    [TransactionStatus.Pending]: i18n.t('Removing V3 Farm Stake'),
    [TransactionStatus.Confirmed]: i18n.t('Removed V3 Farm Stake'),
    [TransactionStatus.Failed]: i18n.t('Remove V3 Farm Stake failed'),
  },
  [TransactionType.CONVERT]: {
    [TransactionStatus.Pending]: i18n.t('Converting'),
    [TransactionStatus.Confirmed]: i18n.t('Converted'),
    [TransactionStatus.Failed]: i18n.t('Convert failed'),
  },
  [TransactionType.STAKE_BOO]: {
    [TransactionStatus.Pending]: i18n.t('Staking BOO'),
    [TransactionStatus.Confirmed]: i18n.t('Staked BOO'),
    [TransactionStatus.Failed]: i18n.t('Stake BOO failed'),
  },
  [TransactionType.UNSTAKE_BOO]: {
    [TransactionStatus.Pending]: i18n.t('Unstaking BOO'),
    [TransactionStatus.Confirmed]: i18n.t('Unstaked BOO'),
    [TransactionStatus.Failed]: i18n.t('Unstake BOO failed'),
  },
  [TransactionType.UNSTAKE_FARM_V3]: {
    [TransactionStatus.Pending]: i18n.t('Unstaking Farm V3'),
    [TransactionStatus.Confirmed]: i18n.t('Unstaked Farm V3'),
    [TransactionStatus.Failed]: i18n.t('Unstake Farm V3 failed'),
  },
  [TransactionType.STAKE_FARM_V3]: {
    [TransactionStatus.Pending]: i18n.t('Staking Farm V3'),
    [TransactionStatus.Confirmed]: i18n.t('Staked Farm V3'),
    [TransactionStatus.Failed]: i18n.t('Stake Farm V3 failed'),
  },
  [TransactionType.WITHDRAW_FARM_V3]: {
    [TransactionStatus.Pending]: i18n.t('Withdrawing Farm V3'),
    [TransactionStatus.Confirmed]: i18n.t('Withdrew Farm V3'),
    [TransactionStatus.Failed]: i18n.t('Withdraw Farm V3 failed'),
  },
  [TransactionType.DEPOSIT_FARM_V3]: {
    [TransactionStatus.Pending]: i18n.t('Depositing Farm V3'),
    [TransactionStatus.Confirmed]: i18n.t('Deposited Farm V3'),
    [TransactionStatus.Failed]: i18n.t('Deposit Farm V3 failed'),
  },
  [TransactionType.LAUNCH_TOKEN]: {
    [TransactionStatus.Pending]: i18n.t('Launching Coin'),
    [TransactionStatus.Confirmed]: i18n.t('Launched Coin'),
    [TransactionStatus.Failed]: i18n.t('Launch Coin failed'),
  },
}

export const CancelledTransactionTitleTable: { [key in TransactionType]: string } = {
  [TransactionType.SWAP]: i18n.t('Swap cancelled'),
  [TransactionType.WRAP]: i18n.t('Wrap cancelled'),
  [TransactionType.ADD_LIQUIDITY_V3_POOL]: i18n.t('Add liquidity cancelled'),
  [TransactionType.REMOVE_LIQUIDITY_V3]: i18n.t('Remove liquidity cancelled'),
  [TransactionType.CREATE_V3_POOL]: i18n.t('Create pool cancelled'),
  [TransactionType.COLLECT_FEES]: i18n.t('Collect fees cancelled'),
  [TransactionType.APPROVAL]: i18n.t('Approval cancelled'),
  [TransactionType.CLAIM]: i18n.t('Claim cancelled'),
  [TransactionType.CLAIM_ALL]: i18n.t('Claim all cancelled'),
  [TransactionType.BUY]: i18n.t('Buy cancelled'),
  [TransactionType.SEND]: i18n.t('Send cancelled'),
  [TransactionType.RECEIVE]: i18n.t('Receive cancelled'),
  [TransactionType.MINT]: i18n.t('Mint cancelled'),
  [TransactionType.BURN]: i18n.t('Burn cancelled'),
  [TransactionType.VOTE]: i18n.t('Vote cancelled'),
  [TransactionType.QUEUE]: i18n.t('Queue cancelled'),
  [TransactionType.EXECUTE]: i18n.t('Execute cancelled'),
  [TransactionType.BORROW]: i18n.t('Borrow cancelled'),
  [TransactionType.REPAY]: i18n.t('Repay cancelled'),
  [TransactionType.DEPLOY]: i18n.t('Deploy cancelled'),
  [TransactionType.CANCEL]: i18n.t('Cancellation cancelled'),
  [TransactionType.DELEGATE]: i18n.t('Delegate cancelled'),
  [TransactionType.DEPOSIT_LIQUIDITY_STAKING]: i18n.t('Deposit cancelled'),
  [TransactionType.WITHDRAW_LIQUIDITY_STAKING]: `Withdrawal cancelled`,
  [TransactionType.ADD_LIQUIDITY_V2_POOL]: i18n.t('Add V2 liquidity cancelled'),
  [TransactionType.ADD_LIQUIDITY_AND_FARM_V2]: i18n.t('Add V2 liquidity and Farm cancelled'),
  [TransactionType.ADD_LIQUIDITY_V3_FARM]: i18n.t('Add V3 Farm cancelled'),
  [TransactionType.CONVERT]: i18n.t('convert cancelled'),
  [TransactionType.REMOVE_FARM_V3]: i18n.t('Remove V3 Farm cancelled'),
  [TransactionType.MIGRATE_LIQUIDITY_V3]: i18n.t('Migrate liquidity cancelled'),
  [TransactionType.SUBMIT_PROPOSAL]: i18n.t('Submit proposal cancelled'),
  [TransactionType.STAKE_BOO]: i18n.t('Stake cancelled'),
  [TransactionType.UNSTAKE_BOO]: i18n.t('Unstake cancelled'),
  [TransactionType.UNSTAKE_FARM_V3]: i18n.t('Unstake Farm V3 cancelled'),
  [TransactionType.WITHDRAW_FARM_V3]: i18n.t('Withdraw Farm V3 cancelled'),
  [TransactionType.DEPOSIT_FARM_V3]: i18n.t('Deposit Farm V3 cancelled'),
  [TransactionType.STAKE_FARM_V3]: i18n.t('Stake Farm V3 cancelled'),
  [TransactionType.LAUNCH_TOKEN]: i18n.t('Launch coin cancelled'),
}

const AlternateTransactionTitleTable: { [key in TransactionType]?: { [state in TransactionStatus]: string } } = {
  [TransactionType.WRAP]: {
    [TransactionStatus.Pending]: i18n.t('Unwrapping'),
    [TransactionStatus.Confirmed]: i18n.t('Unwrapped'),
    [TransactionStatus.Failed]: i18n.t('Unwrap failed'),
  },
  [TransactionType.APPROVAL]: {
    [TransactionStatus.Pending]: i18n.t('Revoking approval'),
    [TransactionStatus.Confirmed]: i18n.t('Revoked approval'),
    [TransactionStatus.Failed]: i18n.t('Revoke approval failed'),
  },
}

export function getActivityTitle(type: TransactionType, status: TransactionStatus, alternate?: boolean) {
  if (alternate) {
    const alternateTitle = AlternateTransactionTitleTable[type]
    if (alternateTitle !== undefined) return alternateTitle[status]
  }
  return TransactionTitleTable[type][status]
}

const SwapTitleTable = TransactionTitleTable[TransactionType.SWAP]
export const OrderTextTable: {
  [status in UniswapXOrderStatus]: { title: string; status: TransactionStatus; statusMessage?: string }
} = {
  [UniswapXOrderStatus.OPEN]: {
    title: SwapTitleTable.PENDING,
    status: TransactionStatus.Pending,
  },
  [UniswapXOrderStatus.FILLED]: {
    title: SwapTitleTable.CONFIRMED,
    status: TransactionStatus.Confirmed,
  },
  [UniswapXOrderStatus.EXPIRED]: {
    title: `Swap expired`,
    statusMessage: `Your swap could not be fulfilled at this time. Please try again.`,
    status: TransactionStatus.Failed,
  },
  [UniswapXOrderStatus.ERROR]: {
    title: SwapTitleTable.FAILED,
    status: TransactionStatus.Failed,
  },
  [UniswapXOrderStatus.INSUFFICIENT_FUNDS]: {
    title: SwapTitleTable.FAILED,
    statusMessage: `Your account had insufficent funds to complete this swap.`,
    status: TransactionStatus.Failed,
  },
  [UniswapXOrderStatus.CANCELLED]: {
    title: `Swap cancelled`,
    status: TransactionStatus.Failed,
  },
}

// Non-exhaustive list of addresses Moonpay uses when sending purchased tokens
export const MOONPAY_SENDER_ADDRESSES = [
  '0x8216874887415e2650d12d53ff53516f04a74fd7',
  '0x151b381058f91cf871e7ea1ee83c45326f61e96d',
  '0xb287eac48ab21c5fb1d3723830d60b4c797555b0',
  '0xd108fd0e8c8e71552a167e7a44ff1d345d233ba6',
]

// Converts GQL backend orderStatus enum to the enum used by the frontend and UniswapX backend
export const OrderStatusTable: { [key in SwapOrderStatus]: UniswapXOrderStatus } = {
  [SwapOrderStatus.Open]: UniswapXOrderStatus.OPEN,
  [SwapOrderStatus.Expired]: UniswapXOrderStatus.EXPIRED,
  [SwapOrderStatus.Error]: UniswapXOrderStatus.ERROR,
  [SwapOrderStatus.InsufficientFunds]: UniswapXOrderStatus.INSUFFICIENT_FUNDS,
  [SwapOrderStatus.Filled]: UniswapXOrderStatus.FILLED,
  [SwapOrderStatus.Cancelled]: UniswapXOrderStatus.CANCELLED,
}
