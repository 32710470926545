import { IHasTradeProperties, QuoteMethod } from 'state/routing/types'
import { ThemedText } from 'theme'

export default function RouterLabel({ trade }: { trade: IHasTradeProperties }) {
  if (trade.quoteMethod === QuoteMethod.CLIENT_SIDE || trade.quoteMethod === QuoteMethod.CLIENT_SIDE_FALLBACK) {
    return <ThemedText.BodySmall>Spookyswap Client</ThemedText.BodySmall>
  } else if (trade.quoteMethod === QuoteMethod.MAGPIE) {
    return <ThemedText.BodySmall>Aggregator</ThemedText.BodySmall>
  } else if (trade.quoteMethod === QuoteMethod.LIQUIDITY_HUB) {
    return <ThemedText.BodySmall>Powered By Orbs</ThemedText.BodySmall>
  } else if (trade.quoteMethod === QuoteMethod.BEST_TRADE_API) {
    return <ThemedText.BodySmall>Best Trade API</ThemedText.BodySmall>
  }
}
