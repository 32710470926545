import { ChainId } from '@uniswap/sdk-core'

export const BTT_PERMIT2_ADDRESS = '0x41846bd0E41dD42D80d00E99212040C871F40CB0'
export const FTM_PERMIT2_ADDRESS = '0x838c010F57889195C88f2097488e49B7ad04EcB1'
export const ZEN_PERMIT2_ADDRESS = '0xd119eF12fdC22756c8Ce4F03E463607742e3A59f'
export const BERA_TESTNET_PERMIT2_ADDRESS = '0x6AB0CA9c94FDE313a3A1d34A8247ae6065Bd2E73'
export const SONIC_TESTNET_PERMIT2_ADDRESS = '0xc67ED70B32D14EcDdb7d279b9A8D50d00aBD171e'
export const SONIC_PERMIT2_ADDRESS = '0x000000000022D473030F116dDEE9F6B43aC78BA3'

type AddressMap = {
  [chainId: number]: string
}

const MASTERCHEF_ADDRESS_MAP: AddressMap = {
  199: '0x6A8C15229FFD048dcffF3D05EaA5C1A70e6c599C',
  250: '0x2b2929E785374c651a81A63878Ab22742656DcDd',
}

const MASTERCHEF_V2_ADDRESS_MAP: AddressMap = {
  199: '0x29822044a7AD0F6B19A6CdCa1c82014785bFBa7e',
  250: '0x18b4f774fdC7BF685daeeF66c2990b1dDd9ea6aD',
}

const MASTERCHEF_V3_ADDRESS_MAP: AddressMap = {
  199: '0x29822044a7AD0F6B19A6CdCa1c82014785bFBa7e',
  250: '0x9C9C920E51778c4ABF727b8Bb223e78132F00aA4',
}

export const SPOOKYSWAP_CONFIG_MAP: AddressMap = {
  250: '0x44FD0e3a5DcE52de0E8628993b99C7dcF7e372Db',
}

export const FARM_FETCH_HELPER_MAP: AddressMap = {
  199: '0x99AcA5D9123859b3538EE9B80e4a4520B8A6d43F',
  250: '0x263e0a9Fb3193fAea94C580696A4fEcBBA0a8276',
}

export const PARASWAP_V5_PROXY_ROUTER_ADDRESS: AddressMap = {
  250: '0x216b4b4ba9f3e719726886d34a177484278bfcae',
}

export const PARASWAP_PROXY_ROUTER_ADDRESS: AddressMap = {
  250: '0x6A000F20005980200259B80c5102003040001068',
}

export const FANTOM_ADAPTER_ADDRESS = '0x3AF1CF07F9960e06B76D701c0CD7F1120707378A'

// TODO - update this address to the new converter address
export const BOO_CONVERTER_ADDRESS: AddressMap = {
  250: FANTOM_ADAPTER_ADDRESS,
}

export const BOO_OLD_ADDRESS = `${process.env.REACT_APP_BOO_OLD_ADDRESS}`
export const BOO_NEW_ADDRESS = `${process.env.REACT_APP_BOO_NEW_ADDRESS}`

export const BOO_ADDRESS: AddressMap = {
  250: '0x841FAD6EAe12c286d1Fd18d1d525DFfA75C7EFFE',
  146: '0x7a0c53f7eb34c5bc8b01691723669ada9d6cb384',
}
export const XBOO_ADDRESS: AddressMap = {
  250: '0xa48d959ae2e88f1daa7d5f611e01908106de7598',
  146: '0xa95eA1cfaBcCf0E9eb94b646CeFe9eD71ff5D605',
}

export const V3_UNIV3STAKER_MAP: AddressMap = {
  250: '0xB203D94eBFCc177a17F7df1ec58016af666BB813',
  199: '0xC23D6229f06Cb2FEa3b319B4DF7BD9D79eb80216',
  146: '0x160f55276713F356D8409910C3cfA1Da23217C23',
}

export const LAUNCHPAD_ADDRESS: AddressMap = {
  [ChainId.FANTOM]: '0x9d616b121CFFA480102161CB16D83B2bcA5beD09',
  [ChainId.SONIC]: '0xb52C0eb864722C313D2c084C6FFA2A6e46850c31',
}

type VersionedAddressMap = {
  [version: number]: AddressMap
}

const versionChef: VersionedAddressMap = {
  1: MASTERCHEF_ADDRESS_MAP,
  2: MASTERCHEF_V2_ADDRESS_MAP,
  3: MASTERCHEF_V3_ADDRESS_MAP,
}
export const getVersionedMasterchefAddressMap = (version: number) => {
  return versionChef[version]
}
