import { useCallback } from 'react'
import { useTransactionAdder } from 'state/transactions/hooks'
import { TransactionType } from 'state/transactions/types'
import { BooLandUnstake } from 'utils/callhelpers'
import { formatBalance } from 'utils/formatBalance'

import { useXbooContract } from './useContract'

export const useBooLandUnstakeV2 = () => {
  const addTransaction = useTransactionAdder()
  const BooLandContract = useXbooContract()

  const handleUnstake = useCallback(
    async (amount: string) => {
      const txHash = BooLandUnstake(BooLandContract, amount)

      txHash.then((response: any) => {
        const description = `Burn ${formatBalance(Number(amount))} xBOO from Mirror World`

        addTransaction(response, {
          type: TransactionType.UNSTAKE_BOO,
          summary: description,
        })
      })
    },
    [BooLandContract, addTransaction]
  )

  return { onUnstake: handleUnstake }
}
