import { Quote } from '@orbs-network/liquidity-hub-sdk'
import { Currency, CurrencyAmount, Price, TradeType } from '@uniswap/sdk-core'
import { useWeb3React } from '@web3-react/core'
import { DEFAULT_CHAIN_ID, LIQUIDITY_HUB_NATIVE_ADDRESS } from 'constants/misc'
import { getLiquidityHubSDK } from 'hooks/useLiquidityHubSDK'
import React from 'react'
import { useQuery } from 'react-query'
import { getApproveInfo } from 'state/routing/gas'
import { ApproveInfo, IHasQuoteProperties, QuoteMethod, TradeFillType } from 'state/routing/types'
import { computeFiatValuePriceImpact } from 'utils/computeFiatValuePriceImpact'

import { calculateFiatValue, QuoterProps, Quoters } from './QuoteManager'

// We're not setup to calculate usd gas cost so we will use default for now since the hooks are dependent on passing it
const DEFAULT_USD_COST_PER_GAS = 0.000000001

function getLiquidityHubInCurrencyAddress(currency: Currency) {
  return currency.isNative ? currency.wrapped.address : currency.address
}

function getLiquidityHubOutCurrencyAddress(currency: Currency) {
  return currency.isNative ? LIQUIDITY_HUB_NATIVE_ADDRESS : currency.address
}

// eslint-disable-next-line import/no-unused-modules
export const LiquidityHubSDKQuoter: React.FC<QuoterProps> = ({
  swapParams,
  onQuoteUpdate,
  inputFiatValuePerToken,
  outputFiatValuePerToken,
}) => {
  const { account, chainId } = useWeb3React()

  // The quote manager blasts this hooks we dont want external dependencies
  //const liquidityHubSDK = useLiquidityHubSDK()
  // Consolidated function to fetch and resolve the quote
  const fetchAndResolveLHQuote = async () => {
    const fromToken = getLiquidityHubInCurrencyAddress(swapParams.inputCurrency)
    const toToken = getLiquidityHubOutCurrencyAddress(swapParams.outputCurrency)

    let approveInfo: ApproveInfo | undefined
    if (account) {
      //Figure out a way so this doesnt need to be run everytime
      // Hard code gas cost since its required to get approve info
      approveInfo = await getApproveInfo(
        account,
        swapParams.inputCurrency.wrapped,
        swapParams.amount.toString(),
        DEFAULT_USD_COST_PER_GAS
      )
    }

    const liquidityHubSDK = getLiquidityHubSDK(chainId ?? DEFAULT_CHAIN_ID)

    try {
      // Fetch the rate from LiquidityHub
      //
      // export interface QuoteArgs {
      // fromToken: string;
      // toToken: string;
      // inAmount: string;
      // dexMinAmountOut?: string;
      // account?: string;
      // partner: string;
      // slippage: number;
      // signal?: AbortSignal;
      // chainId: number;
      // timeout?: number;
      // }
      //
      //
      const lhQuote = await liquidityHubSDK.getQuote({
        fromToken,
        toToken,
        inAmount: swapParams.amount.toString(),
        account,
        slippage: Number(swapParams.allowedSlippage.toFixed(6)),
      })

      // Resolve the optimal rate into a quote
      const resolvedOptimalQuote = resolveQuoteFromLiquidityHub(
        lhQuote,
        swapParams.inputCurrency,
        swapParams.outputCurrency,
        approveInfo,
        inputFiatValuePerToken,
        outputFiatValuePerToken
      )
      const isQuoteValid = resolvedOptimalQuote.outputAmount && resolvedOptimalQuote.outputAmount.greaterThan(0)

      // Prepare the final quote object
      const quote: IHasQuoteProperties = {
        ...resolvedOptimalQuote,
        isValid: isQuoteValid,
      }

      return quote
    } catch (err) {
      console.error('Failed to fetch LiquidityHub quote', err)
      return null
    }
  }

  // Resolving the fetched rate into the IHasQuoteProperties format
  const resolveQuoteFromLiquidityHub = (
    rawQuote: Quote,
    inputCurrency: Currency,
    outputCurrency: Currency,
    approveInfo: ApproveInfo | undefined,
    inputFiatValuePerToken?: number,
    outputFiatValuePerToken?: number
  ): IHasQuoteProperties => {
    const inputCurrencyAmount = CurrencyAmount.fromRawAmount(inputCurrency, rawQuote.inAmount)
    const outputCurrencyAmount = CurrencyAmount.fromRawAmount(outputCurrency, rawQuote.outAmount)

    const internalFiatInputValue = calculateFiatValue(inputFiatValuePerToken, inputCurrencyAmount)
    const fiatInputValue: number | undefined = internalFiatInputValue

    const internalFiatOutputValue = calculateFiatValue(outputFiatValuePerToken, outputCurrencyAmount)
    const fiatOutputValue: number | undefined = internalFiatOutputValue

    return {
      inputAmount: inputCurrencyAmount,
      outputAmount: outputCurrencyAmount,
      executionPrice: new Price(
        inputCurrencyAmount.currency,
        outputCurrencyAmount.currency,
        inputCurrencyAmount.quotient,
        outputCurrencyAmount.quotient
      ),
      quote: rawQuote,
      quoteMethod: QuoteMethod.LIQUIDITY_HUB,
      fillType: TradeFillType.LiquidityHub,
      //LH Hub only supports exact input
      tradeType: TradeType.EXACT_INPUT,
      postTaxOutputAmount: outputCurrencyAmount,
      approveInfo: approveInfo ?? { needsApprove: false },
      gasUseEstimateUSD: undefined, // TODO: this needs to be converted to usd rawQuote.gasAmountOut ? Number(rawQuote.gasAmountOut) : undefined,
      priceImpact: computeFiatValuePriceImpact(fiatInputValue, fiatOutputValue), // Use rate.destUSD directly for price impact
      fiatValueInput: { data: Number(fiatInputValue), isLoading: false },
      fiatValueOutput: { data: Number(fiatOutputValue), isLoading: false },
      quoter: Quoters.LIQUIDITY_HUB,
      dependencies: {},
      wrapInfo: inputCurrencyAmount.currency.isNative
        ? { needsWrap: true, wrapGasEstimateUSD: DEFAULT_USD_COST_PER_GAS }
        : { needsWrap: false },
    }
  }

  // useQuery hook to fetch and resolve quotes
  useQuery({
    queryKey: ['fetchAndResolveLHSDKQuote', swapParams],
    queryFn: fetchAndResolveLHQuote, // Directly call fetchAndResolveQuote
    refetchInterval: 5000, // Auto-refetch every 5 seconds
    onSuccess: (quote) => {
      if (quote) {
        onQuoteUpdate(Quoters.LIQUIDITY_HUB, quote)
      }
    },
  })

  return null // No rendering necessary
}
