import { ADDRESS_ZERO } from '@uniswap/v3-sdk'
import axios from 'axios'

export const MAGPIE_CHAINID_TO_NETWORK: { [chainId: number]: string } = {
  250: 'fantom',
  146: 'sonic',
}

const MAGPIE_BASE_URL = 'https://api.magpiefi.xyz'
const QUOTE_URL = `${MAGPIE_BASE_URL}/aggregator/quote`
const TRANSACTION_URL = `${MAGPIE_BASE_URL}/aggregator/transaction`

const EXECUTE_SWAP_URL = `${MAGPIE_BASE_URL}/user-manager/execute-swap`

interface ExecuteSwapParams {
  chainId: number
  quoteId: string
  swapSignature: string
  permitSignature?: string
  permitDeadline?: number
}

interface MagpieQuoteParams {
  network: string
  fromTokenAddress: string
  toTokenAddress: string
  sellAmount: string
  slippage: number
  gasless: boolean
  affiliateAddress?: string
  affiliateFeeInPercentage?: string
  toAddress?: string
  fromAddress?: string
}

export const MAGPIE_NATIVE_ADDRESS = ADDRESS_ZERO

// eslint-disable-next-line @typescript-eslint/adjacent-overload-signatures
export async function getMagpieQuote(params: MagpieQuoteParams) {
  const {
    network,
    fromTokenAddress,
    toTokenAddress,
    sellAmount,
    slippage,
    gasless,
    affiliateAddress,
    affiliateFeeInPercentage,
    fromAddress,
    toAddress,
  } = params

  const queryParams = new URLSearchParams({
    network,
    fromTokenAddress,
    toTokenAddress,
    sellAmount,
    slippage: slippage.toString(),
    gasless: gasless.toString(),
  })

  if (affiliateAddress) {
    queryParams.append('affiliateAddress', affiliateAddress)
  }
  if (affiliateFeeInPercentage !== undefined) {
    queryParams.append('affiliateFeeInPercentage', affiliateFeeInPercentage.toString())
  }

  if (fromAddress) {
    queryParams.append('fromAddress', fromAddress)
  }
  if (toAddress) {
    queryParams.append('toAddress', toAddress)
  }

  try {
    const response = await axios.get(`${QUOTE_URL}?${queryParams.toString()}`, {
      headers: {
        accept: 'application/json',
      },
    })

    return response.data
  } catch (error) {
    console.error('Error fetching quote:', error)
    throw error
  }
}

// eslint-disable-next-line import/no-unused-modules
export async function getMagpieTransaction(quoteId: string, estimateGas: boolean) {
  const queryParams = new URLSearchParams({
    quoteId,
    estimateGas: estimateGas.toString(),
  })
  try {
    const response = await axios.get(`${TRANSACTION_URL.toString()}?${queryParams.toString()}`, {
      headers: {
        accept: 'application/json',
      },
    })

    return response.data
  } catch (error) {
    console.error('Error fetching quote:', error)
    throw error
  }
}

// eslint-disable-next-line import/no-unused-modules
export async function executeMagpieSwap(params: ExecuteSwapParams) {
  const { chainId, quoteId, swapSignature } = params

  const networkName = MAGPIE_CHAINID_TO_NETWORK[chainId]!

  const queryParams = new URLSearchParams({
    networkName,
    quoteId,
    swapSignature,
  })

  try {
    const response = await axios.get(`${EXECUTE_SWAP_URL.toString()}?${queryParams.toString()}`, {
      headers: {
        accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })

    return response.data
  } catch (error) {
    console.error('Error executing swap:', error)
    throw error
  }
}
