import { t } from '@lingui/macro'
import { useAtom } from 'jotai'
import { atomWithStorage } from 'jotai/utils'

import { SettingsToggle } from './SettingsToggle'

export const gaslessWhenPossibleAtom = atomWithStorage<boolean>('useGaslessWhenPossible', false)

export function GaslessToggle() {
  const [gaslessWhenPossible, updateGaslessWhenPossible] = useAtom(gaslessWhenPossibleAtom)

  return (
    <SettingsToggle
      title={t`Use gasless`}
      dataid="gasless-toggle"
      isActive={gaslessWhenPossible}
      toggle={() => void updateGaslessWhenPossible((value) => !value)}
    />
  )
}
