import { BigNumber } from '@ethersproject/bignumber'
import { Trans } from '@lingui/macro'
import { useWeb3React } from '@web3-react/core'
import { MAX_WIDTH_MEDIA_BREAKPOINT } from 'components/Tokens/constants'
import useSelectChain from 'hooks/useSelectChain'
import { useDebouncedEffect } from 'lib/hooks/useDebouncedEffect'
import { ReactNode, useEffect, useState } from 'react'
import { AlertTriangle } from 'react-feather'
import { useLocation } from 'react-router-dom'
import { useUSDPricesWithFallback } from 'state/cache/hooks'
import styled from 'styled-components'
import { getChainFromUrlOrEmpty } from 'utils/dynamicSwapRoute'
import { isV3FarmDisabled } from 'utils/env'

import { Incentive } from '../FarmDetails/details'
import { useV3FarmIncentivesAll } from '../hooks/fetchFarms'
import { HeaderRowComponent, LoadedFarmRow } from './FarmRow'

interface FarmTableProps {
  showExpiredFarms: boolean
  farmType: 'official' | 'community'
  isVerified: boolean
  showRiskyFarms: boolean // Add this prop
}

const GridContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: ${MAX_WIDTH_MEDIA_BREAKPOINT};
  background-color: ${({ theme }) => theme.surface1};
  z-index: 1;
  margin-left: auto;
  margin-right: auto;
  border-radius: 12px;
  justify-content: center;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.surface3};
`

const FarmDataContainer = styled.div`
  & > div:nth-child(odd) {
    background: ${({ theme }) => theme.surface2};
  }

  & > div:nth-child(even) {
    background-color: transparent;
  }

  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`

const NoFarmDisplay = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 60px;
  color: ${({ theme }) => theme.neutral2};
  font-size: 16px;
  font-weight: 535;
  align-items: center;
  padding: 0px 28px;
  gap: 8px;
`

function NoFarmsState({ message }: { message: ReactNode }) {
  return (
    <GridContainer>
      <HeaderRowComponent />
      <NoFarmDisplay>{message}</NoFarmDisplay>
    </GridContainer>
  )
}

export default function FarmTable({ showExpiredFarms, farmType, isVerified, showRiskyFarms }: FarmTableProps) {
  const [validFarms, setValidFarms] = useState<Incentive[]>([])
  // Pass showCommunityFarms to the hook
  const { loading, incentives, error } = useV3FarmIncentivesAll(farmType, isVerified)
  const cachedTokenPrices = useUSDPricesWithFallback()
  const { chainId } = useWeb3React()
  const selectChain = useSelectChain()
  const location = useLocation()
  const offChain = getChainFromUrlOrEmpty(location)

  useDebouncedEffect(
    () => {
      async function updateChain() {
        if (!selectChain || !offChain) {
          return
        }

        if (chainId !== offChain) {
          await selectChain(offChain)
        }
      }

      updateChain()
    },
    [selectChain, chainId, offChain], // Dependencies
    1000 // Delay in milliseconds (1 second)
  )

  useEffect(() => {
    const now = BigNumber.from(Math.floor(Date.now() / 1000))

    const filterAndProcessFarms = async () => {
      if (!incentives) return

      const filteredFarms = await Promise.all(
        incentives.map(async (farm) => {
          const endTime = BigNumber.from(farm?.endTime)
          const startTime = BigNumber.from(farm?.startTime)
          const isExpired = endTime.lt(now)
          const isActive = endTime.gt(now) && startTime.lt(now)
          const shouldShow = showExpiredFarms ? isExpired : isActive

          return shouldShow
            ? {
                ...farm,
                isExpired,
                isActive,
              }
            : null
        })
      )

      setValidFarms(filteredFarms.filter((farm) => farm !== null) as Incentive[])
    }

    filterAndProcessFarms()
  }, [incentives, showExpiredFarms])

  // Rendering Logic
  if (loading && !incentives) {
    return null // Or a placeholder component
  }
  if (error) {
    console.log('🚀 ~ FarmTable ~ error:', error)
    return (
      <NoFarmsState
        message={
          <>
            <AlertTriangle size={16} />
            <Trans>An error occurred loading Farms. Please try again.</Trans>
          </>
        }
      />
    )
  }

  if (incentives?.length === 0 || validFarms?.length === 0 || isV3FarmDisabled() === true) {
    return (
      <NoFarmsState message={<Trans>{showExpiredFarms ? 'No expired Farms found' : 'No active Farms found'}</Trans>} />
    )
  }

  // Render Farm Data
  return (
    <GridContainer>
      <HeaderRowComponent />
      <FarmDataContainer>
        {validFarms?.map(
          (farm, index) =>
            farm?.id && (
              <LoadedFarmRow
                key={farm?.id}
                farmListIndex={index}
                farmListLength={validFarms.length}
                farm={farm}
                priceMap={cachedTokenPrices}
                sortRank={1}
                showRiskyFarms={showRiskyFarms}
              />
            )
        )}
      </FarmDataContainer>
    </GridContainer>
  )
}
