import { BigNumber } from '@ethersproject/bignumber'
import { Contract } from '@ethersproject/contracts'
import { Trans } from '@lingui/macro'
import { CurrencyAmount, Token } from '@uniswap/sdk-core'
import { Pool } from '@uniswap/v3-sdk'
import { useWeb3React } from '@web3-react/core'
import { usePendingActivity } from 'components/AccountDrawer/MiniPortfolio/Activity/hooks'
import Badge from 'components/Badge'
import { ButtonSecondary } from 'components/Button'
import { AutoColumn } from 'components/Column'
import CurrencyLogo from 'components/Logo/CurrencyLogo'
import { RowBetween, RowFixed } from 'components/Row'
import { TokenIdBalance } from 'components/TokenIdBalance'
import { BIG_INT_ZERO, ZERO_ADDRESS } from 'constants/misc'
import { useToken } from 'hooks/Tokens'
import { useColor } from 'hooks/useColor'
import { useV3PositionsFromFarm } from 'hooks/useV3PositionsVested'
import { useSingleContractMultipleData } from 'lib/hooks/multicall'
import { useTokenFromActiveNetwork } from 'lib/hooks/useCurrency'
import { Table } from 'nft/components/explore/Table'
import { CheckMarkIcon } from 'nft/components/icons'
import { themeVars } from 'nft/css/sprinkles.css'
import { useIsMobile } from 'nft/hooks'
import { floorFormatter } from 'nft/utils'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { AlertTriangle, CheckCircle, Clock } from 'react-feather'
import { Row } from 'react-table'
import styled from 'styled-components'
import { ThemedText } from 'theme'
import { formatDuration } from 'utils/formatDuration'

import { UserPosition } from '../hooks/fetchFarms'
import { PositionMetadataGenerate } from '../hooks/misc'
import { VestingWarningModal } from '../VestingWarning'
import { FarmAction, Incentive } from './details'

const WrapperStaked = styled.div`
  display: flex;
  align-items: right;
  justify-content: right;
`

const WrapperResponsive = styled(RowFixed)`
  @media (max-width: ${({ theme }) => `${theme.breakpoint.md}px`}) {
    display: flex;
    flex-direction: column;
    align-items: right;
    justify-content: right;
  }
`

const BadgeUnstake = styled(Badge)`
  @media (max-width: ${({ theme }) => `${theme.breakpoint.md}px`}) {
    padding: 0;
  }
`

const NFTContainer = styled.div`
  display: flex;
  align-items: center;
  align-self: center;
  justify-content: center;
  max-height: 100px;
`

const RewardsContainer = styled.div`
  @media (max-width: ${({ theme }) => `${theme.breakpoint.md}px`}) {
    width: 100%;
  }
`

const VestingBadge = styled(Badge)`
  background-color: ${({ theme }) => theme.deprecated_yellow3};
  padding: 4px 8px;
  border-radius: 8px;
  margin-top: 4px;
  width: fit-content;
  align-self: flex-end;
`

const VestingText = styled(ThemedText.BodySmall)`
  color: ${({ theme }) => theme.deprecated_yellow2};
  font-size: 12px;
  font-weight: 500;
`

// Define a type for the row data
type RowData = {
  key: string
  nft: string
  tokenId: string
  positionValue: string
  pendingRewards: number
  staked: number
  isDeposited: boolean
}

type Props = {
  row: Row<RowData>
}

type PositionType = {
  isStaked: boolean
  stakedTimestamp: number
  hasRewards: boolean
  isDeposited: boolean
  liquidity: BigNumber
}
type PositionMap = { [tokenId: string]: PositionType }

export function PositionsTable({
  positions,
  pool,
  setModal,
  setFarmActionState,
  setToken,
  incentive,
  UniV3StakerContract,
  tokenRewards,
  remainingDays,
}: {
  positions: UserPosition[]
  pool: Pool
  setModal: (modal: any) => void
  setFarmActionState: (action: FarmAction) => void
  setToken: (token: any) => void
  incentive: Incentive
  UniV3StakerContract: Contract | null
  tokenRewards: { [tokenId: string]: string }
  remainingDays?: number
}) {
  const { account } = useWeb3React()
  const [positionStates, setPositionStates] = useState<PositionMap>({})
  const [showVestingWarning, setShowVestingWarning] = useState(false)
  const [selectedTokenForUnstake, setSelectedTokenForUnstake] = useState<string | null>(null)

  const tokenQuote = useToken(pool.token1.address) || undefined
  const tokenBase = useToken(pool.token0.address) || undefined

  const currencyQuoteColor = useColor(tokenQuote)
  const currencyBaseColor = useColor(tokenBase)

  const tokenIds = useMemo(() => {
    return positions.map((position) => position.tokenId.toString())
  }, [positions])

  // Check if positions are staked
  const stakedCalls = useMemo(() => {
    return tokenIds.map((tokenId) => ({
      params: [tokenId],
    }))
  }, [tokenIds])

  const { hasPendingActivity } = usePendingActivity()

  const depositedResults = useSingleContractMultipleData(
    UniV3StakerContract,
    'deposits',
    stakedCalls.map((call) => call.params)
  )

  // Create calls for stakes function
  const stakesCalls = useMemo(() => {
    return tokenIds.map((tokenId) => ({
      params: [tokenId, incentive.id],
    }))
  }, [tokenIds, incentive.id])

  const stakesResults = useSingleContractMultipleData(
    UniV3StakerContract,
    'stakes',
    stakesCalls.map((call) => call.params)
  )

  const { positions: filteredPositions } = useV3PositionsFromFarm(account, pool)

  useEffect(() => {
    const newPositionStates: PositionMap = {}
    depositedResults.forEach((result, index) => {
      if (result.result && result.result.length > 0) {
        const tokenId = tokenIds[index]
        const stakesResult = stakesResults[index]
        const position = positions.find((p) => p.tokenId.toString() === tokenId)

        const liquidity =
          stakesResult.result && stakesResult.result.length > 0 ? BigNumber.from(stakesResult.result[0]) : 0
        newPositionStates[tokenId] = {
          isStaked: result.result.numberOfStakes > 0,
          stakedTimestamp: position?.staked ?? 0,

          hasRewards: false, // We'll update this in the next loop
          isDeposited: result.result.owner !== ZERO_ADDRESS,
          liquidity: BigNumber.from(liquidity),
        }
      }
    })
    setPositionStates(newPositionStates)
  }, [depositedResults, stakesResults, tokenIds, positions])

  // Add getRewardInfo calls
  const rewardInfoCalls = useMemo(() => {
    if (!incentive || !tokenIds.length) return []
    return tokenIds.map((tokenId) => ({
      params: [
        [
          incentive.rewardToken,
          incentive.pool,
          incentive.startTime,
          incentive.endTime,
          incentive.vestingPeriod,
          incentive.refundee,
        ],
        tokenId,
      ],
    }))
  }, [tokenIds, incentive])

  const rewardInfoResults = useSingleContractMultipleData(
    UniV3StakerContract,
    'getRewardInfo',
    rewardInfoCalls.map((call) => call.params)
  )
  console.log('🚀 ~ rewardInfoResults:', rewardInfoResults)

  // Helper to calculate remaining vesting time
  const calculateRemainingVesting = useCallback(
    (tokenId: string) => {
      if (!incentive.vestingPeriod) return 0

      const rewardInfo = rewardInfoResults.find((_, index) => tokenIds[index] === tokenId)
      console.log('🚀 ~ rewardInfo:', rewardInfo)
      if (!rewardInfo?.result) return 0
      const position = positions.find((p) => p.tokenId.toString() === tokenId)
      if (!position || position.staked === 0) return 0

      const stakedTimestamp = position.staked
      const currentTimestamp = Math.floor(Date.now() / 1000)
      const vestingPeriod = Number(incentive.vestingPeriod)
      const timeStaked = currentTimestamp - stakedTimestamp

      return Math.max(0, vestingPeriod - timeStaked)
    },
    [incentive.vestingPeriod, positions, rewardInfoResults, tokenIds]
  )

  const positionData = useMemo(() => {
    if (!filteredPositions || filteredPositions.length === 0) {
      return []
    }

    return filteredPositions.map((position) => {
      const positionState = positionStates[position.tokenId.toString()]

      return {
        nft: position.tokenId,
        tokenId: position.tokenId.toString(),
        positionValue: '',
        staked: positionState?.isStaked ?? false,
        isDeposited: positionState?.isDeposited ?? false,
        liquidity: positionState?.liquidity ?? BIG_INT_ZERO,
      }
    })
  }, [filteredPositions, positionStates])

  const clearPoolData = useCallback(
    (original: any) => {
      setToken(original.tokenId)
      setModal(true)
    },
    [setToken, setModal]
  )

  const handleDeposit = useCallback(
    (original: any): void => {
      setFarmActionState(FarmAction.Deposit)
      clearPoolData(original)
    },
    [setFarmActionState, clearPoolData]
  )

  const handleStake = useCallback(
    (original: any): void => {
      setFarmActionState(FarmAction.Stake)
      clearPoolData(original)
    },
    [setFarmActionState, clearPoolData]
  )

  const handleUnstake = useCallback(
    (original: any): void => {
      const remainingVesting = calculateRemainingVesting(original.tokenId)
      if (remainingVesting > 0) {
        setSelectedTokenForUnstake(original.tokenId)
        setShowVestingWarning(true)
      } else {
        setFarmActionState(FarmAction.Unstake)
        clearPoolData(original)
      }
    },
    [calculateRemainingVesting, setFarmActionState, clearPoolData]
  )

  const handleWithdraw = useCallback(
    (original: any): void => {
      setFarmActionState(FarmAction.Withdraw)
      clearPoolData(original)
    },
    [setFarmActionState, clearPoolData]
  )

  const handleClaim = useCallback(
    (original: any): void => {
      setFarmActionState(FarmAction.Claim)
      clearPoolData(original)
    },
    [setFarmActionState, clearPoolData]
  )

  const handleConfirmUnstake = useCallback(() => {
    if (selectedTokenForUnstake) {
      setFarmActionState(FarmAction.Unstake)
      clearPoolData({ tokenId: selectedTokenForUnstake })
    }
    setShowVestingWarning(false)
    setSelectedTokenForUnstake(null)
  }, [selectedTokenForUnstake, setFarmActionState, clearPoolData])

  const isMobile = useIsMobile()

  const columns = React.useMemo(() => {
    return [
      {
        accessor: 'nft',
        title: 'NFT',
        dataIndex: 'nft',
        key: 'nft',
        Cell: ({ row }: Props) => {
          const currentTokenId = row.original.tokenId

          if (!filteredPositions) {
            return <div>Loading position data...</div>
          }
          const positionHere = filteredPositions.find((pos) => pos.tokenId.toString() === currentTokenId)
          if (!positionHere) {
            return <div>Position data unavailable</div>
          }
          return (
            <>
              <NFTContainer>
                <PositionMetadataGenerate
                  key={currentTokenId}
                  pool={pool}
                  currencyQuoteColor={currencyQuoteColor}
                  currencyBaseColor={currencyBaseColor}
                  position={positionHere}
                  height={100}
                />
              </NFTContainer>
              <TokenIdBalance tokenId={currentTokenId} />
            </>
          )
        },
        Header: () => <div>LP</div>,
      },
      {
        accessor: 'staked',
        title: 'Staked',
        dataIndex: 'staked',
        key: 'staked',
        Cell: ({ row }: { row: Row<RowData> }) => {
          const tokenId = row.original.tokenId
          const state = positionStates[tokenId]
          const rewards = tokenRewards?.[tokenId]?.toString() ?? '0'
          const remainingVesting = calculateRemainingVesting(tokenId)
          const stakedTimestamp = row.original.staked

          if (!state) return null // Loading or error state
          return (
            <WrapperStaked>
              {stakedTimestamp > 0 && state.liquidity.gt(0) ? (
                <AutoColumn>
                  <RowFixed alignItems="right" style={{ width: '100%', justifyContent: 'flex-end' }}>
                    <LabelText color={themeVars.colors.success}>
                      <Badge
                        style={{
                          display: 'flex',
                          gap: '8px',
                          alignItems: 'right',
                          textAlign: 'right',
                          width: '100%',
                          background: 'none',
                          padding: '0px',
                        }}
                      >
                        <CheckMarkIcon height="20px" width="20px" color={themeVars.colors.success}></CheckMarkIcon>
                        <BadgeTextSuccess style={{ display: 'flex', gap: '4px' }} color={themeVars.colors.success}>
                          <Trans>Staked {remainingVesting > 0 ? (<> - Vesting <Clock /></>) : ""}</Trans>
                        </BadgeTextSuccess>
                      </Badge>
                    </LabelText>
                  </RowFixed>

                  <WrapperResponsive>
                    <BadgeUnstake style={{ gap: '8px', alignItems: 'right', background: 'none' }}>
                      <BadgeText style={{ display: 'flex', gap: '4px' }} color={themeVars.colors.success}>
                        <Trans>Claimable</Trans>
                      </BadgeText>
                    </BadgeUnstake>
                    <RewardCell reward={rewards} rewardToken={incentive.rewardToken} />
                  </WrapperResponsive>
                </AutoColumn>
              ) : state.isStaked && state.liquidity.gt(0) ? (
                <>
                  <RowFixed>
                    <LabelText color={themeVars.colors.success}>
                      <Badge style={{ gap: '8px', alignItems: 'right', background: 'none', padding: '0px' }}>
                        <BadgeText color={themeVars.colors.success} style={{ display: 'flex', gap: '4px' }}>
                          <Trans>Staked</Trans>
                          <CheckCircle color={themeVars.colors.success} width="25px" />
                        </BadgeText>
                      </Badge>
                    </LabelText>
                    <RewardCell reward={rewards} rewardToken={incentive.rewardToken} />
                  </RowFixed>
                </>
              ) : state.isStaked && state.liquidity.eq(0) ? (
                <>
                  <RowFixed>
                    <LabelText color="grey">
                      <Badge style={{ gap: '8px', padding: '0px', background: 'none' }}>
                        <BadgeText style={{ color: 'grey', display: 'flex', gap: '4px' }}>
                          <Trans>Staked (Other Farm)</Trans>
                        </BadgeText>
                      </Badge>
                    </LabelText>
                  </RowFixed>
                </>
              ) : state.isDeposited ? (
                <LabelText color="orange">
                  <Badge style={{ gap: '8px', padding: '0px' }}>
                    <BadgeText style={{ color: 'orange', display: 'flex', gap: '4px', background: 'none' }}>
                      <Trans>Deposited - Unstaked</Trans>
                      <AlertTriangle color="orange" width="25px" />
                    </BadgeText>
                  </Badge>
                </LabelText>
              ) : (
                <LabelText color={themeVars.colors.neutral1}>
                  <Badge style={{ gap: '8px', background: 'none', padding: '0px' }}>
                    <BadgeText>
                      <Trans>Not Deposited</Trans>
                    </BadgeText>
                  </Badge>
                </LabelText>
              )}
            </WrapperStaked>
          )
        },
        Header: () => <div>Status</div>,
      },
      {
        accessor: 'manageLiquidity',
        title: 'Manage Liquidity',
        Cell: ({ row }: Props) => {
          const tokenId = row.original.tokenId
          const state = positionStates[tokenId]

          if (!state) return null // Loading or error state

          const isIncentiveActive = remainingDays !== undefined && remainingDays > 0

          return (
            <ResponsiveButtonContainer>
              <AutoColumn style={{ width: '100%', rowGap: '0px', alignItems: 'flex-end' }} justify="flex-end">
                {state.isStaked && state.liquidity.gt(0) ? (
                  <ResponsiveButton onClick={() => handleUnstake(row.original)} disabled={hasPendingActivity}>
                    Claim&Unstake
                  </ResponsiveButton>
                ) : state.isStaked && state.liquidity.eq(0) ? (
                  <>
                    {isIncentiveActive ? (
                      <ResponsiveButton onClick={() => handleStake(row.original)} disabled={hasPendingActivity}>
                        Stake
                      </ResponsiveButton>
                    ) : (
                      <></>
                    )}
                    <ResponsiveButton
                      onClick={() => handleUnstake(row.original)}
                      disabled={true}
                      style={{ backgroundColor: 'grey' }}
                    >
                      Withdraw
                    </ResponsiveButton>
                  </>
                ) : state.isDeposited ? (
                  <>
                    {isIncentiveActive ? (
                      <ResponsiveButton onClick={() => handleStake(row.original)} disabled={hasPendingActivity}>
                        Stake
                      </ResponsiveButton>
                    ) : (
                      <></>
                    )}
                    <ResponsiveButton onClick={() => handleWithdraw(row.original)} disabled={hasPendingActivity}>
                      Withdraw
                    </ResponsiveButton>
                  </>
                ) : (
                  <>
                    {isIncentiveActive ? (
                      <ResponsiveButton
                        margin="4px"
                        onClick={() => handleDeposit(row.original)}
                        disabled={hasPendingActivity}
                      >
                        Deposit&Stake
                      </ResponsiveButton>
                    ) : (
                      <></>
                    )}
                  </>
                )}
                {state.hasRewards && (
                  <ResponsiveButton onClick={() => handleClaim(row.original)} disabled={hasPendingActivity}>
                    Claim Rewards
                  </ResponsiveButton>
                )}
              </AutoColumn>
            </ResponsiveButtonContainer>
          )
        },
        Header: () => <div>Manage Liquidity</div>,
      },
    ]
  }, [
    filteredPositions,
    pool,
    currencyQuoteColor,
    currencyBaseColor,
    positionStates,
    tokenRewards,
    incentive.rewardToken,
    hasPendingActivity,
    handleUnstake,
    handleStake,
    handleWithdraw,
    handleDeposit,
    handleClaim,
    remainingDays,
    calculateRemainingVesting,
  ])

  if (!positionData || positionData.length === 0) {
    return (
      <NoPositionsContainer>
        <ThemedText.BodyPrimary>No NFT positions available</ThemedText.BodyPrimary>
      </NoPositionsContainer>
    )
  }

  return (
    <>
      <VestingWarningModal
        isOpen={showVestingWarning}
        onDismiss={() => setShowVestingWarning(false)}
        onConfirm={handleConfirmUnstake}
        remainingVestingTime={formatDuration(calculateRemainingVesting(selectedTokenForUnstake))}
      />
      <Table
        columns={columns}
        data={positionData}
        smallHiddenColumns={['']}
        mediumHiddenColumns={['']}
        largeHiddenColumns={['symbol']}
      />
    </>
  )
}
const RewardCell = ({ reward, rewardToken }: { reward: string; rewardToken: string }) => {
  const rewardTokenQuery = useTokenFromActiveNetwork(rewardToken) as Token

  return (
    <RewardsContainer style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'right' }}>
      {/* <LightCard padding="6px 8px" maxWidth="60%"> */}
      <AutoColumn gap="md">
        <RowBetween>
          <RowFixed>
            <ThemedText.DeprecatedDarkGray>
              {CurrencyAmount.fromRawAmount(rewardTokenQuery, reward).greaterThan(0) &&
              CurrencyAmount.fromRawAmount(rewardTokenQuery, reward).toFixed(3) === '0.000'
                ? '<0.001'
                : floorFormatter(
                    Number(
                      CurrencyAmount.fromRawAmount(rewardTokenQuery, reward).toFixed(
                        CurrencyAmount.fromRawAmount(rewardTokenQuery, reward).greaterThan(1000) ? 2 : 4
                      )
                    )
                  )}
            </ThemedText.DeprecatedDarkGray>
          </RowFixed>
          {rewardTokenQuery && <CurrencyLogo currency={rewardTokenQuery} size="20px" style={{ marginLeft: '5px' }} />}
        </RowBetween>
      </AutoColumn>
      {/* </LightCard> */}
    </RewardsContainer>
  )
}

const LabelText = styled.div<{ color: string }>`
  align-items: center;
  color: ${({ color }) => color} !important;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`

const BadgeText = styled.div`
  font-weight: 535;
  font-size: 14px;
  color: ${({ theme }) => theme.neutral2};
`

const BadgeTextSuccess = styled.div`
  font-weight: 535;
  font-size: 14px;
  color: ${({ theme }) => theme.success};
`

const ResponsiveButtonContainer = styled.div`
  display: flex;
  justify-content: end;
  width: 100%;
  margin-left: 10px;
  margin-right: 32px;

  @media (max-width: 768px) {
    justify-content: end;
    width: 100%;
  }
`

export const ResponsiveButton = styled(ButtonSecondary)`
  width: 120px;
  margin: 4px;
  align-items: center;
  justify-content: center;

  font-size: 14px;
  background-color: ${({ theme }) => theme.accent1};
  color: white;
  font-weight: 500;
  border: none;
  transition: background-color 0.2s, transform 0.1s;
  padding: 6px 8px;

  &:hover {
    background-color: ${({ theme }) => theme.accent2};
    transform: scale(1.03);
  }
  @media (max-width: 768px) {
    font-size: 12px;
    padding: 6px;
  }
`

const WarningBadge = styled(Badge)`
  background-color: ${({ theme }) => theme.deprecated_yellow3};
  color: ${({ theme }) => theme.deprecated_yellow3};
  font-size: 12px;
  padding: 4px 8px;
  margin: 4px;
`

const NoPositionsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  width: 100%;
  background-color: ${({ theme }) => theme.deprecated_deepShadow};
  border-radius: 12px;
`

const VestingStatus = styled(ThemedText.BodySmall)`
  color: ${({ theme }) => theme.deprecated_accentWarning};
  margin-top: 4px;
`
