import { ChainId } from '@uniswap/sdk-core'
import { Location } from 'react-router-dom'

export function getChainFromUrl(location: Location) {
  return getChainFromUrlOrEmpty(location) ?? ChainId.SONIC
}

export function getChainFromUrlOrEmpty(location: Location) {
  const query = new URLSearchParams(location.search)
  const param = query.get('chain')
  switch (param) {
    case 'fantom':
      return ChainId.FANTOM
    case 'bit_torrent':
      return ChainId.BIT_TORRENT_MAINNET
    case 'horizen':
      return ChainId.EON
    case 'bera_testnet':
      return ChainId.BERA_TESTNET
    case 'sonic_testnet':
      return ChainId.SONIC_TESTNET
    case 'sonic':
      return ChainId.SONIC
    default:
      return undefined
  }
}
